import { useTheme } from '@material-ui/core';
import { Helmet } from 'react-helmet';

const Head = () => {
  const theme = useTheme();

  return (
    <Helmet>
      <title>{theme.meta?.propertyName}</title>
      <meta name="description" content={theme.meta?.propertyDesc} />
      <link
        type="image/x-icon"
        rel="shortcut icon"
        href={theme.meta?.favicon}
      />
      <link
        rel="apple-touch-icon"
        sizes="192x192"
        href={theme.meta?.appleIcon}
      />
      <link rel="manifest" href={theme.meta?.manifest} />
    </Helmet>
  );
};

export default Head;
