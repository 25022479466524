import {
  getClient,
  getRefreshToken,
  loggedInVar,
  refreshTokenVar,
  tokenVar,
} from '../client/client';
import { getWebSocketClient } from '../client/links/webSocket';

import { getHistory } from '../history';
import { ObtainJsonWebToken, AuthRefreshTokenDocument } from '../schema/types';
import { mergeAuthedRules, resetAbility } from './util';

export const persistTokens = (
  tokens: Pick<ObtainJsonWebToken, 'token' | 'refreshToken'>
) => {
  localStorage.setItem('token', tokens.token!);
  localStorage.setItem('refreshToken', tokens.refreshToken!);
  tokenVar(tokens.token);
  refreshTokenVar(tokens.refreshToken);
};

export const clearAuthTokens = () => {
  // NEVER REMOVE REFRESH TOKEN BEFORE AUTH
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('token');
  refreshTokenVar(null);
  tokenVar(null);
};

export const setLoggedIn = () => {
  document.dispatchEvent(new Event('loggedIn'));
  mergeAuthedRules();
  loggedInVar(true);
};

const setLoggedOut = () => {
  document.dispatchEvent(new Event('loggedOut'));
  loggedInVar(false);
};

export const login = () => {
  //@ts-ignore
  getWebSocketClient().sendMessage(undefined, 'connection_init', {
    authToken: tokenVar(),
  });
};

export const logout = () => {
  // DO NOT TOUCH THIS
  //@ts-ignore
  getWebSocketClient().sendMessage(
    undefined,
    'connection_terminate',
    undefined
  );
  // DO NOT TOUCH THIS
  //@ts-ignore
  getWebSocketClient().sendMessage(undefined, 'connection_init', {
    authToken: null,
  });
  resetAbility();
  setLoggedOut();
  getClient().resetStore();
  clearAuthTokens();
  getHistory().push('/');
};

export const refreshToken = () => {
  localStorage.removeItem('token');
  return getClient()
    .mutate({
      mutation: AuthRefreshTokenDocument,
      variables: { refreshToken: getRefreshToken() },
    })
    .then(({ data }) => {
      persistTokens(data.refreshToken);
      login();
    })
    .catch(() => {
      logout();
      getHistory().push('/');
    });
};
