import { FC } from 'react';
import {
  useConnectionStatusQuery,
  useUserPermissionsQuery,
} from '../../../schema/types';
import AuthTokenRefresher from '../AuthTokenRefresher/AuthTokenRefresher';
import useLiveQueryWrapper from '../../../hooks/useLiveQueryWrapper/useLiveQueryWrapper';
import InfoMessage from '../../InfoMessage';
import { Error } from '@material-ui/icons';
import Loading from '../../Loading';

const PermissionsFetcher: FC = ({ children }) => {
  const { data: csData } = useConnectionStatusQuery();
  const { data, loading, error } = useLiveQueryWrapper(
    useUserPermissionsQuery,
    {
      fetchPolicy: 'network-only',
    }
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <InfoMessage
        icon={<Error fontSize="large" />}
        title={'Something went wrong'}
        description={error.message}
      />
    );
  }

  if (!data) {
    return (
      <InfoMessage
        icon={<Error fontSize="large" />}
        title={'Something went wrong'}
        description={
          'Failed to fetch user permissions, refresh the page to try again'
        }
      />
    );
  }

  return (
    <>
      {children}
      {csData?.connectionStatus === 2 && <AuthTokenRefresher />}
    </>
  );
};

export default PermissionsFetcher;
