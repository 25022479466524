import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Drawer,
  IconButton,
  makeStyles,
  ModalProps,
  Paper,
  Theme,
} from '@material-ui/core';
import { ArrowLeft, ArrowRight, ZoomIn, ZoomOut } from '@material-ui/icons';
import { usePdf } from '@mikecousins/react-pdf';
import { FC, useRef, useState } from 'react';
import Loading from '../../Loading';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'top',
    justifyContent: 'center',
  },
  modalBody: {
    outline: 'none',
    width: '100%',
    marginTop: theme.spacing(4.5),
    height: '90vh !important',
    flex: 1,
  },
  viewer: {
    backgroundColor: theme.palette.grey[400],
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  navBar: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    height: '40px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  navBarSection: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
  },
}));

interface PolicyDrawerProps extends Partial<ModalProps> {
  open: boolean;
  closeModal: () => void;
  url: string;
  title: string;
}

const PolicyDrawer: FC<PolicyDrawerProps> = ({
  title,
  open,
  closeModal,
  url,
}) => {
  const classes = useStyles();

  const [embedError, setEmbedError] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [scale, setScale] = useState<number>(1);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const { pdfDocument } = usePdf({
    file: url,
    page,
    scale,
    canvasRef,
    onDocumentLoadFail: () => {
      setEmbedError(true);
    },
    onPageLoadFail: () => {
      setEmbedError(true);
    },
    onPageRenderFail: () => {
      setEmbedError(true);
    },
  });

  return (
    <Drawer open={open} className={classes.modal} anchor={'bottom'}>
      <Container maxWidth="lg" className={classes.modalBody}>
        <Paper
          elevation={3}
          style={{ flex: 1, height: '100%', display: 'flex' }}
        >
          <Card style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <h2>{title}</h2>
            <CardContent
              style={{
                height: '100%',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              {pdfDocument ? (
                <div className={classes.viewer}>
                  <nav className={classes.navBar}>
                    <div className={classes.navBarSection}>Policy Viewer</div>
                    <div className={classes.navBarSection}>
                      <div className="previous">
                        <IconButton
                          disabled={page === 1}
                          onClick={() => page > 1 && setPage(page - 1)}
                        >
                          <ArrowLeft />
                        </IconButton>
                      </div>
                      {`${page} / ${pdfDocument.numPages}`}
                      <div className="next">
                        <IconButton
                          disabled={page === pdfDocument.numPages}
                          onClick={() =>
                            page < pdfDocument.numPages && setPage(page + 1)
                          }
                        >
                          <ArrowRight />
                        </IconButton>
                      </div>
                    </div>
                    <div className={classes.navBarSection}>
                      <div className="previous">
                        <IconButton
                          disabled={scale <= 0.2}
                          onClick={() => setScale(scale * 0.8)}
                        >
                          <ZoomOut />
                        </IconButton>
                      </div>
                      <div className="next">
                        <IconButton
                          disabled={scale >= 6}
                          onClick={() => setScale(scale * 1.2)}
                        >
                          <ZoomIn />
                        </IconButton>
                      </div>
                    </div>
                  </nav>
                  <div
                    style={{
                      flex: 1,
                      overflow: 'scroll',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      display: 'flex',
                      position: 'relative',
                      flexDirection: 'column',
                    }}
                  >
                    <canvas
                      ref={canvasRef}
                      id={'policy-pdf'}
                      style={{
                        width: '100%',
                        height: '100%',
                        margin: '20px 0',
                      }}
                    />
                  </div>
                </div>
              ) : embedError ? (
                <p>ERROR</p>
              ) : (
                <Loading />
              )}
            </CardContent>
            <CardActions style={{ justifyContent: 'flex-end' }}>
              <Button variant={'contained'} onClick={closeModal}>
                Close
              </Button>
            </CardActions>
          </Card>
        </Paper>
      </Container>
    </Drawer>
  );
};

export default PolicyDrawer;
