export const wwWithout = (wwWhatever: string) =>
  wwWhatever.replace(/^www\./i, '').replace(/^m\./i, '');

export const GRAPHQL_HOST =
  process.env.REACT_APP_API_BASE ||
  `${window.location.protocol}//api.${wwWithout(window.location.host)}`;
const wsProtocol = GRAPHQL_HOST.startsWith('https') ? 'wss:' : 'ws:';
const wsHost = `${wsProtocol}${GRAPHQL_HOST.replace('https:', '').replace(
  'http:',
  ''
)}`;
export const GRAPHQL_URI = `${GRAPHQL_HOST}/api/graphql`;
export const GRAPHQL_WS_URI = `${wsHost}/graphql`;
