import { useTheme } from '@material-ui/core';
import React from 'react';
import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useGoogleAnalyticsUserQuery } from '../../schema/types';
import { deepCompareProps } from '../../util/compareProps';

const GoogleAnalytics = () => {
  const { data } = useGoogleAnalyticsUserQuery({ fetchPolicy: 'cache-only' });
  const userId = data?.user?.id;

  const theme = useTheme();

  let timer: undefined | NodeJS.Timeout;
  if (theme.meta.googAnal) {
    ReactGA.initialize(theme.meta.googAnal);
    ReactGA.pageview(window.location.pathname);
    timer = setInterval(
      () => ReactGA.pageview(window.location.pathname),
      60000
    );
  }

  useEffect(() => {
    if (timer && userId) ReactGA.set({ userId });
  }, [timer, userId]);

  useEffect(() => {
    return () => {
      if (timer) clearInterval(timer);
    };
  });

  return null;
};

export default React.memo(GoogleAnalytics, deepCompareProps);
