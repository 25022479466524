import { ErrorLink } from '@apollo/client/link/error';
import { closeWs } from './webSocket';

export const errorLink = new ErrorLink(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const { message } of graphQLErrors) {
      if (message.startsWith('PermissionDenied:')) {
        closeWs();
      }
    }
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});
