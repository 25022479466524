import { FC, HTMLProps } from 'react';
import { SvgIconProps } from '@material-ui/core';

export interface InfoMessageProps extends HTMLProps<HTMLDivElement> {
  icon: SvgIconProps;
  title: string;
  description: string | JSX.Element;
}

const InfoMessage: FC<InfoMessageProps> = ({
  icon,
  title,
  description,
  ...props
}) => {
  return (
    <div
      {...props}
      style={{
        height: '100%',
        width: '100%',
        maxHeight: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        overflow: 'auto',
        ...props.style,
      }}
    >
      <p>{icon}</p>
      <div
        style={{
          margin: '10px',
          fontSize: '.8rem',
          textTransform: 'uppercase',
          fontWeight: 400,
          letterSpacing: 2,
        }}
      >
        {title}
      </div>
      <div
        style={{
          justifyContent: 'center',
          textAlign: 'center',
          maxWidth: '100%',
          padding: '10px 20px',
        }}
      >
        {description}
      </div>
    </div>
  );
};

export default InfoMessage;
