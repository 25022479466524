import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import routes from '../../routes';
import Loading from '../Loading';
import ProtectedRoute from './ProtectedRoute';

const ViewProvider = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {routes.map(({ Component, path, subject }, idx) => {
          if (!Component) {
            return null;
          }

          return (
            <Route
              key={idx}
              path={path}
              element={
                <ProtectedRoute subject={subject}>
                  <Component />
                </ProtectedRoute>
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  );
};

export default ViewProvider;
