import { useTheme } from '@material-ui/core';
import React, { FC, useState } from 'react';
import PolicyDrawer from '../PolicyDrawer/PolicyDrawer';

interface PolicyButtonProps extends React.HTMLAttributes<HTMLAnchorElement> {
  title: string;
  url: string;
}

const PolicyButton: FC<PolicyButtonProps> = ({ title, url, ...props }) => {
  const [policyOpen, setPolicyOpen] = useState<boolean>(false);
  const togglePolicyOpen = () => {
    setPolicyOpen(!policyOpen);
  };
  const theme = useTheme();

  return (
    <>
      <a
        {...props}
        style={{
          cursor: 'pointer',
          fontSize: '0.8em',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          color: theme.palette.text.hint,
          ...props.style,
        }}
        onClick={togglePolicyOpen}
      >
        {title}
      </a>
      {policyOpen ? (
        <PolicyDrawer
          title={title}
          url={url}
          open={policyOpen}
          closeModal={togglePolicyOpen}
          id={props.id ? `${props.id}-viewer` : undefined}
        />
      ) : null}
    </>
  );
};

export default PolicyButton;
