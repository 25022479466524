import { MuiThemeProvider } from '@material-ui/core';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { FC } from 'react';
import {
  useLoggedInQuery,
  useThemeContainerUserLazyQuery,
} from '../../schema/types';
import { createTheme } from '../../themes/util';

const ThemeContainer: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [lazyUserQuery, { data }] = useThemeContainerUserLazyQuery();
  const userThemeOverrides = data?.user?.metadata?.mobile?.themeOverrides;
  const { data: loggedIn } = useLoggedInQuery();
  const [theme, setTheme] = useState(createTheme(userThemeOverrides));

  useEffect(() => {
    if (loggedIn?.loggedIn) {
      lazyUserQuery();
    }
  }, [loggedIn?.loggedIn]);

  useEffect(() => {
    setTheme(createTheme(userThemeOverrides));
  }, [JSON.stringify(userThemeOverrides)]);

  return (
    <MuiThemeProvider theme={theme}>
      <div
        style={{
          height: '100%',
          width: '100%',
          background: theme.palette.background.default,
        }}
      >
        {children}
      </div>
    </MuiThemeProvider>
  );
};

export default React.memo(ThemeContainer);
