import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient as SClient } from 'subscriptions-transport-ws';
import { logout, refreshToken, setLoggedIn } from '../../auth/auth';
import {
  connectionIdVar,
  connectionStatusVar,
  hasConnectedVar,
  systemMessageVar,
  systemStatusVar,
} from '../client';
import { GRAPHQL_WS_URI } from '../util';

if (process.env.NODE_ENV === 'development') {
  //@ts-ignore
  SClient.prototype.createMaxConnectTimeGenerator = function () {
    return {
      reset: () => {},
      duration: () => 10000,
    };
  };
}

const configureWebsocketEvents = (wsClient: SubscriptionClient) => {
  wsClient.onDisconnected(() => {
    connectionStatusVar(0);
  });
  wsClient.onConnecting(() => connectionStatusVar(1));
  wsClient.onReconnecting(() => connectionStatusVar(1));
  wsClient.onConnected(() => {
    incrementConnectionId();
    connectionStatusVar(2);
  });
  wsClient.onReconnected(() => {
    incrementConnectionId();
    connectionStatusVar(2);
  });
};

//@ts-ignore
class SubscriptionClient extends SClient {
  processReceivedData = (receivedData: any) => {
    let parsedMessage;
    try {
      parsedMessage = JSON.parse(receivedData);
    } catch (e) {
      throw new Error(`Message must be JSON-parseable. Got: ${receivedData}`);
    }

    if (parsedMessage.type === 'ka') {
      //@ts-ignore
      getWebSocketClient().sendMessage(undefined, 'ka');
      if (parsedMessage['system_status'] !== undefined) {
        systemStatusVar(parsedMessage['system_status']);
        systemMessageVar(parsedMessage['system_message'] || '');
      }
    }

    if (parsedMessage.type === 'connection_error') {
      if (receivedData.includes('Signature has expired')) {
        refreshToken();
      } else {
        logout();
      }
      return;
    }

    if (parsedMessage.type === 'connection_ack') {
      setConnected();
      if (localStorage.getItem('token')) {
        setLoggedIn();
      }
    }

    //@ts-ignore
    super.processReceivedData(receivedData);
  };
}

function initWebSocketClient() {
  if (!GRAPHQL_WS_URI) {
    throw new Error('NO URI');
  }
  const webSocket = new SubscriptionClient(GRAPHQL_WS_URI, {
    reconnect: true,
    connectionParams: () => ({
      authToken: localStorage.getItem('token'),
    }),
    timeout: 10000,
  });
  configureWebsocketEvents(webSocket);
  return webSocket;
}

const incrementConnectionId = () => {
  if (hasConnectedVar() && localStorage.getItem('refreshToken') !== null) {
    connectionIdVar(connectionIdVar() + 1);
  }
  hasConnectedVar(true);
};

const setConnected = () => {
  hasConnectedVar(true);
};

// USE THE ARGUMENTS AT YOUR OWN PERIL, YOU MAY END UP WITH MULTIPLE CONN
export const closeWs = (isForced = false, closedByUser = false) => {
  getWebSocketClient().close(isForced, closedByUser);
};

export const getWebSocketClient = () => {
  return webSocketClient ? webSocketClient : initWebSocketClient();
};

// This is a var because Javascript Tests can't handle it being used without init above;
var webSocketClient: SubscriptionClient = getWebSocketClient();

// Fix this as any later. Its a cop out.
export const webSocketLink = new WebSocketLink(webSocketClient as any);
