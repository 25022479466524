import logo from './assets/img/logo.png';
import appleIcon from './assets/img/icon-256x256.png';
import favicon from './assets/img/favicon.ico';
import manifest from './manifest.webmanifest';
import { ThemeOptions } from '@material-ui/core';

const overrides: ThemeOptions = {
  palette: {
    type: 'dark',
    primary: {
      main: '#208a55',
    },
  },
  meta: {
    propertyName: 'Braemar Screen',
    propertyOwner: 'Braemar Atlantic Securities',
    propertyDesc:
      'View and trade live FFA prices for Capesize, Panamax and Supramax dry bulk markets.',
    googAnal: 'UA-107044293-6',
    logo,
    favicon,
    manifest,
    appleIcon,
    twitterId: 'braemarscreen',
    policies: [
      {
        title: 'Privacy Policy',
        source: 'https://braemarscreen.com/static/terms/GDPR.pdf',
      },
      {
        title: 'Terms of Service',
        source: 'https://www.braemarscreen.com/static/terms/TOS.pdf',
      },
      {
        title: 'Terms & Conditions',
        source: 'https://www.braemarscreen.com/static/terms/T&C.pdf',
        authedOnly: true,
      },
      {
        title: 'Disclaimer',
        source: 'https://www.braemarscreen.com/static/terms/Disclaimer.pdf',
      },
    ],
    loginTerms: 'https://www.braemarscreen.com/static/terms/T&C.pdf',
  },
};

export default overrides;
