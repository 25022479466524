import { useTheme } from '@material-ui/core';
import { FC } from 'react';

const AppContainer: FC = ({ children }) => {
  const theme = useTheme();
  return (
    <div
      data-testid="AppContainer"
      style={{
        height: '100%',
        width: '100%',
        background: theme.palette.background.default,
      }}
    >
      {children}
    </div>
  );
};

export default AppContainer;
