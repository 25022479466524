import { makeStyles, Theme, useTheme } from '@material-ui/core';
import classnames from 'classnames';
import '@fontsource/play';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    opacity: 1,
    visibility: 'visible',
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'fixed',
    backgroundColor: theme.palette.background.default,
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Play',
    overflow: 'hidden',
  },
  fadeOut: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0.75s, opacity 0.75s ease',
  },
  image: {
    opacity: 0,
    height: '130px',
    width: '80%',
    maxHeight: '130px',
    maxWidth: '80%',
  },
  attribution: {
    opacity: 0,
  },
  fadeIn: {
    opacity: 1,
    transition: 'opacity 1.5s, ease',
  },
  attributionLogo: {
    maxWidth: '150px',
    transform: 'translate(-151px, 0)',
  },
  moveIn: {
    transform: 'translate(0px, 0)',
    transition: 'transform 0.75s, ease',
  },
}));

const SplashScreen = ({ fade }: { fade?: number }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [fadeOut, setFadeOut] = useState<boolean>(false);
  const [logoLoaded, setLogoLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (fade) {
      setTimeout(() => setFadeOut(true), fade * 500);
    }
  });

  return (
    <div
      style={{
        zIndex: fadeOut ? 99999 : 9999,
      }}
      className={classnames(classes.container, { [classes.fadeOut]: fadeOut })}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxHeight: '150px',
          width: '70%',
          maxWidth: '70%',
        }}
      >
        <img
          style={{ objectFit: 'contain' }}
          src={theme.meta.logo}
          alt={theme.meta.propertyName}
          className={classnames(classes.image, {
            [classes.fadeIn]: logoLoaded,
          })}
          onLoad={() => setLogoLoaded(true)}
        />
        {logoLoaded && (
          <div
            style={{
              fontSize: '1rem',
              fontFamily: 'Play, sans-serif',
              paddingTop: '20px',
            }}
          >
            {!fadeOut ? 'Connecting...' : 'Connected'}
          </div>
        )}
      </div>
      <div
        style={{
          position: 'fixed',
          bottom: 40,
          left: 28,
          margin: '0 10px 10px 0',
          zIndex: 11000,
          width: '150px',
        }}
      >
        <span
          className={classnames(classes.attribution, {
            [classes.fadeIn]: logoLoaded,
          })}
        >
          Powered By
        </span>
        <div
          className={classnames(classes.attributionLogo, {
            [classes.moveIn]: logoLoaded,
          })}
        >
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 2000 550"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              fillRule: 'evenodd',
              clipRule: 'evenodd',
              strokeLinejoin: 'round',
              strokeMiterlimit: 2,
            }}
          >
            <g id="Layer-1" transform="matrix(1,0,0,1,0,-725)">
              <g transform="matrix(395.822,0,0,-395.822,97.6596,1050.04)">
                <path
                  d="M1,-0.292L0.142,-0.292L0.634,0.426L0.641,0.437L0.715,0.545L0.624,0.545L0.55,0.437L0.548,0.435L0.548,0.435L0.015,-0.343L0.015,-0.345L0,-0.368L0.925,-0.368L0.925,-0.47L0.434,-0.47L0.434,-0.545L1,-0.545L1,-0.292Z"
                  style={{ fill: 'url(#_Linear1)', fillRule: 'nonzero' }}
                />
              </g>
              <g transform="matrix(517.943,0,0,-517.943,16.3592,1000)">
                <path
                  d="M1,0.513L0.043,0.513L0.043,0.32L0.464,0.32L-0,-0.359L-0,-0.513L0.423,-0.513L0.423,-0.456L0.058,-0.456L0.058,-0.377L0.534,0.32L0.573,0.378L0.1,0.378L0.1,0.456L0.891,0.456L0.406,-0.259L0.476,-0.259L0.961,0.456L1,0.513Z"
                  style={{ fill: 'url(#_Linear2)', fillRule: 'nonzero' }}
                />
              </g>
              <g transform="matrix(1596.74,0,0,-1596.74,464.326,1000)">
                <path
                  d="M0.117,-0.021L0.117,-0.031L0.064,-0.031L0.064,-0.023L0.101,0.021L0.065,0.021L0.065,0.031L0.116,0.031L0.116,0.023L0.079,-0.021L0.117,-0.021Z"
                  style={{ fill: 'url(#_Linear3)', fillRule: 'nonzero' }}
                />
              </g>
              <g transform="matrix(1596.74,0,0,-1596.74,464.326,1000)">
                <path
                  d="M0.213,0.031L0.213,-0.003C0.213,-0.016 0.208,-0.021 0.198,-0.021C0.188,-0.021 0.182,-0.016 0.182,-0.003L0.182,0.031L0.171,0.031L0.171,-0.004C0.171,-0.021 0.181,-0.031 0.198,-0.031C0.214,-0.031 0.224,-0.021 0.224,-0.004L0.224,0.031L0.213,0.031Z"
                  style={{ fill: 'url(#_Linear4)', fillRule: 'nonzero' }}
                />
              </g>
              <g transform="matrix(1596.74,0,0,-1596.74,464.326,1000)">
                <path
                  d="M0.342,0.031L0.318,-0.01L0.294,0.031L0.284,0.031L0.284,-0.031L0.295,-0.031L0.295,0.009L0.315,-0.024L0.321,-0.024L0.341,0.01L0.341,-0.031L0.352,-0.031L0.352,0.031L0.342,0.031Z"
                  style={{ fill: 'url(#_Linear5)', fillRule: 'nonzero' }}
                />
              </g>
              <g transform="matrix(1596.74,0,0,-1596.74,464.326,1000)">
                <path
                  d="M0.431,0.031L0.404,-0.031L0.415,-0.031L0.421,-0.017L0.452,-0.017L0.458,-0.031L0.47,-0.031L0.442,0.031L0.431,0.031ZM0.437,0.019L0.448,-0.008L0.425,-0.008L0.437,0.019Z"
                  style={{ fill: 'url(#_Linear6)', fillRule: 'nonzero' }}
                />
              </g>
              <g transform="matrix(1596.74,0,0,-1596.74,464.326,1000)">
                <path
                  d="M0.59,0.031L0.59,-0.031L0.634,-0.031L0.634,-0.021L0.602,-0.021L0.602,0.031L0.59,0.031Z"
                  style={{ fill: 'url(#_Linear7)', fillRule: 'nonzero' }}
                />
              </g>
              <g transform="matrix(1596.74,0,0,-1596.74,464.326,1000)">
                <path
                  d="M0.706,0.031L0.678,-0.031L0.69,-0.031L0.696,-0.017L0.727,-0.017L0.733,-0.031L0.745,-0.031L0.717,0.031L0.706,0.031ZM0.712,0.019L0.723,-0.008L0.7,-0.008L0.712,0.019Z"
                  style={{ fill: 'url(#_Linear8)', fillRule: 'nonzero' }}
                />
              </g>
              <g transform="matrix(1596.74,0,0,-1596.74,464.326,1000)">
                <path
                  d="M0.797,0.031L0.797,-0.031L0.827,-0.031C0.843,-0.031 0.851,-0.025 0.851,-0.014C0.851,-0.006 0.847,-0.001 0.84,0.001C0.845,0.004 0.848,0.008 0.848,0.015C0.848,0.025 0.84,0.031 0.826,0.031L0.797,0.031ZM0.808,0.022L0.824,0.022C0.832,0.022 0.837,0.019 0.837,0.013C0.837,0.008 0.832,0.005 0.824,0.005L0.808,0.005L0.808,0.022ZM0.808,-0.004L0.827,-0.004C0.835,-0.004 0.84,-0.007 0.84,-0.013C0.84,-0.019 0.835,-0.022 0.827,-0.022L0.808,-0.022L0.808,-0.004Z"
                  style={{ fill: 'url(#_Linear9)', fillRule: 'nonzero' }}
                />
              </g>
              <g transform="matrix(1596.74,0,0,-1596.74,464.326,1000)">
                <path
                  d="M0.904,0.013C0.904,-0.01 0.94,-0.001 0.94,-0.014C0.94,-0.019 0.936,-0.022 0.927,-0.022C0.919,-0.022 0.911,-0.019 0.906,-0.015L0.902,-0.024C0.908,-0.029 0.917,-0.032 0.927,-0.032C0.944,-0.032 0.952,-0.023 0.952,-0.013C0.952,0.009 0.915,0.001 0.915,0.014C0.915,0.018 0.919,0.022 0.928,0.022C0.934,0.022 0.94,0.021 0.945,0.017L0.949,0.026C0.944,0.03 0.936,0.032 0.928,0.032C0.912,0.032 0.904,0.023 0.904,0.013Z"
                  style={{ fill: 'url(#_Linear10)', fillRule: 'nonzero' }}
                />
              </g>
            </g>
            <defs>
              <linearGradient
                id="_Linear1"
                x1="0"
                y1="0"
                x2="1"
                y2="0"
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(1,0,0,-1,0,-6.24018e-07)"
              >
                <stop
                  offset="0"
                  style={{ stopColor: 'rgb(10,243,181)', stopOpacity: 1 }}
                />
                <stop
                  offset="1"
                  style={{ stopColor: 'rgb(1,161,226)', stopOpacity: 1 }}
                />
              </linearGradient>
              <linearGradient
                id="_Linear2"
                x1="0"
                y1="0"
                x2="1"
                y2="0"
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(1,0,0,-1,0,2.22045e-16)"
              >
                <stop
                  offset="0"
                  style={{ stopColor: 'rgb(10,243,181)', stopOpacity: 1 }}
                />
                <stop
                  offset="1"
                  style={{ stopColor: 'rgb(1,161,226)', stopOpacity: 1 }}
                />
              </linearGradient>
              <linearGradient
                id="_Linear3"
                x1="0"
                y1="0"
                x2="1"
                y2="0"
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(1,0,0,-1,0,-1.11022e-16)"
              >
                <stop
                  offset="0"
                  style={{ stopColor: 'rgb(10,243,181)', stopOpacity: 1 }}
                />
                <stop
                  offset="1"
                  style={{ stopColor: 'rgb(1,161,226)', stopOpacity: 1 }}
                />
              </linearGradient>
              <linearGradient
                id="_Linear4"
                x1="0"
                y1="0"
                x2="1"
                y2="0"
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(1,0,0,-1,0,-2.22045e-16)"
              >
                <stop
                  offset="0"
                  style={{ stopColor: 'rgb(10,243,181)', stopOpacity: 1 }}
                />
                <stop
                  offset="1"
                  style={{ stopColor: 'rgb(1,161,226)', stopOpacity: 1 }}
                />
              </linearGradient>
              <linearGradient
                id="_Linear5"
                x1="0"
                y1="0"
                x2="1"
                y2="0"
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(1,0,0,-1,0,-1.11022e-16)"
              >
                <stop
                  offset="0"
                  style={{ stopColor: 'rgb(10,243,181)', stopOpacity: 1 }}
                />
                <stop
                  offset="1"
                  style={{ stopColor: 'rgb(1,161,226)', stopOpacity: 1 }}
                />
              </linearGradient>
              <linearGradient
                id="_Linear6"
                x1="0"
                y1="0"
                x2="1"
                y2="0"
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(1,0,0,-1,0,-1.11022e-16)"
              >
                <stop
                  offset="0"
                  style={{ stopColor: 'rgb(10,243,181)', stopOpacity: 1 }}
                />
                <stop
                  offset="1"
                  style={{ stopColor: 'rgb(1,161,226)', stopOpacity: 1 }}
                />
              </linearGradient>
              <linearGradient
                id="_Linear7"
                x1="0"
                y1="0"
                x2="1"
                y2="0"
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(1,0,0,-1,0,-1.11022e-16)"
              >
                <stop
                  offset="0"
                  style={{ stopColor: 'rgb(10,243,181)', stopOpacity: 1 }}
                />
                <stop
                  offset="1"
                  style={{ stopColor: 'rgb(1,161,226)', stopOpacity: 1 }}
                />
              </linearGradient>
              <linearGradient
                id="_Linear8"
                x1="0"
                y1="0"
                x2="1"
                y2="0"
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(1,0,0,-1,0,-1.11022e-16)"
              >
                <stop
                  offset="0"
                  style={{ stopColor: 'rgb(10,243,181)', stopOpacity: 1 }}
                />
                <stop
                  offset="1"
                  style={{ stopColor: 'rgb(1,161,226)', stopOpacity: 1 }}
                />
              </linearGradient>
              <linearGradient
                id="_Linear9"
                x1="0"
                y1="0"
                x2="1"
                y2="0"
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(1,0,0,-1,0,-1.11022e-16)"
              >
                <stop
                  offset="0"
                  style={{ stopColor: 'rgb(10,243,181)', stopOpacity: 1 }}
                />
                <stop
                  offset="1"
                  style={{ stopColor: 'rgb(1,161,226)', stopOpacity: 1 }}
                />
              </linearGradient>
              <linearGradient
                id="_Linear10"
                x1="0"
                y1="0"
                x2="1"
                y2="0"
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(1,0,0,-1,0,-2.22045e-16)"
              >
                <stop
                  offset="0"
                  style={{ stopColor: 'rgb(10,243,181)', stopOpacity: 1 }}
                />
                <stop
                  offset="1"
                  style={{ stopColor: 'rgb(1,161,226)', stopOpacity: 1 }}
                />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;
