import { FC } from 'react';
import ability from '../../auth/util';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
  subject?: string;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ subject, children }) => {
  const authorizedForRoute = ability.can('read', subject || 'private');
  return authorizedForRoute ? <>{children}</> : <Navigate to={'/login'} />;
};

export default ProtectedRoute;
