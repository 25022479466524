import React from 'react';
import Loader from 'react-loader-spinner';

interface LoadingProps extends React.HTMLProps<HTMLDivElement> {
  size?: number;
}

const Loading: React.FC<LoadingProps> = ({ size = 40, ...props }) => {
  return (
    <div
      data-testid={'loading'}
      {...props}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        ...props.style,
      }}
    >
      <Loader type="Grid" color="#FFFFFF" height={size} width={size} />
    </div>
  );
};

export default React.memo(Loading);
