import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import {
  useConnectionStatusQuery,
  useHasConnectedQuery,
  useReloadingCountQuery,
} from '../../schema/types';
import SplashScreen from './SplashScreen';

const ConnectionContainer: FC = ({ children }) => {
  const { data: csData } = useConnectionStatusQuery();
  const { data: hcData } = useHasConnectedQuery();
  const { data: rlData } = useReloadingCountQuery();

  const reloadingCount =
    rlData?.reloadingCount === undefined ? 1 : rlData.reloadingCount;
  const isConnected =
    (csData?.connectionStatus === undefined ? 0 : csData.connectionStatus) > 1;
  const hasConnected = !!hcData?.hasConnected;

  const [showSplashScreen, setShowSplashScreen] = useState<boolean>(
    !hasConnected
  );

  useEffect(() => {
    if (!showSplashScreen) {
      return;
    }
    if (hasConnected) {
      setShowSplashScreen(false);
    }
  }, [hasConnected]);

  return showSplashScreen ? (
    <SplashScreen />
  ) : (
    <>
      {<SplashScreen fade={1.5} />}
      <Dialog
        open={!isConnected || reloadingCount > 0}
        data-testid={'connectiondialog'}
        onClose={() => false}
      >
        <DialogContent>
          <DialogContentText>Reconnecting...</DialogContentText>
        </DialogContent>
      </Dialog>
      {children}
    </>
  );
};

export default ConnectionContainer;
