import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: any;
};

export type AbilityType = {
  __typename?: 'AbilityType';
  actions: Maybe<Scalars['GenericScalar']>;
  subject: Maybe<Scalars['GenericScalar']>;
  conditions: Maybe<Scalars['GenericScalar']>;
  fields: Maybe<Scalars['GenericScalar']>;
  inverted: Scalars['Boolean'];
  reason: Maybe<Scalars['String']>;
};

export type AccountType = {
  __typename?: 'AccountType';
  id: Scalars['ID'];
  addressLine1: Maybe<Scalars['String']>;
  addressLine2: Maybe<Scalars['String']>;
  addressLine3: Maybe<Scalars['String']>;
  addressCity: Maybe<Scalars['String']>;
  addressState: Maybe<Scalars['String']>;
  addressPostalCode: Maybe<Scalars['String']>;
  addressCountry: Maybe<Scalars['String']>;
  organization: OrganizationSsoType;
  name: Scalars['String'];
  entityName: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  purchases: Array<TradeType>;
  sales: Array<TradeType>;
};

export type ActivityStreamSubscription = {
  __typename?: 'ActivityStreamSubscription';
  activities: Array<Maybe<ActivityStreamType>>;
};

export type ActivityStreamType = {
  __typename?: 'ActivityStreamType';
  id: Scalars['ID'];
  user: Scalars['String'];
  message: Scalars['String'];
  isOwnStream: Scalars['Boolean'];
  ts: Scalars['DateTime'];
};

export type ActivityStreamWidgetType = WidgetInterface & {
  __typename?: 'ActivityStreamWidgetType';
  id: Scalars['ID'];
  dashboard: DashboardType;
  layout: Maybe<Scalars['GenericScalar']>;
  memberId: Maybe<Scalars['ID']>;
};

export type AddActivityStreamToDashboardMutation = {
  __typename?: 'AddActivityStreamToDashboardMutation';
  dashboard: Maybe<DashboardType>;
  memberId: Scalars['ID'];
};

export type AddBrokerBlastWidgetToDashboardMutation = {
  __typename?: 'AddBrokerBlastWidgetToDashboardMutation';
  dashboard: Maybe<DashboardType>;
  memberId: Scalars['ID'];
};

export type AddBrokerContactsWidgetToDashboardMutation = {
  __typename?: 'AddBrokerContactsWidgetToDashboardMutation';
  dashboard: Maybe<DashboardType>;
  memberId: Scalars['ID'];
};

export type AddBrokerProductGroupWidgetToDashboardMutation = {
  __typename?: 'AddBrokerProductGroupWidgetToDashboardMutation';
  dashboard: Maybe<DashboardType>;
  memberId: Scalars['ID'];
};

export type AddContactFolderMutation = {
  __typename?: 'AddContactFolderMutation';
  clientFolder: Maybe<ClientFolderType>;
};

export type AddContactGroupMutation = {
  __typename?: 'AddContactGroupMutation';
  clientGroup: Maybe<ClientGroupType>;
};

export type AddContactMutation = {
  __typename?: 'AddContactMutation';
  clientUser: Maybe<Contactable>;
};

export type AddContactToGroupMutation = {
  __typename?: 'AddContactToGroupMutation';
  clientGroup: Maybe<ClientGroupType>;
};

/**
 * Keywords are basically the most basic type of matcher
 * name is pattern
 */
export type AddKeywordsToWhatsappStreamWidgetMutation = {
  __typename?: 'AddKeywordsToWhatsappStreamWidgetMutation';
  widget: Maybe<WhatsappStreamWidgetType>;
  invalidKeywords: Maybe<Array<InvalidKeywordType>>;
};

export type AddMarineTrafficWidgetToDashboard = {
  __typename?: 'AddMarineTrafficWidgetToDashboard';
  dashboard: Maybe<DashboardType>;
  memberId: Scalars['ID'];
};

export type AddMarketDepthWidgetToDashboardMutation = {
  __typename?: 'AddMarketDepthWidgetToDashboardMutation';
  dashboard: Maybe<DashboardType>;
  memberId: Scalars['ID'];
};

export type AddMarketGridWidgetToDashboard = {
  __typename?: 'AddMarketGridWidgetToDashboard';
  dashboard: Maybe<DashboardType>;
  memberId: Scalars['ID'];
};

export type AddMarketSessionWidgetToDashboard = {
  __typename?: 'AddMarketSessionWidgetToDashboard';
  dashboard: Maybe<DashboardType>;
  memberId: Scalars['ID'];
};

export type AddNewsFeedWidgetToDashboard = {
  __typename?: 'AddNewsFeedWidgetToDashboard';
  dashboard: Maybe<DashboardType>;
  memberId: Scalars['ID'];
};

export type AddNoteToOrderMutation = {
  __typename?: 'AddNoteToOrderMutation';
  note: NoteType;
  order: Maybe<OrderType>;
};

export type AddNoteToTradeCandidateMutation = {
  __typename?: 'AddNoteToTradeCandidateMutation';
  note: NoteType;
  tradeCandidate: Maybe<TradeCandidateType>;
};

export type AddNoteToTradeMutation = {
  __typename?: 'AddNoteToTradeMutation';
  note: NoteType;
  trade: Maybe<TradeType>;
};

export type AddOrderWidgetToDashboardMutation = {
  __typename?: 'AddOrderWidgetToDashboardMutation';
  dashboard: Maybe<DashboardType>;
  memberId: Scalars['ID'];
};

export type AddProductGroupOrderRuleWidgetToDashboard = {
  __typename?: 'AddProductGroupOrderRuleWidgetToDashboard';
  dashboard: Maybe<DashboardType>;
  memberId: Scalars['ID'];
};

export type AddProductToProductGroupMutation = {
  __typename?: 'AddProductToProductGroupMutation';
  productGroup: Maybe<ProductGroupType>;
  productGroupId: Scalars['ID'];
};

export type AddSeasonalityChartWidgetToDashboard = {
  __typename?: 'AddSeasonalityChartWidgetToDashboard';
  dashboard: Maybe<DashboardType>;
  memberId: Scalars['ID'];
};

export type AddTimeMachineChartWidgetToDashboard = {
  __typename?: 'AddTimeMachineChartWidgetToDashboard';
  dashboard: Maybe<DashboardType>;
  memberId: Scalars['ID'];
};

export type AddTradeTapeWidgetToDashboard = {
  __typename?: 'AddTradeTapeWidgetToDashboard';
  dashboard: Maybe<DashboardType>;
  memberId: Scalars['ID'];
};

export type AddTradesPendingWidgetToDashboardMutation = {
  __typename?: 'AddTradesPendingWidgetToDashboardMutation';
  dashboard: Maybe<DashboardType>;
  memberId: Scalars['ID'];
};

export type AddTradingViewWidgetToDashboard = {
  __typename?: 'AddTradingViewWidgetToDashboard';
  dashboard: Maybe<DashboardType>;
  memberId: Scalars['ID'];
};

export type AddWatchlistMutation = {
  __typename?: 'AddWatchlistMutation';
  watchlistId: Scalars['ID'];
};

export type AddWatchlistWidgetToDashboard = {
  __typename?: 'AddWatchlistWidgetToDashboard';
  dashboard: Maybe<DashboardType>;
  memberId: Scalars['ID'];
};

export type AddWhatsappStreamWidgetToDashboardMutation = {
  __typename?: 'AddWhatsappStreamWidgetToDashboardMutation';
  dashboard: Maybe<DashboardType>;
  memberId: Scalars['ID'];
};

export type AggregatorAddBrokerMutation = {
  __typename?: 'AggregatorAddBrokerMutation';
  status: Maybe<Scalars['String']>;
};

export type AggregatorDataSubscription = {
  __typename?: 'AggregatorDataSubscription';
  aggregatorData: Maybe<AggregatorDataType>;
};

export type AggregatorDataType = {
  __typename?: 'AggregatorDataType';
  id: Scalars['ID'];
  organization: OrganizationSsoType;
  source: AggregatorSourceType;
  product: ProductType;
  bidPrice: Maybe<Scalars['Int']>;
  bidTs: Maybe<Scalars['DateTime']>;
  offerPrice: Maybe<Scalars['Int']>;
  offerTs: Maybe<Scalars['DateTime']>;
};

export type AggregatorDeleteBrokerMutation = {
  __typename?: 'AggregatorDeleteBrokerMutation';
  status: Maybe<Scalars['String']>;
};

export type AggregatorSourceType = {
  __typename?: 'AggregatorSourceType';
  id: Scalars['ID'];
  name: Scalars['String'];
  organization: OrganizationSsoType;
  hashes: Array<Scalars['String']>;
};

export type AnonymousOrderType = {
  clearers: Array<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deskId: Maybe<Scalars['ID']>;
  displayVolume: Scalars['Float'];
  side: SideEnum;
  myOrder: Scalars['Boolean'];
  limit: Maybe<Scalars['Float']>;
};

export type AnonymousTradeType = {
  __typename?: 'AnonymousTradeType';
  id: Scalars['ID'];
  product: ProductType;
  price: Scalars['Float'];
  volume: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  cancelled: Scalars['Boolean'];
};

export type BlastMutation = {
  __typename?: 'BlastMutation';
  contacts: Maybe<Array<Maybe<Contactable>>>;
  contactGroups: Maybe<Array<Maybe<ClientGroupType>>>;
};

export type BrokerBlastWidgetType = WidgetInterface & {
  __typename?: 'BrokerBlastWidgetType';
  id: Scalars['ID'];
  dashboard: DashboardType;
  layout: Maybe<Scalars['GenericScalar']>;
  memberId: Maybe<Scalars['ID']>;
};

export type BrokerContactsWidgetType = WidgetInterface & {
  __typename?: 'BrokerContactsWidgetType';
  id: Scalars['ID'];
  dashboard: DashboardType;
  layout: Maybe<Scalars['GenericScalar']>;
  memberId: Maybe<Scalars['ID']>;
  desk: BrokerDeskType;
};

export type BrokerDeskContactsSubscription = {
  __typename?: 'BrokerDeskContactsSubscription';
  clients: Maybe<Array<Maybe<Contactable>>>;
};

export type BrokerDeskFeatureFlagType = {
  __typename?: 'BrokerDeskFeatureFlagType';
  id: Scalars['ID'];
  flag: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  isGlobal: Scalars['Boolean'];
  users: Array<UserType>;
  desks: Array<BrokerDeskType>;
};

/** An enumeration. */
export enum BrokerDeskMarketInvoiceFileType {
  /** Pdf */
  A_0 = 'A_0',
  /** Docx */
  A_1 = 'A_1',
}

export type BrokerDeskMarketType = {
  __typename?: 'BrokerDeskMarketType';
  id: Scalars['ID'];
  brokerdesk: BrokerDeskType;
  market: MarketType;
  updateOrderPrice: Scalars['Boolean'];
  submitBid: Scalars['Boolean'];
  submitOffer: Scalars['Boolean'];
  defaultValidity: Maybe<Scalars['String']>;
  brokerOnlyMarket: Scalars['Boolean'];
  invoiceFileType: BrokerDeskMarketInvoiceFileType;
  metadata: Scalars['JSONString'];
  session: Array<MarketSessionType>;
  defaultTick: Maybe<Scalars['Int']>;
};

export type BrokerDeskMetadataType = MetadataInterfaceType & {
  __typename?: 'BrokerDeskMetadataType';
  id: Scalars['ID'];
  desk: BrokerDeskType;
  orderRequired: Scalars['Boolean'];
  tradeCandidateRequired: Scalars['Boolean'];
  tradeRequired: Scalars['Boolean'];
  displayName: Scalars['String'];
  name: Scalars['String'];
  type: ValueType;
  options: Array<MetadataOptionType>;
};

export type BrokerDeskType = GuardianAbility & {
  __typename?: 'BrokerDeskType';
  id: Scalars['ID'];
  name: Scalars['String'];
  brokers: Array<UserType>;
  groups: Array<GroupType>;
  markets: Array<BrokerDeskMarketType>;
  products: Array<ProductType>;
  defaultGridMarkets: Array<MarketType>;
  deskMetadata: Array<BrokerDeskMetadataType>;
  abilities: Maybe<Array<Maybe<AbilityType>>>;
  clients: Array<UserType>;
  featureFlags: Array<BrokerDeskFeatureFlagType>;
};

export type BrokerProductGroupWidgetType = WidgetInterface & {
  __typename?: 'BrokerProductGroupWidgetType';
  id: Scalars['ID'];
  dashboard: DashboardType;
  layout: Maybe<Scalars['GenericScalar']>;
  productGroup: ProductGroupType;
  memberId: Maybe<Scalars['ID']>;
};

export type BrokerSiteTickerGroup = {
  __typename?: 'BrokerSiteTickerGroup';
  name: Scalars['String'];
  products: Array<BrokerSiteTickerProduct>;
};

export type BrokerSiteTickerProduct = {
  __typename?: 'BrokerSiteTickerProduct';
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Maybe<Scalars['Decimal']>;
  prevClose: Maybe<Scalars['Decimal']>;
};

export type BrokerSiteType = {
  __typename?: 'BrokerSiteType';
  id: Scalars['ID'];
  name: Scalars['String'];
  productGroups: Array<Scalars['Int']>;
  products: Array<ProductType>;
  activityTypes: Array<Scalars['Int']>;
  publicDelay: Scalars['Int'];
  defaultTicker: Scalars['String'];
  domain: Scalars['String'];
  passwordResetTemplate: Maybe<Scalars['String']>;
  ssoEntityId: Maybe<Scalars['String']>;
  ssoLink: Maybe<Scalars['String']>;
  ticker: Array<BrokerSiteTickerGroup>;
};

export type BulkUpdateInputType = {
  ticker: Scalars['String'];
  typeId: Scalars['Int'];
  price: Scalars['String'];
};

export type BulkUpdateProductActivityMutation = {
  __typename?: 'BulkUpdateProductActivityMutation';
  activities: Maybe<Array<Maybe<ProductGroupMemberActivityType>>>;
  activityIds: Array<Scalars['ID']>;
};

export type CancelAllOrdersMutation = {
  __typename?: 'CancelAllOrdersMutation';
  orders: Maybe<Array<Maybe<OrderType>>>;
  orderIds: Array<Scalars['ID']>;
};

export type CancelOrderMutation = {
  __typename?: 'CancelOrderMutation';
  orders: Maybe<Array<Maybe<OrderType>>>;
  orderIds: Array<Scalars['ID']>;
};

export type CancelTradeCandidatesMutation = {
  __typename?: 'CancelTradeCandidatesMutation';
  tradeCandidates: Array<TradeCandidateType>;
  tradeCandidateIds: Array<Scalars['ID']>;
};

export type CancelTradeMutation = {
  __typename?: 'CancelTradeMutation';
  trades: Array<TradeType>;
  tradeIds: Array<Scalars['ID']>;
};

export type ClearTradeMutation = {
  __typename?: 'ClearTradeMutation';
  trade: Maybe<TradeType>;
  tradeId: Maybe<Scalars['ID']>;
};

/** An enumeration. */
export enum ClearingStatusEnum {
  Pending = 'Pending',
  Submitted = 'Submitted',
  Cleared = 'Cleared',
  Cancelled = 'Cancelled',
}

export type ClientFolderType = {
  __typename?: 'ClientFolderType';
  id: Scalars['ID'];
  name: Scalars['String'];
  members: Array<UserType>;
};

export type ClientGroupType = {
  __typename?: 'ClientGroupType';
  id: Scalars['ID'];
  name: Scalars['String'];
  members: Array<UserType>;
};

/** An enumeration. */
export enum Column {
  MarketIndicator = 'MARKET_INDICATOR',
  AggBid = 'AGG_BID',
  Bid = 'BID',
  Offer = 'OFFER',
  AggOffer = 'AGG_OFFER',
  Midpoint = 'MIDPOINT',
  Volumes = 'VOLUMES',
  Close = 'CLOSE',
  Change = 'CHANGE',
  Last = 'LAST',
  ChangePercent = 'CHANGE_PERCENT',
  Value = 'VALUE',
  Trader = 'TRADER',
  Clearer = 'CLEARER',
}

export type CommissionType = {
  __typename?: 'CommissionType';
  id: Scalars['ID'];
  name: Scalars['String'];
  typeId: CommissionTypeId;
  rate: Scalars['Float'];
  bidTrades: Array<TradeType>;
  offerTrades: Array<TradeType>;
  bidTradeCandidates: Array<TradeCandidateType>;
  offerTradeCandidates: Array<TradeCandidateType>;
  brokerDesks: Array<BrokerDeskType>;
};

/** An enumeration. */
export enum CommissionTypeId {
  /** Fixed */
  A_0 = 'A_0',
  /** Volume */
  A_1 = 'A_1',
  /** Price */
  A_2 = 'A_2',
}

export type CommitTradeCandidatesMutation = {
  __typename?: 'CommitTradeCandidatesMutation';
  trades: Array<TradeType>;
  tradeIds: Array<Scalars['ID']>;
};

export type CommonTradeType = {
  ticker: Scalars['String'];
  market: MarketType;
  buyerOrganization: Scalars['String'];
  buyer: UserType;
  sellerOrganization: Scalars['String'];
  seller: UserType;
  clearerCode: Maybe<Scalars['String']>;
  bidBroker: Maybe<UserType>;
  offerBroker: Maybe<UserType>;
  allowableClearers: Array<Scalars['String']>;
};

export type ContactGroupType = {
  __typename?: 'ContactGroupType';
  id: Scalars['ID'];
  name: Scalars['String'];
  lastContacted: Maybe<Scalars['DateTime']>;
  contacts: Array<ContactType>;
  users: Array<UserType>;
  coveringFor: Maybe<Array<Maybe<UserType>>>;
};

export type ContactType = {
  __typename?: 'ContactType';
  id: Scalars['ID'];
  user: Maybe<UserType>;
  name: Scalars['String'];
  mobile: Scalars['String'];
  lastContacted: Maybe<Scalars['DateTime']>;
  users: Array<UserType>;
  contactGroups: Array<ContactGroupType>;
  coveringFor: Maybe<Array<Maybe<UserType>>>;
};

export type Contactable = {
  id: Maybe<Scalars['ID']>;
  name: Maybe<Scalars['String']>;
  lastContacted: Maybe<Scalars['DateTime']>;
  mobileNumber: Maybe<Scalars['String']>;
  wechatIds: Array<Scalars['String']>;
  skypeIds: Array<Scalars['String']>;
  whatsappEnabled: Maybe<Scalars['Boolean']>;
  wechatEnabled: Maybe<Scalars['Boolean']>;
  eikonEnabled: Maybe<Scalars['Boolean']>;
  skypeEnabled: Maybe<Scalars['Boolean']>;
};

export type CreateDashboardMutation = {
  __typename?: 'CreateDashboardMutation';
  dashboard: Maybe<DashboardType>;
};

export type CreateOrderMutation = {
  __typename?: 'CreateOrderMutation';
  order: OrderType;
  orderIds: Array<Scalars['ID']>;
};

/**
 * Create a new Product Group, requiring pretty special perms.
 * Honestly this is mostly for E2E at the time of writing
 */
export type CreateProductGroupOnDeskMutation = {
  __typename?: 'CreateProductGroupOnDeskMutation';
  productGroup: Maybe<ProductGroupType>;
};

export type CreateTicketMutation = {
  __typename?: 'CreateTicketMutation';
  ticketId: Maybe<Scalars['ID']>;
  outcomes: Array<TicketOutcome>;
};

export type CreateTimeMachineChartMutation = {
  __typename?: 'CreateTimeMachineChartMutation';
  chart: Maybe<TimeMachineChartType>;
};

export type CreateTradeCandidates = {
  __typename?: 'CreateTradeCandidates';
  tradeCandidateIds: Array<TradeCandidateIdType>;
};

export type CreateTradeCandidatesForNewOrder = {
  __typename?: 'CreateTradeCandidatesForNewOrder';
  order: Maybe<OrderType>;
  tradeCandidates: Maybe<Array<TradeCandidateType>>;
  tradeCandidateIds: Array<TradeCandidateIdType>;
};

export type DashboardType = {
  __typename?: 'DashboardType';
  id: Scalars['ID'];
  name: Scalars['String'];
  ownedBy: UserType;
  createdBy: UserType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  layout: Maybe<Scalars['JSONString']>;
  rank: Scalars['Int'];
  displayOrder: Array<Scalars['ID']>;
  widgets: Maybe<Array<Maybe<WidgetType>>>;
  dashboardsMarinetrafficwidgets: Array<MarineTrafficWidgetType>;
  vloggingActivitystreamwidgets: Array<ActivityStreamWidgetType>;
  marketsTradetapewidgets: Array<TradeTapeWidgetType>;
  marketsTradingviewwidgets: Array<TradingViewWidgetType>;
  marketsMarketsessionwidgets: Array<MarketSessionWidgetType>;
  marketsWatchlistwidgets: Array<WatchlistWidgetType>;
  marketsSeasonalitychartwidgets: Array<SeasonalityChartWidgetType>;
  marketsProductgrouporderrulewidgets: Array<ProductGroupOrderRuleWidgetType>;
  ordersOrderwidgets: Array<OrderWidgetType>;
  ordersTradespendingwidgets: Array<TradesPendingWidgetType>;
  ordersMarketdepthwidgets: Array<MarketDepthWidgetType>;
  brokersBrokerblastwidgets: Array<BrokerBlastWidgetType>;
  brokersBrokerproductgroupwidgets: Array<BrokerProductGroupWidgetType>;
  brokersBrokercontactswidgets: Array<BrokerContactsWidgetType>;
  brokersBrokermarketgridwidgets: Array<MarketGridWidgetType>;
  whatsappWhatsappstreamwidgets: Array<WhatsappStreamWidgetType>;
  newsNewsfeedwidgets: Array<NewsFeedWidgetType>;
  timemachineTimemachinechartwidgets: Array<TimeMachineChartWidgetType>;
  removed: Maybe<Scalars['Boolean']>;
};

export type DashboardWidgetSubscription = {
  __typename?: 'DashboardWidgetSubscription';
  widgets: Maybe<Array<Maybe<WidgetType>>>;
};

export type DashboardsSubscription = {
  __typename?: 'DashboardsSubscription';
  dashboards: Maybe<Array<Maybe<DashboardType>>>;
};

export type DeleteProductGroupMemberMutation = {
  __typename?: 'DeleteProductGroupMemberMutation';
  productGroup: Maybe<ProductGroupType>;
  productGroupId: Scalars['ID'];
};

/**
 * Remove all perms on this PG for everyone, and all widgets,
 * but don't fully delete
 */
export type DeleteProductGroupMutation = {
  __typename?: 'DeleteProductGroupMutation';
  status: Scalars['Boolean'];
};

export type DeleteWatchlistMemberMutation = {
  __typename?: 'DeleteWatchlistMemberMutation';
  memberIds: Array<Scalars['ID']>;
};

export type GridBootstrapType = {
  __typename?: 'GridBootstrapType';
  desks: Array<BrokerDeskType>;
  markets: Array<MarketType>;
  deskMarkets: Array<BrokerDeskMarketType>;
  productCodes: Array<Scalars['String']>;
};

export type GridDeskMarketType = {
  __typename?: 'GridDeskMarketType';
  productGroups: Array<ProductGroupType>;
  products: Array<ProductType>;
};

export type GroupType = {
  __typename?: 'GroupType';
  id: Scalars['ID'];
  name: Scalars['String'];
  /** The groups this user belongs to. A user will get all permissions granted to each of their groups. */
  userSet: Array<UserType>;
  desks: Array<BrokerDeskType>;
};

export type GuardianAbility = {
  abilities: Maybe<Array<Maybe<AbilityType>>>;
};

export type HomePageNewsFeedItemType = {
  __typename?: 'HomePageNewsFeedItemType';
  title: Scalars['String'];
  body: Scalars['String'];
  source: HomePageNewsFeedSourceType;
  media: Array<NewsFeedMediaType>;
  url: Scalars['String'];
  id: Scalars['String'];
  timestamp: Scalars['DateTime'];
};

export type HomePageNewsFeedSourceType = {
  __typename?: 'HomePageNewsFeedSourceType';
  name: Scalars['String'];
  platform: Scalars['String'];
  url: Scalars['String'];
  avatarIcon: Maybe<Scalars['String']>;
};

export type InvalidKeywordType = {
  __typename?: 'InvalidKeywordType';
  keyword: Scalars['String'];
  reason: Scalars['String'];
};

export type MarineTrafficWidgetType = WidgetInterface & {
  __typename?: 'MarineTrafficWidgetType';
  id: Scalars['ID'];
  dashboard: DashboardType;
  layout: Maybe<Scalars['GenericScalar']>;
  memberId: Maybe<Scalars['ID']>;
};

/** An enumeration. */
export enum MarketCurrency {
  /** ADP */
  Adp = 'ADP',
  /** AFA */
  Afa = 'AFA',
  /** ALK */
  Alk = 'ALK',
  /** AON */
  Aon = 'AON',
  /** AOR */
  Aor = 'AOR',
  /** ARA */
  Ara = 'ARA',
  /** ARP */
  Arp = 'ARP',
  /** ATS */
  Ats = 'ATS',
  /** AZM */
  Azm = 'AZM',
  /** BAD */
  Bad = 'BAD',
  /** BEF */
  Bef = 'BEF',
  /** BGL */
  Bgl = 'BGL',
  /** BRC */
  Brc = 'BRC',
  /** BRE */
  Bre = 'BRE',
  /** BRN */
  Brn = 'BRN',
  /** BRR */
  Brr = 'BRR',
  /** BYR */
  Byr = 'BYR',
  /** CLE */
  Cle = 'CLE',
  /** CSD */
  Csd = 'CSD',
  /** CSK */
  Csk = 'CSK',
  /** CYP */
  Cyp = 'CYP',
  /** DDM */
  Ddm = 'DDM',
  /** DEM */
  Dem = 'DEM',
  /** ECS */
  Ecs = 'ECS',
  /** ECV */
  Ecv = 'ECV',
  /** EEK */
  Eek = 'EEK',
  /** ESA */
  Esa = 'ESA',
  /** ESB */
  Esb = 'ESB',
  /** ESP */
  Esp = 'ESP',
  /** FIM */
  Fim = 'FIM',
  /** FRF */
  Frf = 'FRF',
  /** GHC */
  Ghc = 'GHC',
  /** GRD */
  Grd = 'GRD',
  /** GWP */
  Gwp = 'GWP',
  /** HRD */
  Hrd = 'HRD',
  /** IEP */
  Iep = 'IEP',
  /** ITL */
  Itl = 'ITL',
  /** LTL */
  Ltl = 'LTL',
  /** LUF */
  Luf = 'LUF',
  /** LVL */
  Lvl = 'LVL',
  /** MGF */
  Mgf = 'MGF',
  /** MLF */
  Mlf = 'MLF',
  /** MRO */
  Mro = 'MRO',
  /** MTL */
  Mtl = 'MTL',
  /** MZM */
  Mzm = 'MZM',
  /** NLG */
  Nlg = 'NLG',
  /** PEI */
  Pei = 'PEI',
  /** PLZ */
  Plz = 'PLZ',
  /** PTE */
  Pte = 'PTE',
  /** ROL */
  Rol = 'ROL',
  /** RUR */
  Rur = 'RUR',
  /** SDD */
  Sdd = 'SDD',
  /** SIT */
  Sit = 'SIT',
  /** SKK */
  Skk = 'SKK',
  /** SRG */
  Srg = 'SRG',
  /** STD */
  Std = 'STD',
  /** TJR */
  Tjr = 'TJR',
  /** TMM */
  Tmm = 'TMM',
  /** TPE */
  Tpe = 'TPE',
  /** TRL */
  Trl = 'TRL',
  /** UAK */
  Uak = 'UAK',
  /** USS */
  Uss = 'USS',
  /** VEB */
  Veb = 'VEB',
  /** VEF */
  Vef = 'VEF',
  /** VNN */
  Vnn = 'VNN',
  /** XEU */
  Xeu = 'XEU',
  /** YDD */
  Ydd = 'YDD',
  /** YUM */
  Yum = 'YUM',
  /** YUN */
  Yun = 'YUN',
  /** ZAL */
  Zal = 'ZAL',
  /** ZMK */
  Zmk = 'ZMK',
  /** ZRN */
  Zrn = 'ZRN',
  /** ZRZ */
  Zrz = 'ZRZ',
  /** ZWD */
  Zwd = 'ZWD',
  /** ZWL */
  Zwl = 'ZWL',
  /** ZWR */
  Zwr = 'ZWR',
  /** AOK */
  Aok = 'AOK',
  /** ARL */
  Arl = 'ARL',
  /** ARM */
  Arm = 'ARM',
  /** BAN */
  Ban = 'BAN',
  /** BEC */
  Bec = 'BEC',
  /** BEL */
  Bel = 'BEL',
  /** BGM */
  Bgm = 'BGM',
  /** BGO */
  Bgo = 'BGO',
  /** BOL */
  Bol = 'BOL',
  /** BOP */
  Bop = 'BOP',
  /** BRB */
  Brb = 'BRB',
  /** BRZ */
  Brz = 'BRZ',
  /** BUK */
  Buk = 'BUK',
  /** BYB */
  Byb = 'BYB',
  /** CNH */
  Cnh = 'CNH',
  /** CNX */
  Cnx = 'CNX',
  /** GEK */
  Gek = 'GEK',
  /** GNS */
  Gns = 'GNS',
  /** GQE */
  Gqe = 'GQE',
  /** GWE */
  Gwe = 'GWE',
  /** ILP */
  Ilp = 'ILP',
  /** ILR */
  Ilr = 'ILR',
  /** ISJ */
  Isj = 'ISJ',
  /** KRH */
  Krh = 'KRH',
  /** KRO */
  Kro = 'KRO',
  /** LTT */
  Ltt = 'LTT',
  /** LUC */
  Luc = 'LUC',
  /** LUL */
  Lul = 'LUL',
  /** LVR */
  Lvr = 'LVR',
  /** MAF */
  Maf = 'MAF',
  /** MCF */
  Mcf = 'MCF',
  /** MDC */
  Mdc = 'MDC',
  /** MKN */
  Mkn = 'MKN',
  /** MRU */
  Mru = 'MRU',
  /** MTP */
  Mtp = 'MTP',
  /** MVP */
  Mvp = 'MVP',
  /** MXP */
  Mxp = 'MXP',
  /** MZE */
  Mze = 'MZE',
  /** NIC */
  Nic = 'NIC',
  /** PES */
  Pes = 'PES',
  /** RHD */
  Rhd = 'RHD',
  /** SDP */
  Sdp = 'SDP',
  /** STN */
  Stn = 'STN',
  /** SUR */
  Sur = 'SUR',
  /** UGS */
  Ugs = 'UGS',
  /** UYP */
  Uyp = 'UYP',
  /** UYW */
  Uyw = 'UYW',
  /** VES */
  Ves = 'VES',
  /** XRE */
  Xre = 'XRE',
  /** YUD */
  Yud = 'YUD',
  /** YUR */
  Yur = 'YUR',
  /** AED */
  Aed = 'AED',
  /** AFN */
  Afn = 'AFN',
  /** ALL */
  All = 'ALL',
  /** AMD */
  Amd = 'AMD',
  /** ANG */
  Ang = 'ANG',
  /** AOA */
  Aoa = 'AOA',
  /** ARS */
  Ars = 'ARS',
  /** AUD */
  Aud = 'AUD',
  /** AWG */
  Awg = 'AWG',
  /** AZN */
  Azn = 'AZN',
  /** BAM */
  Bam = 'BAM',
  /** BBD */
  Bbd = 'BBD',
  /** BDT */
  Bdt = 'BDT',
  /** BGN */
  Bgn = 'BGN',
  /** BHD */
  Bhd = 'BHD',
  /** BIF */
  Bif = 'BIF',
  /** BMD */
  Bmd = 'BMD',
  /** BND */
  Bnd = 'BND',
  /** BOB */
  Bob = 'BOB',
  /** BOV */
  Bov = 'BOV',
  /** BRL */
  Brl = 'BRL',
  /** BSD */
  Bsd = 'BSD',
  /** BTN */
  Btn = 'BTN',
  /** BWP */
  Bwp = 'BWP',
  /** BYN */
  Byn = 'BYN',
  /** BZD */
  Bzd = 'BZD',
  /** CAD */
  Cad = 'CAD',
  /** CDF */
  Cdf = 'CDF',
  /** CHE */
  Che = 'CHE',
  /** CHF */
  Chf = 'CHF',
  /** CHW */
  Chw = 'CHW',
  /** CLF */
  Clf = 'CLF',
  /** CLP */
  Clp = 'CLP',
  /** CNY */
  Cny = 'CNY',
  /** COP */
  Cop = 'COP',
  /** COU */
  Cou = 'COU',
  /** CRC */
  Crc = 'CRC',
  /** CUC */
  Cuc = 'CUC',
  /** CUP */
  Cup = 'CUP',
  /** CVE */
  Cve = 'CVE',
  /** CZK */
  Czk = 'CZK',
  /** DJF */
  Djf = 'DJF',
  /** DKK */
  Dkk = 'DKK',
  /** DOP */
  Dop = 'DOP',
  /** DZD */
  Dzd = 'DZD',
  /** EGP */
  Egp = 'EGP',
  /** ERN */
  Ern = 'ERN',
  /** ETB */
  Etb = 'ETB',
  /** EUR */
  Eur = 'EUR',
  /** FJD */
  Fjd = 'FJD',
  /** FKP */
  Fkp = 'FKP',
  /** GBP */
  Gbp = 'GBP',
  /** GEL */
  Gel = 'GEL',
  /** GHS */
  Ghs = 'GHS',
  /** GIP */
  Gip = 'GIP',
  /** GMD */
  Gmd = 'GMD',
  /** GNF */
  Gnf = 'GNF',
  /** GTQ */
  Gtq = 'GTQ',
  /** GYD */
  Gyd = 'GYD',
  /** HKD */
  Hkd = 'HKD',
  /** HNL */
  Hnl = 'HNL',
  /** HRK */
  Hrk = 'HRK',
  /** HTG */
  Htg = 'HTG',
  /** HUF */
  Huf = 'HUF',
  /** IDR */
  Idr = 'IDR',
  /** ILS */
  Ils = 'ILS',
  /** IMP */
  Imp = 'IMP',
  /** INR */
  Inr = 'INR',
  /** IQD */
  Iqd = 'IQD',
  /** IRR */
  Irr = 'IRR',
  /** ISK */
  Isk = 'ISK',
  /** JMD */
  Jmd = 'JMD',
  /** JOD */
  Jod = 'JOD',
  /** JPY */
  Jpy = 'JPY',
  /** KES */
  Kes = 'KES',
  /** KGS */
  Kgs = 'KGS',
  /** KHR */
  Khr = 'KHR',
  /** KMF */
  Kmf = 'KMF',
  /** KPW */
  Kpw = 'KPW',
  /** KRW */
  Krw = 'KRW',
  /** KWD */
  Kwd = 'KWD',
  /** KYD */
  Kyd = 'KYD',
  /** KZT */
  Kzt = 'KZT',
  /** LAK */
  Lak = 'LAK',
  /** LBP */
  Lbp = 'LBP',
  /** LKR */
  Lkr = 'LKR',
  /** LRD */
  Lrd = 'LRD',
  /** LSL */
  Lsl = 'LSL',
  /** LYD */
  Lyd = 'LYD',
  /** MAD */
  Mad = 'MAD',
  /** MDL */
  Mdl = 'MDL',
  /** MGA */
  Mga = 'MGA',
  /** MKD */
  Mkd = 'MKD',
  /** MMK */
  Mmk = 'MMK',
  /** MNT */
  Mnt = 'MNT',
  /** MOP */
  Mop = 'MOP',
  /** MUR */
  Mur = 'MUR',
  /** MVR */
  Mvr = 'MVR',
  /** MWK */
  Mwk = 'MWK',
  /** MXN */
  Mxn = 'MXN',
  /** MXV */
  Mxv = 'MXV',
  /** MYR */
  Myr = 'MYR',
  /** MZN */
  Mzn = 'MZN',
  /** NAD */
  Nad = 'NAD',
  /** NGN */
  Ngn = 'NGN',
  /** NIO */
  Nio = 'NIO',
  /** NOK */
  Nok = 'NOK',
  /** NPR */
  Npr = 'NPR',
  /** NZD */
  Nzd = 'NZD',
  /** OMR */
  Omr = 'OMR',
  /** PAB */
  Pab = 'PAB',
  /** PEN */
  Pen = 'PEN',
  /** PGK */
  Pgk = 'PGK',
  /** PHP */
  Php = 'PHP',
  /** PKR */
  Pkr = 'PKR',
  /** PLN */
  Pln = 'PLN',
  /** PYG */
  Pyg = 'PYG',
  /** QAR */
  Qar = 'QAR',
  /** RON */
  Ron = 'RON',
  /** RSD */
  Rsd = 'RSD',
  /** RUB */
  Rub = 'RUB',
  /** RWF */
  Rwf = 'RWF',
  /** SAR */
  Sar = 'SAR',
  /** SBD */
  Sbd = 'SBD',
  /** SCR */
  Scr = 'SCR',
  /** SDG */
  Sdg = 'SDG',
  /** SEK */
  Sek = 'SEK',
  /** SGD */
  Sgd = 'SGD',
  /** SHP */
  Shp = 'SHP',
  /** SLE */
  Sle = 'SLE',
  /** SLL */
  Sll = 'SLL',
  /** SOS */
  Sos = 'SOS',
  /** SRD */
  Srd = 'SRD',
  /** SSP */
  Ssp = 'SSP',
  /** SVC */
  Svc = 'SVC',
  /** SYP */
  Syp = 'SYP',
  /** SZL */
  Szl = 'SZL',
  /** THB */
  Thb = 'THB',
  /** TJS */
  Tjs = 'TJS',
  /** TMT */
  Tmt = 'TMT',
  /** TND */
  Tnd = 'TND',
  /** TOP */
  Top = 'TOP',
  /** TRY */
  Try = 'TRY',
  /** TTD */
  Ttd = 'TTD',
  /** TVD */
  Tvd = 'TVD',
  /** TWD */
  Twd = 'TWD',
  /** TZS */
  Tzs = 'TZS',
  /** UAH */
  Uah = 'UAH',
  /** UGX */
  Ugx = 'UGX',
  /** USD */
  Usd = 'USD',
  /** USN */
  Usn = 'USN',
  /** UYI */
  Uyi = 'UYI',
  /** UYU */
  Uyu = 'UYU',
  /** UZS */
  Uzs = 'UZS',
  /** VED */
  Ved = 'VED',
  /** VND */
  Vnd = 'VND',
  /** VUV */
  Vuv = 'VUV',
  /** WST */
  Wst = 'WST',
  /** XAF */
  Xaf = 'XAF',
  /** XAG */
  Xag = 'XAG',
  /** XAU */
  Xau = 'XAU',
  /** XBA */
  Xba = 'XBA',
  /** XBB */
  Xbb = 'XBB',
  /** XBC */
  Xbc = 'XBC',
  /** XBD */
  Xbd = 'XBD',
  /** XCD */
  Xcd = 'XCD',
  /** XDR */
  Xdr = 'XDR',
  /** XFO */
  Xfo = 'XFO',
  /** XFU */
  Xfu = 'XFU',
  /** XOF */
  Xof = 'XOF',
  /** XPD */
  Xpd = 'XPD',
  /** XPF */
  Xpf = 'XPF',
  /** XPT */
  Xpt = 'XPT',
  /** XSU */
  Xsu = 'XSU',
  /** XTS */
  Xts = 'XTS',
  /** XUA */
  Xua = 'XUA',
  /** XXX */
  Xxx = 'XXX',
  /** YER */
  Yer = 'YER',
  /** ZAR */
  Zar = 'ZAR',
  /** ZMW */
  Zmw = 'ZMW',
  /** ZWN */
  Zwn = 'ZWN',
}

export type MarketDepthSubscription = {
  __typename?: 'MarketDepthSubscription';
  orders: Maybe<Array<Maybe<MarketDepthType>>>;
};

export type MarketDepthType = AnonymousOrderType & {
  __typename?: 'MarketDepthType';
  id: Scalars['ID'];
  sideId: OrderSideId;
  statusId: OrderStatusId;
  limit: Maybe<Scalars['Float']>;
  displayVolume: Scalars['Float'];
  product: ProductType;
  clearers: Array<Scalars['String']>;
  validUntil: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  visibilityLocked: Scalars['Boolean'];
  deskId: Maybe<Scalars['ID']>;
  side: SideEnum;
  myOrder: Scalars['Boolean'];
  status: StatusEnum;
};

export type MarketDepthWidgetType = WidgetInterface & {
  __typename?: 'MarketDepthWidgetType';
  id: Scalars['ID'];
  dashboard: DashboardType;
  layout: Maybe<Scalars['GenericScalar']>;
  memberId: Maybe<Scalars['ID']>;
};

export type MarketGridWidgetType = WidgetInterface & {
  __typename?: 'MarketGridWidgetType';
  id: Scalars['ID'];
  dashboard: DashboardType;
  layout: Maybe<Scalars['GenericScalar']>;
  memberId: Maybe<Scalars['ID']>;
};

export type MarketMetadataType = MetadataInterfaceType & {
  __typename?: 'MarketMetadataType';
  id: Scalars['ID'];
  market: MarketType;
  orderRequired: Scalars['Boolean'];
  tradeCandidateRequired: Scalars['Boolean'];
  tradeRequired: Scalars['Boolean'];
  displayName: Scalars['String'];
  name: Scalars['String'];
  type: ValueType;
  options: Array<MetadataOptionType>;
};

export type MarketSessionSubscription = {
  __typename?: 'MarketSessionSubscription';
  sessions: Maybe<Array<Maybe<MarketSessionType>>>;
};

export type MarketSessionType = {
  __typename?: 'MarketSessionType';
  id: Scalars['ID'];
  name: Scalars['String'];
  deskMarket: BrokerDeskMarketType;
  timeOpen: Scalars['Time'];
  timeClose: Scalars['Time'];
  timeZone: Scalars['String'];
  live: Scalars['Boolean'];
  overridden: Scalars['Boolean'];
};

export type MarketSessionWidgetType = WidgetInterface & {
  __typename?: 'MarketSessionWidgetType';
  id: Scalars['ID'];
  dashboard: DashboardType;
  layout: Maybe<Scalars['GenericScalar']>;
  memberId: Maybe<Scalars['ID']>;
};

export type MarketType = GuardianAbility & {
  __typename?: 'MarketType';
  id: Scalars['ID'];
  name: Scalars['String'];
  code: Scalars['String'];
  fatFingerThreshold: Scalars['Float'];
  orderPriorityThreshold: Scalars['Float'];
  minimumVolume: Scalars['Float'];
  volumeTickSize: Scalars['Float'];
  defaultVolume: Maybe<Scalars['Float']>;
  updateGroupingInterval: Scalars['Int'];
  timeOpen: Maybe<Scalars['Time']>;
  timeClose: Maybe<Scalars['Time']>;
  timeZone: Maybe<Scalars['String']>;
  stalePriceInterval: Maybe<Scalars['Int']>;
  stalePriceBuffer: Maybe<Scalars['Int']>;
  decimalPlaces: Scalars['Int'];
  clearingPrecision: Scalars['Int'];
  clearers: Array<Scalars['String']>;
  displayColor: Maybe<Scalars['String']>;
  autoSettlement: Scalars['Boolean'];
  weekendSettlement: Scalars['Boolean'];
  balticSettlement: Scalars['Boolean'];
  allowOrders: Scalars['Boolean'];
  currency: Maybe<MarketCurrency>;
  priceUnit: Maybe<Scalars['String']>;
  contractUnit: Maybe<Scalars['String']>;
  venue: Maybe<VenueType>;
  marketMetadata: Array<MarketMetadataType>;
  products: Array<ProductType>;
  desks: Array<BrokerDeskType>;
  defaultGridDesks: Array<BrokerDeskType>;
  brokerDeskMarkets: Array<BrokerDeskMarketType>;
  abilities: Maybe<Array<Maybe<AbilityType>>>;
};

export type MetadataInterfaceType = {
  displayName: Scalars['String'];
  name: Scalars['String'];
  type: ValueType;
  options: Array<MetadataOptionType>;
};

export type MetadataOptionType = {
  __typename?: 'MetadataOptionType';
  id: Scalars['ID'];
  value: Scalars['String'];
  extra: Scalars['JSONString'];
};

export type MoveContactToFolderMutation = {
  __typename?: 'MoveContactToFolderMutation';
  clientFolders: Maybe<Array<Maybe<ClientFolderType>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createTimemachineChart: Maybe<CreateTimeMachineChartMutation>;
  patchTimemachineChart: Maybe<PatchTimeMachineChartMutation>;
  addTimemachineWidgetToDashboard: Maybe<AddTimeMachineChartWidgetToDashboard>;
  addNewsFeedWidgetToDashboard: Maybe<AddNewsFeedWidgetToDashboard>;
  addActivityStreamWidgetToDashboard: Maybe<AddActivityStreamToDashboardMutation>;
  addWhatsappStreamWidgetToDashboard: Maybe<AddWhatsappStreamWidgetToDashboardMutation>;
  /**
   * Keywords are basically the most basic type of matcher
   * name is pattern
   */
  addKeywordsToWhatsappStreamWidget: Maybe<AddKeywordsToWhatsappStreamWidgetMutation>;
  removeWhatsappStreamWidgetMatchers: Maybe<RemoveWhatsappStreamWidgetMatchersMutation>;
  aggregatorAddBroker: Maybe<AggregatorAddBrokerMutation>;
  aggregatorDeleteBroker: Maybe<AggregatorDeleteBrokerMutation>;
  createDashboard: Maybe<CreateDashboardMutation>;
  removeWidgetsFromDashboard: Maybe<RemoveWidgetsFromDashboardMutation>;
  patchDashboard: Maybe<PatchDashboardMutation>;
  removeDashboard: Maybe<RemoveDashboardMutation>;
  patchWidget: Maybe<PatchWidgetMutation>;
  addMarineTrafficWidgetToDashboard: Maybe<AddMarineTrafficWidgetToDashboard>;
  addBrokerProductGroupWidgetToDashboard: Maybe<AddBrokerProductGroupWidgetToDashboardMutation>;
  addBrokerBlastWidgetToDashboard: Maybe<AddBrokerBlastWidgetToDashboardMutation>;
  addBrokerContactsWidgetToDashboard: Maybe<AddBrokerContactsWidgetToDashboardMutation>;
  addMarketGridWidgetToDashboard: Maybe<AddMarketGridWidgetToDashboard>;
  blast: Maybe<BlastMutation>;
  addBrokerContact: Maybe<AddContactMutation>;
  patchBrokerContact: Maybe<PatchContactMutation>;
  removeBrokerContact: Maybe<RemoveContactMutation>;
  addBrokerContactGroup: Maybe<AddContactGroupMutation>;
  patchBrokerContactGroup: Maybe<PatchContactGroupMutation>;
  addContactToGroup: Maybe<AddContactToGroupMutation>;
  removeBrokerContactGroup: Maybe<RemoveContactGroupMutation>;
  addBrokerContactFolder: Maybe<AddContactFolderMutation>;
  patchBrokerContactFolder: Maybe<PatchContactFolderMutation>;
  removeBrokerContactFolder: Maybe<RemoveContactFolderMutation>;
  moveContactToFolder: Maybe<MoveContactToFolderMutation>;
  /**
   * Create a new Product Group, requiring pretty special perms.
   * Honestly this is mostly for E2E at the time of writing
   */
  createProductGroupOnDesk: Maybe<CreateProductGroupOnDeskMutation>;
  createTradeCandidates: Maybe<CreateTradeCandidates>;
  createTradeCandidatesForNewOrder: Maybe<CreateTradeCandidatesForNewOrder>;
  commitTradeCandidates: Maybe<CommitTradeCandidatesMutation>;
  cancelTradeCandidates: Maybe<CancelTradeCandidatesMutation>;
  patchTradeCandidatePrices: Maybe<PatchTradeCandidatePricesMutation>;
  patchTradeCandidateClearer: Maybe<PatchTradeCandidateClearerMutation>;
  patchTradeCandidateAccount: Maybe<PatchTradeCandidateAccountMutation>;
  patchTradeCandidateBrokers: Maybe<PatchTradeCandidateBrokersMutation>;
  patchTradeCandidateCommission: Maybe<PatchTradeCandidateCommissionMutation>;
  patchTradeCandidateMetadata: Maybe<PatchTradeCandidateMetadataMutation>;
  tradeEntry: Maybe<TradeEntryMutation>;
  cancelOrder: Maybe<CancelOrderMutation>;
  cancelAllOrders: Maybe<CancelAllOrdersMutation>;
  withdrawOrder: Maybe<WithdrawOrderMutation>;
  reinstateOrder: Maybe<ReinstateOrderMutation>;
  withdrawAllOrders: Maybe<WithdrawAllOrdersMutation>;
  reinstateAllOrders: Maybe<ReinstateAllOrdersMutation>;
  setOrderVisibility: Maybe<SetOrderVisibilityMutation>;
  createTicket: Maybe<CreateTicketMutation>;
  createOrder: Maybe<CreateOrderMutation>;
  patchOrder: Maybe<PatchOrderMutation>;
  clearTrade: Maybe<ClearTradeMutation>;
  cancelTrade: Maybe<CancelTradeMutation>;
  addOrderWidgetToDashboard: Maybe<AddOrderWidgetToDashboardMutation>;
  addTradesPendingWidgetToDashboard: Maybe<AddTradesPendingWidgetToDashboardMutation>;
  addNoteToTrade: Maybe<AddNoteToTradeMutation>;
  addNoteToTradeCandidate: Maybe<AddNoteToTradeCandidateMutation>;
  addNoteToOrder: Maybe<AddNoteToOrderMutation>;
  addMarketDepthWidgetToDashboard: Maybe<AddMarketDepthWidgetToDashboardMutation>;
  addMarketSessionWidgetToDashboard: Maybe<AddMarketSessionWidgetToDashboard>;
  /**
   * Remove all perms on this PG for everyone, and all widgets,
   * but don't fully delete
   */
  deleteProductGroup: Maybe<DeleteProductGroupMutation>;
  addProductToProductGroup: Maybe<AddProductToProductGroupMutation>;
  patchProductGroupMember: Maybe<PatchProductGroupMemberMutation>;
  deleteProductGroupMember: Maybe<DeleteProductGroupMemberMutation>;
  updateProductQuote: Maybe<UpdateProductQuoteMutation>;
  refreshProductQuotes: Maybe<RefreshProductQuotesMutation>;
  bulkUpdateProductQuotes: Maybe<BulkUpdateProductActivityMutation>;
  patchProductGroup: Maybe<PatchProductGroupMutation>;
  addTradeTapeWidgetToDashboard: Maybe<AddTradeTapeWidgetToDashboard>;
  addTradingViewWidgetToDashboard: Maybe<AddTradingViewWidgetToDashboard>;
  patchProductGroupOrderRule: Maybe<PatchProductGroupOrderRuleMutation>;
  patchMarketSession: Maybe<PatchMarketSessionMutation>;
  addProductGroupOrderRuleWidgetToDashboard: Maybe<AddProductGroupOrderRuleWidgetToDashboard>;
  addWatchlist: Maybe<AddWatchlistMutation>;
  patchWatchlist: Maybe<PatchWatchlistMutation>;
  patchWatchlistMember: Maybe<PatchWatchlistMemberMutation>;
  deleteWatchlistMember: Maybe<DeleteWatchlistMemberMutation>;
  addWatchlistWidgetToDashboard: Maybe<AddWatchlistWidgetToDashboard>;
  addSeasonalityChartWidgetToDashboard: Maybe<AddSeasonalityChartWidgetToDashboard>;
  patchUserMetadata: Maybe<UserType>;
  requestResetPassword: Maybe<RequestResetPasswordMutation>;
  submitResetPassword: Maybe<SubmitResetPasswordMutation>;
  tokenAuth: Maybe<ObtainJsonWebToken>;
  verifyToken: Maybe<Verify>;
  refreshToken: Maybe<Refresh>;
  revokeToken: Maybe<Revoke>;
};

export type MutationCreateTimemachineChartArgs = {
  dateEnd: Maybe<Scalars['Date']>;
  dateStart: Scalars['Date'];
  displayType: Maybe<TimeMachineChartDisplayTypeEnum>;
  excludedYears: Maybe<Array<Scalars['String']>>;
  formula: Maybe<Scalars['String']>;
  hasAverage: Maybe<Scalars['Boolean']>;
  name: Maybe<Scalars['String']>;
  products: Maybe<Array<Scalars['String']>>;
  type: TimeMachineChartTypeEnum;
};

export type MutationPatchTimemachineChartArgs = {
  dateEnd: Maybe<Scalars['Date']>;
  dateStart: Maybe<Scalars['Date']>;
  displayType: Maybe<TimeMachineChartDisplayTypeEnum>;
  excludedYears: Maybe<Array<Scalars['String']>>;
  formula: Maybe<Scalars['String']>;
  hasAverage: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  products: Maybe<Array<Scalars['String']>>;
  type: Maybe<TimeMachineChartTypeEnum>;
};

export type MutationAddTimemachineWidgetToDashboardArgs = {
  chartId: Scalars['ID'];
  dashboardId: Scalars['ID'];
  layout: Maybe<Scalars['GenericScalar']>;
};

export type MutationAddNewsFeedWidgetToDashboardArgs = {
  dashboardId: Scalars['ID'];
  layout: Maybe<Scalars['GenericScalar']>;
};

export type MutationAddActivityStreamWidgetToDashboardArgs = {
  dashboardId: Scalars['ID'];
  layout: Maybe<Scalars['GenericScalar']>;
};

export type MutationAddWhatsappStreamWidgetToDashboardArgs = {
  dashboardId: Scalars['ID'];
  layout: Maybe<Scalars['GenericScalar']>;
};

export type MutationAddKeywordsToWhatsappStreamWidgetArgs = {
  keywords: Array<Scalars['String']>;
  widgetId: Scalars['ID'];
};

export type MutationRemoveWhatsappStreamWidgetMatchersArgs = {
  matcherIds: Array<Scalars['ID']>;
};

export type MutationAggregatorAddBrokerArgs = {
  mobileNumber: Scalars['String'];
  sourceName: Scalars['String'];
};

export type MutationAggregatorDeleteBrokerArgs = {
  sourceList: Array<Scalars['String']>;
};

export type MutationCreateDashboardArgs = {
  name: Scalars['String'];
};

export type MutationRemoveWidgetsFromDashboardArgs = {
  dashboardId: Scalars['ID'];
  widgetMemberIds: Array<Maybe<Scalars['ID']>>;
};

export type MutationPatchDashboardArgs = {
  dashboardId: Scalars['ID'];
  displayOrder: Maybe<Array<Scalars['ID']>>;
  layout: Maybe<Scalars['GenericScalar']>;
  name: Maybe<Scalars['String']>;
};

export type MutationRemoveDashboardArgs = {
  id: Scalars['ID'];
};

export type MutationPatchWidgetArgs = {
  layout: Maybe<Scalars['GenericScalar']>;
  memberId: Scalars['ID'];
};

export type MutationAddMarineTrafficWidgetToDashboardArgs = {
  dashboardId: Scalars['ID'];
  layout: Maybe<Scalars['GenericScalar']>;
};

export type MutationAddBrokerProductGroupWidgetToDashboardArgs = {
  dashboardId: Scalars['ID'];
  layout: Maybe<Scalars['GenericScalar']>;
  productGroupId: Scalars['ID'];
};

export type MutationAddBrokerBlastWidgetToDashboardArgs = {
  dashboardId: Scalars['ID'];
  layout: Maybe<Scalars['GenericScalar']>;
};

export type MutationAddBrokerContactsWidgetToDashboardArgs = {
  dashboardId: Scalars['ID'];
  deskId: Scalars['ID'];
  layout: Maybe<Scalars['GenericScalar']>;
};

export type MutationAddMarketGridWidgetToDashboardArgs = {
  dashboardId: Scalars['ID'];
  layout: Maybe<Scalars['GenericScalar']>;
};

export type MutationBlastArgs = {
  body: Scalars['String'];
  contactGroups: Maybe<Array<Maybe<Scalars['ID']>>>;
  contacts: Maybe<Array<Maybe<Scalars['ID']>>>;
  deskId: Maybe<Scalars['ID']>;
};

export type MutationAddBrokerContactArgs = {
  clientGroupId: Maybe<Scalars['ID']>;
  deskId: Maybe<Scalars['ID']>;
  email: Maybe<Scalars['String']>;
  firstName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  mobile: Maybe<Scalars['String']>;
  skypeId: Maybe<Scalars['String']>;
  wechatId: Maybe<Scalars['String']>;
};

export type MutationPatchBrokerContactArgs = {
  contactId: Scalars['ID'];
  deskId: Scalars['ID'];
  eikonEnabled: Maybe<Scalars['Boolean']>;
  mobile: Maybe<Scalars['String']>;
  skypeEnabled: Maybe<Scalars['Boolean']>;
  skypeId: Maybe<Scalars['String']>;
  wechatEnabled: Maybe<Scalars['Boolean']>;
  wechatId: Maybe<Scalars['String']>;
  whatsappEnabled: Maybe<Scalars['Boolean']>;
};

export type MutationRemoveBrokerContactArgs = {
  clientFolderId: Maybe<Scalars['ID']>;
  clientGroupId: Maybe<Scalars['ID']>;
  contactId: Scalars['ID'];
  deskId: Maybe<Scalars['ID']>;
};

export type MutationAddBrokerContactGroupArgs = {
  deskId: Scalars['ID'];
  members: Maybe<Array<Maybe<Scalars['ID']>>>;
  name: Scalars['String'];
};

export type MutationPatchBrokerContactGroupArgs = {
  id: Scalars['ID'];
  members: Maybe<Array<Maybe<Scalars['ID']>>>;
  name: Maybe<Scalars['String']>;
};

export type MutationAddContactToGroupArgs = {
  group: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationRemoveBrokerContactGroupArgs = {
  id: Scalars['ID'];
};

export type MutationAddBrokerContactFolderArgs = {
  deskId: Scalars['ID'];
  members: Maybe<Array<Maybe<Scalars['ID']>>>;
  name: Scalars['String'];
};

export type MutationPatchBrokerContactFolderArgs = {
  id: Scalars['ID'];
  members: Maybe<Array<Maybe<Scalars['ID']>>>;
  name: Maybe<Scalars['String']>;
};

export type MutationRemoveBrokerContactFolderArgs = {
  id: Scalars['ID'];
};

export type MutationMoveContactToFolderArgs = {
  desk: Scalars['ID'];
  folder: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
};

export type MutationCreateProductGroupOnDeskArgs = {
  deskId: Scalars['ID'];
  name: Scalars['String'];
};

export type MutationCreateTradeCandidatesArgs = {
  clearers: Array<Scalars['ID']>;
  createdForId: Maybe<Scalars['ID']>;
  deskId: Scalars['ID'];
  limit: Maybe<Scalars['String']>;
  orderType: OrderTypeEnum;
  restingOrders: Array<Scalars['ID']>;
  side: SideEnum;
  timeInForce: TimeInForceEnum;
  visibilityPrivate?: Maybe<Scalars['Boolean']>;
  volume: Scalars['String'];
};

export type MutationCreateTradeCandidatesForNewOrderArgs = {
  cancelOnPartial?: Maybe<Scalars['Boolean']>;
  clearers: Array<Scalars['ID']>;
  createdForId: Maybe<Scalars['ID']>;
  deskId: Scalars['ID'];
  displayVolume: Maybe<Scalars['String']>;
  flexVolume?: Maybe<Scalars['Boolean']>;
  limit: Maybe<Scalars['String']>;
  orderType: OrderTypeEnum;
  productId: Scalars['ID'];
  restingOrders: Array<Scalars['ID']>;
  side: SideEnum;
  stop: Maybe<Scalars['String']>;
  timeInForce: TimeInForceEnum;
  validFrom: Maybe<Scalars['DateTime']>;
  validUntil: Maybe<Scalars['DateTime']>;
  visibilityLocked?: Maybe<Scalars['Boolean']>;
  visibilityPrivate?: Maybe<Scalars['Boolean']>;
  volume: Scalars['String'];
};

export type MutationCommitTradeCandidatesArgs = {
  tradeCandidateId: Scalars['ID'];
};

export type MutationCancelTradeCandidatesArgs = {
  tradeCandidateId: Scalars['ID'];
};

export type MutationPatchTradeCandidatePricesArgs = {
  prices: Array<TradeCandidatePrice>;
};

export type MutationPatchTradeCandidateClearerArgs = {
  clearerId: Scalars['ID'];
  forAllInCandidateGroup: Maybe<Scalars['Boolean']>;
  tradeCandidateId: Scalars['ID'];
};

export type MutationPatchTradeCandidateAccountArgs = {
  accountId: Scalars['ID'];
  forAllInCandidateGroup: Maybe<Scalars['Boolean']>;
  tradeCandidateId: Scalars['ID'];
};

export type MutationPatchTradeCandidateBrokersArgs = {
  bidBrokerId: Maybe<Scalars['ID']>;
  offerBrokerId: Maybe<Scalars['ID']>;
  tradeCandidateId: Scalars['ID'];
};

export type MutationPatchTradeCandidateCommissionArgs = {
  commissionId: Scalars['ID'];
  side: SideEnum;
  tradeCandidateId: Scalars['ID'];
};

export type MutationPatchTradeCandidateMetadataArgs = {
  metadata: Scalars['GenericScalar'];
  tradeCandidateId: Scalars['ID'];
};

export type MutationTradeEntryArgs = {
  bidCreatedFor: Scalars['ID'];
  clearer: Scalars['ID'];
  deskId: Scalars['ID'];
  metadata: Maybe<Scalars['GenericScalar']>;
  offerCreatedFor: Scalars['ID'];
  price: Scalars['String'];
  productId: Scalars['ID'];
  volume: Scalars['String'];
};

export type MutationCancelOrderArgs = {
  orderId: Maybe<Scalars['ID']>;
};

export type MutationWithdrawOrderArgs = {
  orderId: Maybe<Scalars['ID']>;
};

export type MutationReinstateOrderArgs = {
  orderId: Maybe<Scalars['ID']>;
};

export type MutationSetOrderVisibilityArgs = {
  orderId: Maybe<Scalars['ID']>;
  private: Scalars['Boolean'];
  setPrivateDelaySeconds?: Maybe<Scalars['Int']>;
};

export type MutationCreateTicketArgs = {
  orders: Array<OrderCreationInputType>;
  ticketType: Maybe<TicketTypeEnum>;
};

export type MutationCreateOrderArgs = {
  cancelOnPartial?: Maybe<Scalars['Boolean']>;
  clearers: Array<Scalars['ID']>;
  createdForId: Maybe<Scalars['ID']>;
  deskId: Scalars['ID'];
  displayVolume: Maybe<Scalars['String']>;
  flexVolume?: Maybe<Scalars['Boolean']>;
  limit: Maybe<Scalars['String']>;
  metadata: Maybe<Scalars['GenericScalar']>;
  orderType: OrderTypeEnum;
  productId: Scalars['ID'];
  side: SideEnum;
  stop: Maybe<Scalars['String']>;
  timeInForce: TimeInForceEnum;
  validFrom: Maybe<Scalars['DateTime']>;
  validUntil: Maybe<Scalars['DateTime']>;
  visibilityLocked?: Maybe<Scalars['Boolean']>;
  visibilityPrivate?: Maybe<Scalars['Boolean']>;
  volume: Maybe<Scalars['String']>;
};

export type MutationPatchOrderArgs = {
  clearers: Maybe<Array<Scalars['ID']>>;
  displayVolume: Maybe<Scalars['String']>;
  hide: Maybe<Scalars['Boolean']>;
  limit: Maybe<Scalars['String']>;
  lock: Maybe<Scalars['Boolean']>;
  orderId: Maybe<Scalars['ID']>;
  stop: Maybe<Scalars['String']>;
  timeInForce: Maybe<TimeInForceEnum>;
  validFrom: Maybe<Scalars['DateTime']>;
  validUntil: Maybe<Scalars['DateTime']>;
  volume: Maybe<Scalars['String']>;
};

export type MutationClearTradeArgs = {
  tradeId: Scalars['ID'];
};

export type MutationCancelTradeArgs = {
  tradeId: Scalars['ID'];
};

export type MutationAddOrderWidgetToDashboardArgs = {
  dashboardId: Scalars['ID'];
  layout: Maybe<Scalars['GenericScalar']>;
};

export type MutationAddTradesPendingWidgetToDashboardArgs = {
  dashboardId: Scalars['ID'];
  layout: Maybe<Scalars['GenericScalar']>;
};

export type MutationAddNoteToTradeArgs = {
  tradeId: Scalars['ID'];
  body: Scalars['String'];
  parentNoteId: Maybe<Scalars['ID']>;
};

export type MutationAddNoteToTradeCandidateArgs = {
  tradeCandidateId: Scalars['ID'];
  body: Scalars['String'];
  parentNoteId: Maybe<Scalars['ID']>;
};

export type MutationAddNoteToOrderArgs = {
  orderId: Scalars['ID'];
  body: Scalars['String'];
  parentNoteId: Maybe<Scalars['ID']>;
};

export type MutationAddMarketDepthWidgetToDashboardArgs = {
  dashboardId: Scalars['ID'];
  layout: Maybe<Scalars['GenericScalar']>;
};

export type MutationAddMarketSessionWidgetToDashboardArgs = {
  dashboardId: Scalars['ID'];
  layout: Maybe<Scalars['GenericScalar']>;
};

export type MutationDeleteProductGroupArgs = {
  id: Scalars['ID'];
};

export type MutationAddProductToProductGroupArgs = {
  order: Maybe<Scalars['Int']>;
  product: Scalars['ID'];
  productGroup: Scalars['ID'];
};

export type MutationPatchProductGroupMemberArgs = {
  id: Scalars['ID'];
  order: Maybe<Scalars['Int']>;
  upstream: Maybe<Scalars['ID']>;
};

export type MutationDeleteProductGroupMemberArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateProductQuoteArgs = {
  id: Scalars['ID'];
  updates: Array<ProductQuoteInputType>;
};

export type MutationRefreshProductQuotesArgs = {
  id: Scalars['ID'];
};

export type MutationBulkUpdateProductQuotesArgs = {
  updates: Array<BulkUpdateInputType>;
};

export type MutationPatchProductGroupArgs = {
  displayName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
};

export type MutationAddTradeTapeWidgetToDashboardArgs = {
  dashboardId: Scalars['ID'];
  layout: Maybe<Scalars['GenericScalar']>;
};

export type MutationAddTradingViewWidgetToDashboardArgs = {
  dashboardId: Scalars['ID'];
  layout: Maybe<Scalars['GenericScalar']>;
};

export type MutationPatchProductGroupOrderRuleArgs = {
  enabled: Scalars['Boolean'];
  ids: Array<Scalars['ID']>;
};

export type MutationPatchMarketSessionArgs = {
  ids: Array<Scalars['ID']>;
  live: Scalars['Boolean'];
  override: Scalars['Boolean'];
};

export type MutationAddProductGroupOrderRuleWidgetToDashboardArgs = {
  dashboardId: Scalars['ID'];
  layout: Maybe<Scalars['GenericScalar']>;
};

export type MutationAddWatchlistArgs = {
  name: Scalars['String'];
};

export type MutationPatchWatchlistArgs = {
  name: Maybe<Scalars['String']>;
  pgmIds: Maybe<Array<Scalars['ID']>>;
  watchlistId: Scalars['ID'];
};

export type MutationPatchWatchlistMemberArgs = {
  memberId: Scalars['ID'];
  order: Maybe<Scalars['Int']>;
};

export type MutationDeleteWatchlistMemberArgs = {
  memberIds: Array<Scalars['ID']>;
};

export type MutationAddWatchlistWidgetToDashboardArgs = {
  dashboardId: Scalars['ID'];
  layout: Maybe<Scalars['GenericScalar']>;
  watchlistId: Scalars['ID'];
};

export type MutationAddSeasonalityChartWidgetToDashboardArgs = {
  dashboardId: Scalars['ID'];
  layout: Maybe<Scalars['GenericScalar']>;
};

export type MutationPatchUserMetadataArgs = {
  data: Maybe<Scalars['GenericScalar']>;
  delete?: Maybe<Scalars['Boolean']>;
  path: Maybe<Scalars['GenericScalar']>;
};

export type MutationRequestResetPasswordArgs = {
  origin: Scalars['String'];
  username: Scalars['String'];
};

export type MutationSubmitResetPasswordArgs = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationTokenAuthArgs = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type MutationVerifyTokenArgs = {
  token: Maybe<Scalars['String']>;
};

export type MutationRefreshTokenArgs = {
  refreshToken: Maybe<Scalars['String']>;
};

export type MutationRevokeTokenArgs = {
  refreshToken: Maybe<Scalars['String']>;
};

export type NewsFeedItemType = {
  __typename?: 'NewsFeedItemType';
  source: NewsFeedSourceType;
  title: Scalars['String'];
  url: Scalars['String'];
  timestamp: Scalars['DateTime'];
};

export type NewsFeedMediaType = {
  __typename?: 'NewsFeedMediaType';
  source: Scalars['String'];
  altText: Maybe<Scalars['String']>;
  mediaType: NewsFeedMediaTypeEnum;
};

export enum NewsFeedMediaTypeEnum {
  Image = 'IMAGE',
  Video = 'VIDEO',
}

export type NewsFeedSourceType = {
  __typename?: 'NewsFeedSourceType';
  name: Scalars['String'];
  url: Maybe<Scalars['String']>;
};

export type NewsFeedWidgetType = WidgetInterface & {
  __typename?: 'NewsFeedWidgetType';
  id: Scalars['ID'];
  dashboard: DashboardType;
  layout: Maybe<Scalars['GenericScalar']>;
  memberId: Maybe<Scalars['ID']>;
};

export type NoteType = {
  __typename?: 'NoteType';
  id: Scalars['ID'];
  body: Scalars['String'];
  createdBy: UserType;
  createdAt: Scalars['DateTime'];
  objectId: Scalars['Int'];
  children: Array<NoteType>;
  parentId: Maybe<Scalars['ID']>;
};

export type NullWidget = {
  __typename?: 'NullWidget';
  null: Maybe<Scalars['Boolean']>;
};

export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  user: Maybe<UserType>;
  token: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type OrderCreationInputType = {
  deskId: Scalars['ID'];
  side: SideEnum;
  orderType: OrderTypeEnum;
  timeInForce: TimeInForceEnum;
  volume: Maybe<Scalars['String']>;
  displayVolume: Maybe<Scalars['String']>;
  productId: Scalars['ID'];
  clearers: Array<Scalars['ID']>;
  restingOrders: Maybe<Array<Scalars['ID']>>;
  createdForId: Maybe<Scalars['ID']>;
  limit: Maybe<Scalars['String']>;
  stop: Maybe<Scalars['String']>;
  validFrom: Maybe<Scalars['DateTime']>;
  validUntil: Maybe<Scalars['DateTime']>;
  visibilityLocked: Maybe<Scalars['Boolean']>;
  visibilityPrivate: Maybe<Scalars['Boolean']>;
  flexVolume: Maybe<Scalars['Boolean']>;
  metadata: Maybe<Scalars['GenericScalar']>;
};

/** An enumeration. */
export enum OrderSideId {
  /** Bid */
  A_0 = 'A_0',
  /** Offer */
  A_1 = 'A_1',
}

/** An enumeration. */
export enum OrderStatusId {
  /** Open */
  A_0 = 'A_0',
  /** Partial Fill */
  A_1 = 'A_1',
  /** Filled */
  A_2 = 'A_2',
  /** Cancelled */
  A_3 = 'A_3',
  /** Expired */
  A_4 = 'A_4',
  /** Closed */
  A_5 = 'A_5',
  /** Pending Confirmation */
  A_6 = 'A_6',
  /** Withdrawn */
  A_7 = 'A_7',
  /** Private */
  A_8 = 'A_8',
}

export type OrderType = AnonymousOrderType &
  GuardianAbility &
  WithNotes & {
    __typename?: 'OrderType';
    id: Scalars['ID'];
    sideId: Maybe<Scalars['Int']>;
    typeId: OrderTypeId;
    statusId: Maybe<Scalars['Int']>;
    timeInForceId: Maybe<Scalars['Int']>;
    limit: Maybe<Scalars['Float']>;
    stop: Maybe<Scalars['Float']>;
    volume: Maybe<Scalars['Float']>;
    displayVolume: Scalars['Float'];
    originUpstream: Maybe<OrderType>;
    upstream: Maybe<OrderType>;
    derivedUpstream: Maybe<OrderType>;
    derivedOrders: Array<OrderType>;
    relatedDerivedOrders: Array<OrderType>;
    desk: BrokerDeskType;
    product: ProductType;
    clearers: Array<Scalars['String']>;
    createdFor: UserType;
    createdBy: UserType;
    updatedBy: Maybe<UserType>;
    validFrom: Maybe<Scalars['DateTime']>;
    validUntil: Maybe<Scalars['DateTime']>;
    publicUntil: Maybe<Scalars['DateTime']>;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    latestNote: Maybe<NoteType>;
    metadata: Maybe<Scalars['JSONString']>;
    visibilityLocked: Scalars['Boolean'];
    flexVolume: Scalars['Boolean'];
    flexVolumeBrokerVisible: Scalars['Boolean'];
    allOrNone: Scalars['Boolean'];
    originDownstreams: Array<OrderType>;
    downstreams: Array<OrderType>;
    derivedDownstreams: Array<OrderType>;
    contingentOrders: Array<OrderType>;
    relatedContingentOrders: Array<OrderType>;
    purchase: Maybe<TradeType>;
    sale: Maybe<TradeType>;
    purchaseCandidate: Maybe<TradeCandidateType>;
    saleCandidate: Maybe<TradeCandidateType>;
    deskId: Maybe<Scalars['ID']>;
    side: SideEnum;
    myOrder: Scalars['Boolean'];
    abilities: Maybe<Array<Maybe<AbilityType>>>;
    notes: Array<NoteType>;
    orderType: Maybe<OrderTypeEnum>;
    orderTypeId: Maybe<Scalars['Int']>;
    status: StatusEnum;
    timeInForce: Maybe<TimeInForceEnum>;
    productId: Maybe<Scalars['ID']>;
    ticker: Maybe<Scalars['String']>;
    isDerived: Scalars['Boolean'];
  };

/** Description not provided */
export enum OrderTypeEnum {
  /**
   * Market:
   *         Buy / Sell the specified volume at the best price currently available
   */
  Market = 'Market',
  /**
   * Limit:
   *         Buy / Sell at the specified limit price, or better
   */
  Limit = 'Limit',
  /**
   * Stop:
   *         Buy / Sell the specified volume at market price when the market hits a stop price
   */
  Stop = 'Stop',
  /**
   * Stop-Limit:
   *         Buy / Sell the specified volume at limit price or better when the market hits a stop price
   */
  StopLimit = 'StopLimit',
  /** Description not provided */
  Candidate = 'Candidate',
}

/** An enumeration. */
export enum OrderTypeId {
  /** Market */
  A_0 = 'A_0',
  /** Limit */
  A_1 = 'A_1',
  /** Stop */
  A_2 = 'A_2',
  /** Stop Limit */
  A_3 = 'A_3',
  /** Candidate */
  A_4 = 'A_4',
}

export type OrderWidgetType = WidgetInterface & {
  __typename?: 'OrderWidgetType';
  id: Scalars['ID'];
  dashboard: DashboardType;
  layout: Maybe<Scalars['GenericScalar']>;
  memberId: Maybe<Scalars['ID']>;
};

export type OrdersSubscription = {
  __typename?: 'OrdersSubscription';
  orders: Maybe<Array<Maybe<OrderType>>>;
};

export type OrganizationSsoType = {
  __typename?: 'OrganizationSSOType';
  ssoEnabled: Scalars['Boolean'];
  ssoLink: Maybe<Scalars['String']>;
};

export type PatchContactFolderMutation = {
  __typename?: 'PatchContactFolderMutation';
  clientFolder: Maybe<ClientFolderType>;
};

export type PatchContactGroupMutation = {
  __typename?: 'PatchContactGroupMutation';
  clientGroup: Maybe<ClientGroupType>;
};

export type PatchContactMutation = {
  __typename?: 'PatchContactMutation';
  clientUser: Maybe<Contactable>;
};

export type PatchDashboardMutation = {
  __typename?: 'PatchDashboardMutation';
  dashboard: Maybe<DashboardType>;
};

export type PatchMarketSessionMutation = {
  __typename?: 'PatchMarketSessionMutation';
  marketSessions: Array<MarketSessionType>;
};

export type PatchOrderMutation = {
  __typename?: 'PatchOrderMutation';
  orders: Maybe<Array<Maybe<OrderType>>>;
  orderIds: Array<Scalars['ID']>;
};

export type PatchProductGroupMemberMutation = {
  __typename?: 'PatchProductGroupMemberMutation';
  productGroup: Maybe<ProductGroupType>;
  productGroupId: Scalars['ID'];
};

export type PatchProductGroupMutation = {
  __typename?: 'PatchProductGroupMutation';
  productGroup: Maybe<ProductGroupType>;
  productGroupId: Scalars['ID'];
};

export type PatchProductGroupOrderRuleMutation = {
  __typename?: 'PatchProductGroupOrderRuleMutation';
  orderRules: Array<ProductGroupOrderRuleType>;
};

export type PatchTimeMachineChartMutation = {
  __typename?: 'PatchTimeMachineChartMutation';
  chart: Maybe<TimeMachineChartType>;
};

export type PatchTradeCandidateAccountMutation = {
  __typename?: 'PatchTradeCandidateAccountMutation';
  tradeCandidates: Array<TradeCandidateType>;
  tradeCandidateIds: Array<Scalars['ID']>;
};

export type PatchTradeCandidateBrokersMutation = {
  __typename?: 'PatchTradeCandidateBrokersMutation';
  tradeCandidates: Array<TradeCandidateType>;
  tradeCandidateIds: Array<Scalars['ID']>;
};

export type PatchTradeCandidateClearerMutation = {
  __typename?: 'PatchTradeCandidateClearerMutation';
  tradeCandidate: Maybe<TradeCandidateType>;
  tradeCandidateId: Scalars['ID'];
};

export type PatchTradeCandidateCommissionMutation = {
  __typename?: 'PatchTradeCandidateCommissionMutation';
  tradeCandidates: Array<TradeCandidateType>;
  tradeCandidateIds: Array<Scalars['ID']>;
};

export type PatchTradeCandidateMetadataMutation = {
  __typename?: 'PatchTradeCandidateMetadataMutation';
  tradeCandidates: Array<TradeCandidateType>;
  tradeCandidateIds: Array<Scalars['ID']>;
};

export type PatchTradeCandidatePricesMutation = {
  __typename?: 'PatchTradeCandidatePricesMutation';
  tradeCandidates: Array<TradeCandidateType>;
  tradeCandidateIds: Array<Scalars['ID']>;
};

export type PatchWatchlistMemberMutation = {
  __typename?: 'PatchWatchlistMemberMutation';
  memberIds: Array<Scalars['ID']>;
};

export type PatchWatchlistMutation = {
  __typename?: 'PatchWatchlistMutation';
  watchlistId: Scalars['ID'];
};

export type PatchWidgetMutation = {
  __typename?: 'PatchWidgetMutation';
  widget: Maybe<WidgetType>;
};

export type ProductActivityInterfaceType = {
  createdAt: Maybe<Scalars['DateTime']>;
  typeId: Scalars['Int'];
  price: Maybe<Scalars['Float']>;
};

export type ProductActivitySubscription = {
  __typename?: 'ProductActivitySubscription';
  activities: Maybe<Array<ProductActivityType>>;
  pgmActivities: Maybe<Array<ProductGroupMemberActivityType>>;
};

export type ProductActivityType = ProductActivityInterfaceType & {
  __typename?: 'ProductActivityType';
  venue: VenueType;
  product: ProductType;
  market: MarketType;
  productGroup: Maybe<ProductGroupType>;
  price: Maybe<Scalars['Float']>;
  volume: Maybe<Scalars['Float']>;
  typeId: Scalars['Int'];
  isDerived: Scalars['Boolean'];
  createdBy: Maybe<UserType>;
  createdAt: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
};

export type ProductGroupIndexType = {
  __typename?: 'ProductGroupIndexType';
  market: Maybe<Scalars['String']>;
  curr: Maybe<Scalars['Decimal']>;
  prev: Maybe<Scalars['Decimal']>;
  mtd: Maybe<Scalars['Decimal']>;
  ytd: Maybe<Scalars['Decimal']>;
};

export type ProductGroupMemberActivityType = ProductActivityInterfaceType & {
  __typename?: 'ProductGroupMemberActivityType';
  id: Scalars['ID'];
  venue: VenueType;
  product: ProductType;
  market: MarketType;
  productGroup: Maybe<ProductGroupType>;
  price: Maybe<Scalars['Float']>;
  volume: Maybe<Scalars['Float']>;
  typeId: Scalars['Int'];
  isDerived: Scalars['Boolean'];
  createdBy: Maybe<UserType>;
  createdAt: Maybe<Scalars['DateTime']>;
  member: ProductGroupMemberType;
  hasOrders: Scalars['Boolean'];
  isOrderOverride: Scalars['Boolean'];
};

export type ProductGroupMemberType = {
  __typename?: 'ProductGroupMemberType';
  id: Scalars['ID'];
  productGroup: ProductGroupType;
  product: ProductType;
  components: Array<ProductGroupMemberType>;
  displayOrder: Scalars['Int'];
  createdBy: UserType;
  createdAt: Scalars['DateTime'];
  updatedBy: UserType;
  updatedAt: Scalars['DateTime'];
  isIndex: Scalars['Boolean'];
  dependents: Array<ProductGroupMemberType>;
  watchlistMembers: Array<WatchlistMemberType>;
  activitiesLast: Array<ProductGroupMemberActivityType>;
};

export type ProductGroupOrderRuleSubscription = {
  __typename?: 'ProductGroupOrderRuleSubscription';
  orderRules: Maybe<Array<Maybe<ProductGroupOrderRuleType>>>;
};

export type ProductGroupOrderRuleType = {
  __typename?: 'ProductGroupOrderRuleType';
  id: Scalars['ID'];
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
};

export type ProductGroupOrderRuleWidgetType = WidgetInterface & {
  __typename?: 'ProductGroupOrderRuleWidgetType';
  id: Scalars['ID'];
  dashboard: DashboardType;
  layout: Maybe<Scalars['GenericScalar']>;
  memberId: Maybe<Scalars['ID']>;
};

export type ProductGroupSubscription = {
  __typename?: 'ProductGroupSubscription';
  productGroup: Maybe<ProductGroupType>;
};

export type ProductGroupType = GuardianAbility & {
  __typename?: 'ProductGroupType';
  id: Scalars['ID'];
  name: Scalars['String'];
  externalName: Maybe<Scalars['String']>;
  desk: BrokerDeskType;
  products: Array<ProductType>;
  createdBy: UserType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  autoManaged: Scalars['Boolean'];
  defaultColumns: Array<Column>;
  defaultTick: Scalars['Int'];
  preferOrders: Scalars['Boolean'];
  residualOrderActivity: Scalars['Boolean'];
  productMembers: Array<ProductGroupMemberType>;
  crossDesks: Array<BrokerDeskType>;
  dashboardWidgets: Array<BrokerProductGroupWidgetType>;
  abilities: Maybe<Array<Maybe<AbilityType>>>;
  displayName: Scalars['String'];
  indices: Maybe<Array<Maybe<ProductGroupIndexType>>>;
};

export type ProductQuoteInputType = {
  typeId: Scalars['Int'];
  price: Maybe<Scalars['String']>;
};

export type ProductType = GuardianAbility & {
  __typename?: 'ProductType';
  id: Scalars['ID'];
  typeId: ProductTypeId;
  name: Scalars['String'];
  market: MarketType;
  code: Scalars['String'];
  ticker: Scalars['String'];
  formula: Maybe<Scalars['String']>;
  components: Array<ProductType>;
  spread: Scalars['Boolean'];
  strip: Scalars['Boolean'];
  settlementStart: Maybe<Scalars['DateTime']>;
  settlementEnd: Maybe<Scalars['DateTime']>;
  expiresAt: Maybe<Scalars['DateTime']>;
  productGroupMembers: Array<ProductGroupMemberType>;
  abilities: Maybe<Array<Maybe<AbilityType>>>;
  type: Maybe<ProductTypeEnum>;
  fatFingerThreshold: Maybe<Scalars['Float']>;
  duration: Maybe<Scalars['Int']>;
  clearers: Array<Scalars['String']>;
  desks: Array<BrokerDeskType>;
  isTradable: Scalars['Boolean'];
};

/** An enumeration. */
export enum ProductTypeEnum {
  Concrete = 'Concrete',
  Derived = 'Derived',
  Index = 'Index',
  Balmo = 'Balmo',
}

/** An enumeration. */
export enum ProductTypeId {
  /** Concrete */
  A_0 = 'A_0',
  /** Derived */
  A_1 = 'A_1',
  /** Index */
  A_2 = 'A_2',
  /** Balmo */
  A_3 = 'A_3',
}

export type Query = {
  __typename?: 'Query';
  activityStream: Array<ActivityStreamType>;
  activityStreamWidget: Maybe<ActivityStreamWidgetType>;
  aggregatorData: Maybe<Array<Maybe<AggregatorDataType>>>;
  aggregatorProductData: Maybe<Array<Maybe<AggregatorDataType>>>;
  aggregatorSources: Maybe<Scalars['GenericScalar']>;
  allowableClearers: Array<Scalars['String']>;
  allowableTraders: Array<UserType>;
  brokerContactsWidget: BrokerContactsWidgetType;
  brokerDeskContactFolders: Array<ClientFolderType>;
  brokerDeskContactGroups: Array<ClientGroupType>;
  brokerDeskContacts: Array<UserType>;
  brokerDesks: Array<BrokerDeskType>;
  brokerProductGroupWidget: BrokerProductGroupWidgetType;
  brokerSite: BrokerSiteType;
  commissionRates: Array<CommissionType>;
  connectionId: Scalars['Int'];
  connectionStatus: Scalars['Int'];
  contactGroups: Array<ContactGroupType>;
  contacts: Array<ContactType>;
  dashboard: DashboardType;
  dashboards: Array<DashboardType>;
  desks: Array<BrokerDeskType>;
  featureFlags: Array<BrokerDeskFeatureFlagType>;
  gridBootstrap: GridBootstrapType;
  gridDeskMarket: GridDeskMarketType;
  hasConnected: Scalars['Boolean'];
  homepageNewsFeed: Array<HomePageNewsFeedItemType>;
  loggedIn: Scalars['Boolean'];
  marketDepth: Array<MarketDepthType>;
  marketDepthWidget: MarketDepthWidgetType;
  marketSessions: Array<MarketSessionType>;
  metadataOptions: Array<MetadataOptionType>;
  myOrders: Array<OrderType>;
  newsFeed: Array<Maybe<NewsFeedItemType>>;
  order: Maybe<OrderType>;
  orderRules: Array<ProductGroupOrderRuleType>;
  orderWidget: Maybe<OrderWidgetType>;
  pendingTrades: Array<TradeType>;
  permissionsHash: Scalars['Int'];
  product: Maybe<ProductType>;
  productGroup: Maybe<ProductGroupType>;
  productGroups: Array<ProductGroupType>;
  products: Array<ProductType>;
  reloadingCount: Scalars['Int'];
  screenTradeTape: Array<AnonymousTradeType>;
  squawkGroups: Maybe<Array<Maybe<SquawkGroupType>>>;
  ssoEnabled: Maybe<OrganizationSsoType>;
  systemMessage: Scalars['String'];
  systemStatus: SystemStatus;
  timemachineChart: TimeMachineChartType;
  timemachineMarkets: Array<MarketType>;
  timemachineSeries: Array<TimeMachineSeriesType>;
  tradableOrders: Array<OrderType>;
  tradeCandidates: Array<TradeCandidateType>;
  tradeHistory: Array<TradeHistoryType>;
  tradeTapeActivities: Array<ProductActivityType>;
  tradingViewWidget: TradingViewWidgetType;
  user: UserType;
  watchlists: Array<WatchlistType>;
  whatsappStreamMessages: Array<WhatsappStreamMessageType>;
};

export type QueryActivityStreamArgs = {
  afterId: Maybe<Scalars['ID']>;
  after: Maybe<Scalars['Int']>;
  pageSize: Maybe<Scalars['Int']>;
};

export type QueryActivityStreamWidgetArgs = {
  id: Scalars['ID'];
};

export type QueryAggregatorProductDataArgs = {
  productId: Scalars['ID'];
};

export type QueryAllowableClearersArgs = {
  buyerId: Scalars['ID'];
  sellerId: Scalars['ID'];
  productId: Scalars['ID'];
};

export type QueryAllowableTradersArgs = {
  deskId: Scalars['ID'];
  productId: Scalars['ID'];
  query: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
};

export type QueryBrokerContactsWidgetArgs = {
  id: Scalars['ID'];
};

export type QueryBrokerDeskContactFoldersArgs = {
  deskId: Scalars['ID'];
};

export type QueryBrokerDeskContactGroupsArgs = {
  deskId: Scalars['ID'];
};

export type QueryBrokerDeskContactsArgs = {
  deskId: Scalars['ID'];
};

export type QueryBrokerProductGroupWidgetArgs = {
  id: Scalars['ID'];
};

export type QueryCommissionRatesArgs = {
  deskId: Scalars['ID'];
};

export type QueryDashboardArgs = {
  id: Scalars['ID'];
};

export type QueryGridBootstrapArgs = {
  deskMarketIds: Array<Scalars['ID']>;
  productCodes: Array<Scalars['String']>;
};

export type QueryGridDeskMarketArgs = {
  deskMarketId: Scalars['ID'];
  productCodes: Array<Scalars['String']>;
};

export type QueryMarketDepthWidgetArgs = {
  id: Scalars['ID'];
};

export type QueryMetadataOptionsArgs = {
  metadataId: Scalars['ID'];
  query: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
};

export type QueryOrderArgs = {
  id: Scalars['ID'];
};

export type QueryOrderWidgetArgs = {
  id: Scalars['ID'];
};

export type QueryProductArgs = {
  id: Scalars['ID'];
};

export type QueryProductGroupArgs = {
  id: Scalars['ID'];
};

export type QueryProductsArgs = {
  query: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
  ordersProducts: Maybe<Scalars['Boolean']>;
  marketIds: Maybe<Array<Scalars['ID']>>;
};

export type QuerySsoEnabledArgs = {
  email: Scalars['String'];
};

export type QueryTimemachineChartArgs = {
  id: Scalars['ID'];
};

export type QueryTimemachineSeriesArgs = {
  id: Scalars['ID'];
};

export type QueryTradeHistoryArgs = {
  afterId: Maybe<Scalars['ID']>;
  after: Maybe<Scalars['Int']>;
  pageSize: Maybe<Scalars['Int']>;
};

export type QueryTradeTapeActivitiesArgs = {
  dashboardId: Maybe<Scalars['ID']>;
};

export type QueryTradingViewWidgetArgs = {
  id: Scalars['ID'];
};

export type QueryWatchlistsArgs = {
  watchlistIds: Maybe<Array<Scalars['ID']>>;
};

export type QueryWhatsappStreamMessagesArgs = {
  widgetId: Scalars['ID'];
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type RefreshProductQuotesMutation = {
  __typename?: 'RefreshProductQuotesMutation';
  refreshed: Scalars['Boolean'];
};

export type ReinstateAllOrdersMutation = {
  __typename?: 'ReinstateAllOrdersMutation';
  orders: Maybe<Array<Maybe<OrderType>>>;
  orderIds: Array<Scalars['ID']>;
};

export type ReinstateOrderMutation = {
  __typename?: 'ReinstateOrderMutation';
  orders: Maybe<Array<Maybe<OrderType>>>;
  orderIds: Array<Scalars['ID']>;
};

export type RemoveContactFolderMutation = {
  __typename?: 'RemoveContactFolderMutation';
  ok: Maybe<Scalars['Boolean']>;
};

export type RemoveContactGroupMutation = {
  __typename?: 'RemoveContactGroupMutation';
  ok: Maybe<Scalars['Boolean']>;
};

export type RemoveContactMutation = {
  __typename?: 'RemoveContactMutation';
  ok: Maybe<Scalars['Boolean']>;
};

export type RemoveDashboardMutation = {
  __typename?: 'RemoveDashboardMutation';
  dashboard: Maybe<DashboardType>;
};

export type RemoveWhatsappStreamWidgetMatchersMutation = {
  __typename?: 'RemoveWhatsappStreamWidgetMatchersMutation';
  widgets: Maybe<Array<WhatsappStreamWidgetType>>;
};

export type RemoveWidgetsFromDashboardMutation = {
  __typename?: 'RemoveWidgetsFromDashboardMutation';
  dashboard: Maybe<DashboardType>;
};

export type RequestResetPasswordMutation = {
  __typename?: 'RequestResetPasswordMutation';
  ok: Maybe<Scalars['Boolean']>;
};

export type Revoke = {
  __typename?: 'Revoke';
  revoked: Scalars['Int'];
};

export type ScreenTradeTapeSubscription = {
  __typename?: 'ScreenTradeTapeSubscription';
  trades: Array<AnonymousTradeType>;
};

export type SeasonalityChartWidgetType = WidgetInterface & {
  __typename?: 'SeasonalityChartWidgetType';
  id: Scalars['ID'];
  dashboard: DashboardType;
  layout: Maybe<Scalars['GenericScalar']>;
  memberId: Maybe<Scalars['ID']>;
};

export type SetOrderVisibilityMutation = {
  __typename?: 'SetOrderVisibilityMutation';
  orders: Maybe<Array<Maybe<OrderType>>>;
  orderIds: Array<Scalars['ID']>;
};

/** An enumeration. */
export enum SideEnum {
  Bid = 'Bid',
  Offer = 'Offer',
}

export type SquawkGroupType = {
  __typename?: 'SquawkGroupType';
  id: Scalars['ID'];
  name: Scalars['String'];
  members: Array<UserType>;
  createdBy: Maybe<UserType>;
  createdAt: Scalars['DateTime'];
};

/** An enumeration. */
export enum StatusEnum {
  Open = 'Open',
  PartialFill = 'PartialFill',
  Filled = 'Filled',
  Cancelled = 'Cancelled',
  Expired = 'Expired',
  Closed = 'Closed',
  PendingConfirmation = 'PendingConfirmation',
  Withdrawn = 'Withdrawn',
  Private = 'Private',
}

export type SubmitResetPasswordMutation = {
  __typename?: 'SubmitResetPasswordMutation';
  ok: Maybe<Scalars['Boolean']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  whatsappStreamSubscription: Maybe<WhatsappStreamSubscription>;
  activityStreamSubscription: Maybe<ActivityStreamSubscription>;
  aggregatorDataSubscription: Maybe<AggregatorDataSubscription>;
  ordersSubscription: Maybe<OrdersSubscription>;
  marketDepthSubscription: Maybe<MarketDepthSubscription>;
  tradesSubscription: Maybe<TradesSubscription>;
  tradeCandidatesSubscription: Maybe<TradeCandidateSubscription>;
  tradeCandidateSubscription: Maybe<TradeCandidateSubscription>;
  tradeHistorySubscription: Maybe<TradeHistorySubscription>;
  screenTradeTapeSubscription: Maybe<ScreenTradeTapeSubscription>;
  dashboardsSubscription: Maybe<DashboardsSubscription>;
  layoutSubscription: Maybe<DashboardWidgetSubscription>;
  brokerDeskContactsSubscription: Maybe<BrokerDeskContactsSubscription>;
  productActivitySubscription: Maybe<ProductActivitySubscription>;
  productGroupSubscription: Maybe<ProductGroupSubscription>;
  productGroupOrderRuleSubscription: Maybe<ProductGroupOrderRuleSubscription>;
  marketSessionSubscription: Maybe<MarketSessionSubscription>;
  watchlistSubscription: Maybe<WatchlistSubscription>;
};

export type SubscriptionWhatsappStreamSubscriptionArgs = {
  whatsappStreamWidgetId: Scalars['ID'];
};

export type SubscriptionOrdersSubscriptionArgs = {
  orderIds: Maybe<Array<Scalars['ID']>>;
  productIds: Maybe<Array<Scalars['ID']>>;
};

export type SubscriptionBrokerDeskContactsSubscriptionArgs = {
  deskId: Scalars['ID'];
};

export type SubscriptionProductGroupSubscriptionArgs = {
  id: Scalars['ID'];
};

export enum SystemStatus {
  Operational = 'operational',
  Maintainence = 'maintainence',
  Degraded = 'degraded',
}

export type TicketOutcome = {
  __typename?: 'TicketOutcome';
  accepted: Scalars['Boolean'];
  message: Maybe<Scalars['String']>;
};

/** Description not provided */
export enum TicketTypeEnum {
  /** Description not provided */
  Oco = 'OCO',
  /** Description not provided */
  Aon = 'AON',
}

/** Description not provided */
export enum TimeInForceEnum {
  /**
   * Fill Or Kill:
   *         Attempt to completely fill when placed, immediately cancel if not possible
   */
  Fok = 'FOK',
  /**
   * Good unTil Cancelled:
   *         Order persists until filled or cancelled
   */
  Gtc = 'GTC',
  /**
   * Good unTil Date:
   *         Order expires at a specified a specified date/time
   */
  Gtd = 'GTD',
  /**
   * Immediate Or Cancel:
   *         Fill as much as possible when placed, then cancel
   */
  Ioc = 'IOC',
  /** All Or None */
  Aon = 'AON',
}

export enum TimeMachineChartDisplayTypeEnum {
  Raw = 'RAW',
  Indexed = 'INDEXED',
  Percentage = 'PERCENTAGE',
}

export type TimeMachineChartType = {
  __typename?: 'TimeMachineChartType';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  chartType: TimeMachineChartTypeEnum;
  displayType: TimeMachineChartDisplayTypeEnum;
  dateStart: Scalars['DateTime'];
  dateEnd: Maybe<Scalars['DateTime']>;
  excludedYears: Array<Scalars['String']>;
  formula: Scalars['String'];
  hasAverage: Scalars['Boolean'];
  createdBy: UserType;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  dashboardWidgets: Array<TimeMachineChartWidgetType>;
  decimalPlaces: Scalars['Int'];
};

export enum TimeMachineChartTypeEnum {
  Formula = 'FORMULA',
  Spread = 'SPREAD',
  Butterfly = 'BUTTERFLY',
}

export type TimeMachineChartWidgetType = WidgetInterface & {
  __typename?: 'TimeMachineChartWidgetType';
  id: Scalars['ID'];
  dashboard: DashboardType;
  layout: Maybe<Scalars['GenericScalar']>;
  chart: TimeMachineChartType;
  memberId: Maybe<Scalars['ID']>;
};

export type TimeMachineSeriesType = {
  __typename?: 'TimeMachineSeriesType';
  name: Scalars['String'];
  timestamps: Array<Scalars['Int']>;
  values: Array<Maybe<Scalars['String']>>;
};

export type TradeCandidateIdType = {
  __typename?: 'TradeCandidateIdType';
  id: Scalars['ID'];
  upstreamId: Maybe<Scalars['ID']>;
};

export type TradeCandidatePrice = {
  tradeCandidateId: Scalars['ID'];
  price: Scalars['String'];
};

export type TradeCandidateSubscription = {
  __typename?: 'TradeCandidateSubscription';
  tradeCandidates: Maybe<Array<Maybe<TradeCandidateType>>>;
};

export type TradeCandidateType = WithNotes &
  CommonTradeType & {
    __typename?: 'TradeCandidateType';
    id: Scalars['ID'];
    originUpstream: Maybe<TradeCandidateType>;
    upstream: Maybe<TradeCandidateType>;
    bid: OrderType;
    offer: OrderType;
    volume: Scalars['Float'];
    price: Maybe<Scalars['Float']>;
    bidAccount: Maybe<AccountType>;
    bidCommissionRate: Maybe<CommissionType>;
    offerAccount: Maybe<AccountType>;
    offerCommissionRate: Maybe<CommissionType>;
    bidBroker: Maybe<UserType>;
    offerBroker: Maybe<UserType>;
    trade: Maybe<TradeType>;
    createdAt: Scalars['DateTime'];
    updatedAt: Scalars['DateTime'];
    latestNote: Maybe<NoteType>;
    metadata: Maybe<Scalars['JSONString']>;
    originDownstreams: Array<TradeCandidateType>;
    downstreams: Array<TradeCandidateType>;
    notes: Array<NoteType>;
    ticker: Scalars['String'];
    market: MarketType;
    buyerOrganization: Scalars['String'];
    buyer: UserType;
    sellerOrganization: Scalars['String'];
    seller: UserType;
    clearerCode: Maybe<Scalars['String']>;
    allowableClearers: Array<Scalars['String']>;
    status: Scalars['String'];
  };

/** An enumeration. */
export enum TradeClearingStatusId {
  /** Pending */
  A_0 = 'A_0',
  /** Submitted */
  A_2 = 'A_2',
  /** Cleared */
  A_1 = 'A_1',
  /** Cancelled */
  A_3 = 'A_3',
}

export type TradeEntryMutation = {
  __typename?: 'TradeEntryMutation';
  tradeCandidates: Maybe<Array<Maybe<TradeCandidateType>>>;
  tradeCandidateIds: Array<TradeCandidateIdType>;
};

export type TradeHistorySubscription = {
  __typename?: 'TradeHistorySubscription';
  trades: Maybe<Array<Maybe<TradeHistoryType>>>;
};

export type TradeHistoryType = {
  __typename?: 'TradeHistoryType';
  id: Scalars['ID'];
  volume: Scalars['Decimal'];
  price: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  metadata: Maybe<Scalars['JSONString']>;
  bidBroker: Maybe<UserType>;
  offerBroker: Maybe<UserType>;
  clearers: Array<Scalars['String']>;
  bidClient: Maybe<UserType>;
  offerClient: Maybe<UserType>;
  product: ProductType;
  side: Maybe<SideEnum>;
  tradeId: Maybe<Scalars['ID']>;
  fulfilled: Scalars['Boolean'];
  cancelled: Scalars['Boolean'];
  invoiceAvailable: Scalars['Boolean'];
};

export type TradeTapeWidgetType = WidgetInterface & {
  __typename?: 'TradeTapeWidgetType';
  id: Scalars['ID'];
  dashboard: DashboardType;
  layout: Maybe<Scalars['GenericScalar']>;
  memberId: Maybe<Scalars['ID']>;
};

export type TradeType = WithNotes &
  CommonTradeType & {
    __typename?: 'TradeType';
    id: Scalars['ID'];
    originUpstream: Maybe<TradeType>;
    upstream: Maybe<TradeType>;
    clearingStatusId: TradeClearingStatusId;
    bid: OrderType;
    offer: OrderType;
    bidAccount: Maybe<AccountType>;
    bidCommissionRate: Maybe<CommissionType>;
    offerAccount: Maybe<AccountType>;
    offerCommissionRate: Maybe<CommissionType>;
    bidBroker: Maybe<UserType>;
    offerBroker: Maybe<UserType>;
    volume: Scalars['Float'];
    price: Scalars['Float'];
    executedBy: UserType;
    clearedBy: Maybe<UserType>;
    externalClearerId: Maybe<Scalars['String']>;
    externalClearerStatus: Maybe<Scalars['String']>;
    clearedAt: Maybe<Scalars['DateTime']>;
    createdAt: Scalars['DateTime'];
    latestNote: Maybe<NoteType>;
    metadata: Maybe<Scalars['JSONString']>;
    invoiceAvailable: Scalars['Boolean'];
    originDownstreams: Array<TradeType>;
    downstreams: Array<TradeType>;
    tradecandidate: Maybe<TradeCandidateType>;
    notes: Array<NoteType>;
    ticker: Scalars['String'];
    market: MarketType;
    buyerOrganization: Scalars['String'];
    buyer: UserType;
    sellerOrganization: Scalars['String'];
    seller: UserType;
    clearerCode: Maybe<Scalars['String']>;
    allowableClearers: Array<Scalars['String']>;
    clearingId: Scalars['String'];
    clearingStatus: Maybe<ClearingStatusEnum>;
  };

export type TradesPendingWidgetType = WidgetInterface & {
  __typename?: 'TradesPendingWidgetType';
  id: Scalars['ID'];
  dashboard: DashboardType;
  layout: Maybe<Scalars['GenericScalar']>;
  memberId: Maybe<Scalars['ID']>;
};

export type TradesSubscription = {
  __typename?: 'TradesSubscription';
  trades: Maybe<Array<Maybe<TradeType>>>;
};

export type TradingViewWidgetType = WidgetInterface & {
  __typename?: 'TradingViewWidgetType';
  id: Scalars['ID'];
  dashboard: DashboardType;
  layout: Maybe<Scalars['GenericScalar']>;
  memberId: Maybe<Scalars['ID']>;
};

export type UpdateProductQuoteMutation = {
  __typename?: 'UpdateProductQuoteMutation';
  activities: Maybe<Array<Maybe<ProductGroupMemberActivityType>>>;
  activityIds: Array<Scalars['ID']>;
};

export type UserType = Contactable &
  WithMetaData & {
    __typename?: 'UserType';
    id: Maybe<Scalars['ID']>;
    /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
    username: Scalars['String'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    email: Scalars['String'];
    clearers: Array<Scalars['String']>;
    abilities: Maybe<Array<AbilityType>>;
    featureFlags: Array<BrokerDeskFeatureFlagType>;
    name: Maybe<Scalars['String']>;
    lastContacted: Maybe<Scalars['DateTime']>;
    mobileNumber: Maybe<Scalars['String']>;
    wechatIds: Array<Scalars['String']>;
    skypeIds: Array<Scalars['String']>;
    whatsappEnabled: Maybe<Scalars['Boolean']>;
    wechatEnabled: Maybe<Scalars['Boolean']>;
    eikonEnabled: Maybe<Scalars['Boolean']>;
    skypeEnabled: Maybe<Scalars['Boolean']>;
    metadata: Maybe<Scalars['GenericScalar']>;
    organizationName: Maybe<Scalars['String']>;
    organizationCode: Maybe<Scalars['String']>;
    accounts: Array<AccountType>;
  };

/** An enumeration. */
export enum ValueType {
  String = 'STRING',
  Integer = 'INTEGER',
  Date = 'DATE',
  Datetime = 'DATETIME',
  Enum = 'ENUM',
}

export type VenueType = {
  __typename?: 'VenueType';
  id: Scalars['ID'];
  name: Scalars['String'];
  markets: Array<MarketType>;
};

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar'];
};

export type WatchlistMemberType = {
  __typename?: 'WatchlistMemberType';
  id: Scalars['ID'];
  watchlist: WatchlistType;
  pgm: ProductGroupMemberType;
  displayOrder: Scalars['Int'];
  createdAt: Scalars['DateTime'];
};

export type WatchlistSubscription = {
  __typename?: 'WatchlistSubscription';
  watchlists: Maybe<Array<Maybe<WatchlistType>>>;
};

export type WatchlistType = {
  __typename?: 'WatchlistType';
  id: Scalars['ID'];
  name: Scalars['String'];
  owner: UserType;
  createdAt: Scalars['DateTime'];
  members: Array<WatchlistMemberType>;
  dashboardWidgets: Array<WatchlistWidgetType>;
};

export type WatchlistWidgetType = WidgetInterface & {
  __typename?: 'WatchlistWidgetType';
  id: Scalars['ID'];
  dashboard: DashboardType;
  layout: Maybe<Scalars['GenericScalar']>;
  watchlist: WatchlistType;
  memberId: Maybe<Scalars['ID']>;
};

export type WhatsappMessageSenderType = {
  __typename?: 'WhatsappMessageSenderType';
  mobile: Scalars['String'];
  name: Maybe<Scalars['String']>;
  shortName: Maybe<Scalars['String']>;
  pushName: Maybe<Scalars['String']>;
  groupName: Maybe<Scalars['String']>;
  user: Maybe<UserType>;
};

export type WhatsappStreamMessageType = {
  __typename?: 'WhatsappStreamMessageType';
  body: Scalars['String'];
  sender: WhatsappMessageSenderType;
  timestamp: Scalars['Int'];
};

export type WhatsappStreamSubscription = {
  __typename?: 'WhatsappStreamSubscription';
  message: Maybe<WhatsappStreamMessageType>;
  widget: Maybe<WhatsappStreamWidgetType>;
};

export type WhatsappStreamWidgetMatcherType = {
  __typename?: 'WhatsappStreamWidgetMatcherType';
  id: Scalars['ID'];
  name: Scalars['String'];
  pattern: Scalars['String'];
  widget: WhatsappStreamWidgetType;
};

export type WhatsappStreamWidgetType = WidgetInterface & {
  __typename?: 'WhatsappStreamWidgetType';
  id: Scalars['ID'];
  dashboard: DashboardType;
  layout: Maybe<Scalars['GenericScalar']>;
  matchers: Array<WhatsappStreamWidgetMatcherType>;
  memberId: Maybe<Scalars['ID']>;
};

export type WidgetInterface = {
  id: Scalars['ID'];
  memberId: Maybe<Scalars['ID']>;
  layout: Maybe<Scalars['GenericScalar']>;
};

export type WidgetType =
  | NullWidget
  | MarineTrafficWidgetType
  | ActivityStreamWidgetType
  | TradeTapeWidgetType
  | TradingViewWidgetType
  | MarketSessionWidgetType
  | ProductGroupOrderRuleWidgetType
  | WatchlistWidgetType
  | SeasonalityChartWidgetType
  | OrderWidgetType
  | TradesPendingWidgetType
  | MarketDepthWidgetType
  | BrokerBlastWidgetType
  | BrokerContactsWidgetType
  | BrokerProductGroupWidgetType
  | MarketGridWidgetType
  | WhatsappStreamWidgetType
  | NewsFeedWidgetType
  | TimeMachineChartWidgetType;

export type WithMetaData = {
  metadata: Maybe<Scalars['GenericScalar']>;
};

export type WithNotes = {
  notes: Array<NoteType>;
};

export type WithdrawAllOrdersMutation = {
  __typename?: 'WithdrawAllOrdersMutation';
  orders: Maybe<Array<Maybe<OrderType>>>;
  orderIds: Array<Scalars['ID']>;
};

export type WithdrawOrderMutation = {
  __typename?: 'WithdrawOrderMutation';
  orders: Maybe<Array<Maybe<OrderType>>>;
  orderIds: Array<Scalars['ID']>;
};

export type AuthRefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;

export type AuthRefreshTokenMutation = { __typename?: 'Mutation' } & {
  refreshToken: Maybe<
    { __typename?: 'Refresh' } & Pick<Refresh, 'token' | 'refreshToken'>
  >;
};

export type AuthTokenRefreshMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;

export type AuthTokenRefreshMutation = { __typename?: 'Mutation' } & {
  refreshToken: Maybe<
    { __typename?: 'Refresh' } & Pick<Refresh, 'token' | 'refreshToken'>
  >;
};

export type AbilityFragment = { __typename: 'AbilityType' } & Pick<
  AbilityType,
  'actions' | 'subject' | 'conditions' | 'fields' | 'inverted' | 'reason'
>;

export type UserPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type UserPermissionsQuery = { __typename?: 'Query' } & {
  user: { __typename?: 'UserType' } & Pick<UserType, 'id'> & {
      abilities: Maybe<Array<{ __typename?: 'AbilityType' } & AbilityFragment>>;
    };
};

export type UserAvatarQueryVariables = Exact<{ [key: string]: never }>;

export type UserAvatarQuery = { __typename?: 'Query' } & {
  user: { __typename?: 'UserType' } & Pick<
    UserType,
    'name' | 'lastName' | 'firstName'
  >;
};

export type ContactGroupsQueryVariables = Exact<{
  deskId: Scalars['ID'];
}>;

export type ContactGroupsQuery = { __typename?: 'Query' } & {
  brokerDeskContactGroups: Array<
    { __typename?: 'ClientGroupType' } & ContactGroupFragment
  >;
};

export type ContactGroupFragment = { __typename?: 'ClientGroupType' } & Pick<
  ClientGroupType,
  'id' | 'name'
>;

export type BlastMessageMutationVariables = Exact<{
  body: Scalars['String'];
  contactGroups: Maybe<Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>>;
  deskId: Maybe<Scalars['ID']>;
}>;

export type BlastMessageMutation = { __typename?: 'Mutation' } & {
  blast: Maybe<
    { __typename?: 'BlastMutation' } & {
      contactGroups: Maybe<
        Array<
          Maybe<
            { __typename?: 'ClientGroupType' } & Pick<ClientGroupType, 'id'>
          >
        >
      >;
    }
  >;
};

export type PatchMyOrderPriceMutationVariables = Exact<{
  orderId: Scalars['ID'];
  limit: Maybe<Scalars['String']>;
}>;

export type PatchMyOrderPriceMutation = { __typename?: 'Mutation' } & {
  patchOrder: Maybe<
    { __typename?: 'PatchOrderMutation' } & Pick<PatchOrderMutation, 'orderIds'>
  >;
};

export type PatchMyOrderVolumeMutationVariables = Exact<{
  orderId: Scalars['ID'];
  volume: Maybe<Scalars['String']>;
  displayVolume: Maybe<Scalars['String']>;
}>;

export type PatchMyOrderVolumeMutation = { __typename?: 'Mutation' } & {
  patchOrder: Maybe<
    { __typename?: 'PatchOrderMutation' } & Pick<PatchOrderMutation, 'orderIds'>
  >;
};

export type GoogleAnalyticsUserQueryVariables = Exact<{ [key: string]: never }>;

export type GoogleAnalyticsUserQuery = { __typename?: 'Query' } & {
  user: { __typename: 'UserType' } & Pick<UserType, 'id'>;
};

export type UserFragmentFragment = { __typename: 'UserType' } & Pick<
  UserType,
  | 'id'
  | 'username'
  | 'firstName'
  | 'lastName'
  | 'clearers'
  | 'name'
  | 'metadata'
>;

export type OrderFragmentFragment = { __typename: 'OrderType' } & Pick<
  OrderType,
  | 'id'
  | 'limit'
  | 'stop'
  | 'volume'
  | 'displayVolume'
  | 'isDerived'
  | 'validFrom'
  | 'validUntil'
  | 'createdAt'
  | 'clearers'
  | 'orderType'
  | 'side'
  | 'status'
  | 'timeInForce'
  | 'productId'
  | 'ticker'
  | 'deskId'
  | 'publicUntil'
> & {
    originUpstream: Maybe<{ __typename: 'OrderType' } & Pick<OrderType, 'id'>>;
    product: { __typename: 'ProductType' } & Pick<
      ProductType,
      'id' | 'name' | 'code' | 'clearers' | 'ticker' | 'fatFingerThreshold'
    > & {
        market: { __typename?: 'MarketType' } & Pick<
          MarketType,
          | 'id'
          | 'name'
          | 'code'
          | 'timeZone'
          | 'timeClose'
          | 'timeOpen'
          | 'decimalPlaces'
          | 'displayColor'
        >;
      };
    createdFor: { __typename?: 'UserType' } & Pick<
      UserType,
      'organizationName'
    > &
      UserFragmentFragment;
    createdBy: { __typename?: 'UserType' } & UserFragmentFragment;
    updatedBy: Maybe<{ __typename?: 'UserType' } & UserFragmentFragment>;
    originDownstreams: Array<
      { __typename: 'OrderType' } & Pick<OrderType, 'id'>
    >;
  };

export type MyOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type MyOrdersQuery = { __typename?: 'Query' } & {
  myOrders: Array<{ __typename?: 'OrderType' } & OrderFragmentFragment>;
};

export type OrdersSubSubscriptionVariables = Exact<{
  orderIds: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  productIds: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type OrdersSubSubscription = { __typename?: 'Subscription' } & {
  ordersSubscription: Maybe<
    { __typename?: 'OrdersSubscription' } & {
      orders: Maybe<
        Array<Maybe<{ __typename?: 'OrderType' } & OrderFragmentFragment>>
      >;
    }
  >;
};

export type PatchMyOrderClearersMutationVariables = Exact<{
  orderId: Scalars['ID'];
  clearers: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type PatchMyOrderClearersMutation = { __typename?: 'Mutation' } & {
  patchOrder: Maybe<
    { __typename?: 'PatchOrderMutation' } & Pick<PatchOrderMutation, 'orderIds'>
  >;
};

export type WithdrawMyOrderMutationVariables = Exact<{
  orderId: Scalars['ID'];
}>;

export type WithdrawMyOrderMutation = { __typename?: 'Mutation' } & {
  withdrawOrder: Maybe<
    { __typename?: 'WithdrawOrderMutation' } & Pick<
      WithdrawOrderMutation,
      'orderIds'
    >
  >;
};

export type ReinstateMyOrderMutationVariables = Exact<{
  orderId: Scalars['ID'];
}>;

export type ReinstateMyOrderMutation = { __typename?: 'Mutation' } & {
  reinstateOrder: Maybe<
    { __typename?: 'ReinstateOrderMutation' } & Pick<
      ReinstateOrderMutation,
      'orderIds'
    >
  >;
};

export type CancelMyOrderMutationVariables = Exact<{
  orderId: Scalars['ID'];
}>;

export type CancelMyOrderMutation = { __typename?: 'Mutation' } & {
  cancelOrder: Maybe<
    { __typename?: 'CancelOrderMutation' } & Pick<
      CancelOrderMutation,
      'orderIds'
    >
  >;
};

export type PatchMyOrderTifMutationVariables = Exact<{
  orderId: Scalars['ID'];
  timeInForce: Maybe<TimeInForceEnum>;
  validUntil: Maybe<Scalars['DateTime']>;
}>;

export type PatchMyOrderTifMutation = { __typename?: 'Mutation' } & {
  patchOrder: Maybe<
    { __typename?: 'PatchOrderMutation' } & Pick<PatchOrderMutation, 'orderIds'>
  >;
};

export type ClientOrderFragment = { __typename?: 'MarketDepthType' } & Pick<
  MarketDepthType,
  | 'id'
  | 'limit'
  | 'displayVolume'
  | 'validUntil'
  | 'createdAt'
  | 'clearers'
  | 'side'
  | 'status'
  | 'deskId'
  | 'myOrder'
> & { product: { __typename?: 'ProductType' } & ClientOrderProductFragment };

export type ClientOrderProductFragment = { __typename?: 'ProductType' } & Pick<
  ProductType,
  | 'id'
  | 'code'
  | 'clearers'
  | 'settlementStart'
  | 'settlementEnd'
  | 'strip'
  | 'spread'
  | 'duration'
> & {
    market: { __typename?: 'MarketType' } & Pick<
      MarketType,
      'name' | 'id' | 'code' | 'decimalPlaces' | 'displayColor'
    >;
  };

export type OrderBookQueryVariables = Exact<{ [key: string]: never }>;

export type OrderBookQuery = { __typename?: 'Query' } & {
  marketDepth: Array<{ __typename?: 'MarketDepthType' } & ClientOrderFragment>;
};

export type OrderBookSubSubscriptionVariables = Exact<{ [key: string]: never }>;

export type OrderBookSubSubscription = { __typename?: 'Subscription' } & {
  marketDepthSubscription: Maybe<
    { __typename?: 'MarketDepthSubscription' } & {
      orders: Maybe<
        Array<Maybe<{ __typename?: 'MarketDepthType' } & ClientOrderFragment>>
      >;
    }
  >;
};

export type UserClearersQueryVariables = Exact<{ [key: string]: never }>;

export type UserClearersQuery = { __typename?: 'Query' } & {
  user: { __typename: 'UserType' } & Pick<UserType, 'clearers' | 'id'>;
};

export type CreateMyOrderMutationVariables = Exact<{
  side: SideEnum;
  orderType: OrderTypeEnum;
  timeInForce: TimeInForceEnum;
  volume: Scalars['String'];
  displayVolume: Maybe<Scalars['String']>;
  productId: Scalars['ID'];
  createdForId: Maybe<Scalars['ID']>;
  limit: Maybe<Scalars['String']>;
  validUntil: Maybe<Scalars['DateTime']>;
  clearers: Array<Scalars['ID']> | Scalars['ID'];
  deskId: Scalars['ID'];
  cancelOnPartial: Maybe<Scalars['Boolean']>;
}>;

export type CreateMyOrderMutation = { __typename?: 'Mutation' } & {
  createOrder: Maybe<
    { __typename?: 'CreateOrderMutation' } & Pick<
      CreateOrderMutation,
      'orderIds'
    >
  >;
};

export type CreateTradeCandidatesForNewMyOrderMutationVariables = Exact<{
  side: SideEnum;
  orderType: OrderTypeEnum;
  timeInForce: TimeInForceEnum;
  volume: Scalars['String'];
  productId: Scalars['ID'];
  createdForId: Maybe<Scalars['ID']>;
  limit: Maybe<Scalars['String']>;
  restingOrders: Array<Scalars['ID']> | Scalars['ID'];
  clearers: Array<Scalars['ID']> | Scalars['ID'];
  deskId: Scalars['ID'];
}>;

export type CreateTradeCandidatesForNewMyOrderMutation = {
  __typename?: 'Mutation';
} & {
  createTradeCandidatesForNewOrder: Maybe<
    { __typename?: 'CreateTradeCandidatesForNewOrder' } & {
      tradeCandidateIds: Array<
        { __typename?: 'TradeCandidateIdType' } & Pick<
          TradeCandidateIdType,
          'id' | 'upstreamId'
        >
      >;
    }
  >;
};

export type ProductsViewProductGroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ProductsViewProductGroupQuery = { __typename?: 'Query' } & {
  productGroup: Maybe<
    { __typename?: 'ProductGroupType' } & Pick<
      ProductGroupType,
      'id' | 'name' | 'defaultColumns' | 'defaultTick'
    > & {
        desk: { __typename?: 'BrokerDeskType' } & Pick<BrokerDeskType, 'id'>;
        productMembers: Array<
          { __typename?: 'ProductGroupMemberType' } & ProductGroupMemberFragment
        >;
      }
  >;
};

export type SystemStatusQueryVariables = Exact<{ [key: string]: never }>;

export type SystemStatusQuery = { __typename?: 'Query' } & Pick<
  Query,
  'systemStatus' | 'systemMessage'
>;

export type ThemeContainerUserQueryVariables = Exact<{ [key: string]: never }>;

export type ThemeContainerUserQuery = { __typename?: 'Query' } & {
  user: { __typename: 'UserType' } & Pick<UserType, 'id' | 'metadata'>;
};

export type TradeHistoryQueryVariables = Exact<{
  afterId: Maybe<Scalars['ID']>;
  after: Maybe<Scalars['Int']>;
  pageSize: Maybe<Scalars['Int']>;
}>;

export type TradeHistoryQuery = { __typename?: 'Query' } & {
  tradeHistory: Array<
    { __typename?: 'TradeHistoryType' } & TradeHistoryFragment
  >;
};

export type UserTradeHistorySubSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type UserTradeHistorySubSubscription = {
  __typename?: 'Subscription';
} & {
  tradeHistorySubscription: Maybe<
    { __typename?: 'TradeHistorySubscription' } & {
      trades: Maybe<
        Array<Maybe<{ __typename?: 'TradeHistoryType' } & TradeHistoryFragment>>
      >;
    }
  >;
};

export type TradeHistoryFragment = { __typename?: 'TradeHistoryType' } & Pick<
  TradeHistoryType,
  | 'id'
  | 'price'
  | 'clearers'
  | 'updatedAt'
  | 'side'
  | 'tradeId'
  | 'volume'
  | 'fulfilled'
  | 'cancelled'
> & {
    offerBroker: Maybe<
      { __typename?: 'UserType' } & Pick<UserType, 'id' | 'name'>
    >;
    offerClient: Maybe<
      { __typename?: 'UserType' } & Pick<
        UserType,
        'id' | 'name' | 'organizationName'
      >
    >;
    bidBroker: Maybe<
      { __typename?: 'UserType' } & Pick<UserType, 'id' | 'name'>
    >;
    bidClient: Maybe<
      { __typename?: 'UserType' } & Pick<
        UserType,
        'id' | 'name' | 'organizationName'
      >
    >;
    product: { __typename?: 'ProductType' } & Pick<ProductType, 'code'> & {
        market: { __typename?: 'MarketType' } & Pick<
          MarketType,
          'id' | 'decimalPlaces' | 'code' | 'displayColor'
        >;
      };
  };

export type TradeTapeActivitiesQueryVariables = Exact<{
  dashboardId: Maybe<Scalars['ID']>;
}>;

export type TradeTapeActivitiesQuery = { __typename?: 'Query' } & {
  tradeTapeActivities: Array<
    { __typename?: 'ProductActivityType' } & TradeTapeActivityFragment
  >;
};

export type TradeTapeActivityFragment = {
  __typename?: 'ProductActivityType';
} & Pick<ProductActivityType, 'price' | 'volume' | 'typeId' | 'createdAt'> & {
    product: { __typename?: 'ProductType' } & Pick<
      ProductType,
      'id' | 'ticker' | 'code' | 'name'
    > & {
        market: { __typename?: 'MarketType' } & Pick<
          MarketType,
          'id' | 'code' | 'decimalPlaces' | 'displayColor'
        >;
      };
  };

export type UserInfoQueryVariables = Exact<{ [key: string]: never }>;

export type UserInfoQuery = { __typename?: 'Query' } & {
  user: { __typename?: 'UserType' } & Pick<
    UserType,
    | 'name'
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'username'
    | 'organizationName'
  >;
};

export type MarketsPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type MarketsPermissionsQuery = { __typename?: 'Query' } & {
  desks: Array<
    { __typename?: 'BrokerDeskType' } & Pick<BrokerDeskType, 'id'> & {
        abilities: Maybe<
          Array<Maybe<{ __typename?: 'AbilityType' } & AbilityFragment>>
        >;
        markets: Array<
          { __typename?: 'BrokerDeskMarketType' } & Pick<
            BrokerDeskMarketType,
            'id'
          > & {
              market: { __typename?: 'MarketType' } & Pick<MarketType, 'id'> & {
                  abilities: Maybe<
                    Array<
                      Maybe<{ __typename?: 'AbilityType' } & AbilityFragment>
                    >
                  >;
                };
            }
        >;
      }
  >;
};

export type ActivityStreamQueryVariables = Exact<{
  afterId: Maybe<Scalars['ID']>;
  after: Maybe<Scalars['Int']>;
  pageSize: Maybe<Scalars['Int']>;
}>;

export type ActivityStreamQuery = { __typename?: 'Query' } & {
  activityStream: Array<
    { __typename?: 'ActivityStreamType' } & ActivityFragmentFragment
  >;
};

export type ActivityFragmentFragment = {
  __typename?: 'ActivityStreamType';
} & Pick<ActivityStreamType, 'id' | 'user' | 'message' | 'isOwnStream' | 'ts'>;

export type WebsocketConnectionIdQueryVariables = Exact<{
  [key: string]: never;
}>;

export type WebsocketConnectionIdQuery = { __typename?: 'Query' } & Pick<
  Query,
  'connectionId'
>;

export type HasConnectedQueryVariables = Exact<{ [key: string]: never }>;

export type HasConnectedQuery = { __typename?: 'Query' } & Pick<
  Query,
  'hasConnected'
>;

export type ConnectionStatusQueryVariables = Exact<{ [key: string]: never }>;

export type ConnectionStatusQuery = { __typename?: 'Query' } & Pick<
  Query,
  'connectionStatus'
>;

export type LoggedInQueryVariables = Exact<{ [key: string]: never }>;

export type LoggedInQuery = { __typename?: 'Query' } & Pick<Query, 'loggedIn'>;

export type PermissionsHashQueryVariables = Exact<{ [key: string]: never }>;

export type PermissionsHashQuery = { __typename?: 'Query' } & Pick<
  Query,
  'permissionsHash'
>;

export type ReloadingCountQueryVariables = Exact<{ [key: string]: never }>;

export type ReloadingCountQuery = { __typename?: 'Query' } & Pick<
  Query,
  'reloadingCount'
>;

export type BlastDeskQueryVariables = Exact<{ [key: string]: never }>;

export type BlastDeskQuery = { __typename?: 'Query' } & {
  brokerDesks: Array<
    { __typename?: 'BrokerDeskType' } & Pick<BrokerDeskType, 'id'>
  >;
};

export type HomepageMarketsQueryVariables = Exact<{ [key: string]: never }>;

export type HomepageMarketsQuery = { __typename?: 'Query' } & {
  brokerSite: { __typename?: 'BrokerSiteType' } & Pick<BrokerSiteType, 'id'> & {
      products: Array<
        { __typename?: 'ProductType' } & Pick<ProductType, 'id'> & {
            market: { __typename?: 'MarketType' } & Pick<
              MarketType,
              'decimalPlaces'
            >;
          }
      >;
      ticker: Array<
        { __typename?: 'BrokerSiteTickerGroup' } & Pick<
          BrokerSiteTickerGroup,
          'name'
        > & {
            products: Array<
              { __typename?: 'BrokerSiteTickerProduct' } & Pick<
                BrokerSiteTickerProduct,
                'id' | 'name' | 'price' | 'prevClose'
              >
            >;
          }
      >;
    };
};

export type LoginMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;

export type LoginMutation = { __typename?: 'Mutation' } & {
  tokenAuth: Maybe<
    { __typename?: 'ObtainJSONWebToken' } & Pick<
      ObtainJsonWebToken,
      'token' | 'refreshToken'
    > & {
        user: Maybe<
          { __typename?: 'UserType' } & Pick<
            UserType,
            'id' | 'username' | 'firstName'
          > & {
              abilities: Maybe<
                Array<{ __typename?: 'AbilityType' } & AbilityFragment>
              >;
            }
        >;
      }
  >;
};

export type RequestPasswordResetMutationVariables = Exact<{
  origin: Scalars['String'];
  username: Scalars['String'];
}>;

export type RequestPasswordResetMutation = { __typename?: 'Mutation' } & {
  requestResetPassword: Maybe<
    { __typename?: 'RequestResetPasswordMutation' } & Pick<
      RequestResetPasswordMutation,
      'ok'
    >
  >;
};

export type AuthStrategyQueryVariables = Exact<{ [key: string]: never }>;

export type AuthStrategyQuery = { __typename?: 'Query' } & {
  brokerSite: { __typename?: 'BrokerSiteType' } & Pick<
    BrokerSiteType,
    'ssoLink'
  >;
};

export type SsoEnabledQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type SsoEnabledQuery = { __typename?: 'Query' } & {
  ssoEnabled: Maybe<
    { __typename?: 'OrganizationSSOType' } & Pick<
      OrganizationSsoType,
      'ssoEnabled' | 'ssoLink'
    >
  >;
};

export type ProductsViewProductGroupsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ProductsViewProductGroupsQuery = { __typename?: 'Query' } & {
  productGroups: Array<
    { __typename?: 'ProductGroupType' } & ProductGroupFragment
  >;
};

export type ProductIndexesQueryVariables = Exact<{ [key: string]: never }>;

export type ProductIndexesQuery = { __typename?: 'Query' } & {
  productGroups: Array<
    { __typename?: 'ProductGroupType' } & Pick<ProductGroupType, 'id'> & {
        indices: Maybe<
          Array<Maybe<{ __typename?: 'ProductGroupIndexType' } & IndexFragment>>
        >;
      }
  >;
};

export type ProductGroupFragment = { __typename?: 'ProductGroupType' } & Pick<
  ProductGroupType,
  'id' | 'name'
> & {
    productMembers: Array<
      { __typename?: 'ProductGroupMemberType' } & ShallowPgmFragment
    >;
  };

export type ShallowPgmFragment = {
  __typename?: 'ProductGroupMemberType';
} & Pick<ProductGroupMemberType, 'id'> & {
    product: { __typename?: 'ProductType' } & Pick<
      ProductType,
      'ticker' | 'name' | 'id'
    > & {
        market: { __typename?: 'MarketType' } & Pick<
          MarketType,
          'id' | 'code' | 'displayColor'
        >;
      };
  };

export type IndexFragment = { __typename?: 'ProductGroupIndexType' } & Pick<
  ProductGroupIndexType,
  'curr' | 'market' | 'mtd' | 'ytd' | 'prev'
>;

export type ProductGroupMemberFragment = {
  __typename?: 'ProductGroupMemberType';
} & Pick<ProductGroupMemberType, 'id' | 'updatedAt' | 'isIndex'> & {
    activitiesLast: Array<
      {
        __typename?: 'ProductGroupMemberActivityType';
      } & ProductGroupMemberActivityFragment
    >;
    product: { __typename?: 'ProductType' } & Pick<
      ProductType,
      | 'id'
      | 'code'
      | 'name'
      | 'ticker'
      | 'clearers'
      | 'fatFingerThreshold'
      | 'isTradable'
    > & {
        market: { __typename?: 'MarketType' } & Pick<
          MarketType,
          | 'decimalPlaces'
          | 'id'
          | 'displayColor'
          | 'code'
          | 'timeZone'
          | 'timeClose'
          | 'timeOpen'
          | 'defaultVolume'
        > & {
            abilities: Maybe<
              Array<Maybe<{ __typename?: 'AbilityType' } & AbilityFragment>>
            >;
          };
      };
  };

export type AllProductActivitySubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type AllProductActivitySubscription = { __typename?: 'Subscription' } & {
  productActivitySubscription: Maybe<
    { __typename?: 'ProductActivitySubscription' } & {
      activities: Maybe<
        Array<{ __typename?: 'ProductActivityType' } & ProductActivityFragment>
      >;
      pgmActivities: Maybe<
        Array<
          {
            __typename?: 'ProductGroupMemberActivityType';
          } & ProductGroupMemberActivityFragment
        >
      >;
    }
  >;
};

export type ProductActivityFragment = {
  __typename?: 'ProductActivityType';
} & Pick<
  ProductActivityType,
  'id' | 'typeId' | 'isDerived' | 'price' | 'volume' | 'createdAt'
> & {
    product: { __typename?: 'ProductType' } & Pick<
      ProductType,
      'id' | 'name' | 'code' | 'ticker'
    > & {
        market: { __typename?: 'MarketType' } & Pick<
          MarketType,
          'id' | 'name' | 'code' | 'decimalPlaces' | 'displayColor'
        >;
      };
  };

export type ProductGroupMemberActivityFragment = {
  __typename?: 'ProductGroupMemberActivityType';
} & Pick<
  ProductGroupMemberActivityType,
  'id' | 'typeId' | 'isDerived' | 'price' | 'volume' | 'createdAt'
> & {
    product: { __typename?: 'ProductType' } & Pick<
      ProductType,
      'id' | 'name' | 'code' | 'ticker'
    > & {
        market: { __typename?: 'MarketType' } & Pick<
          MarketType,
          'id' | 'name' | 'code' | 'decimalPlaces' | 'displayColor'
        >;
      };
  };

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;

export type ResetPasswordMutation = { __typename?: 'Mutation' } & {
  submitResetPassword: Maybe<
    { __typename?: 'SubmitResetPasswordMutation' } & Pick<
      SubmitResetPasswordMutation,
      'ok'
    >
  >;
};

export const ContactGroupFragmentDoc = gql`
  fragment contactGroup on ClientGroupType {
    id
    name
  }
`;
export const UserFragmentFragmentDoc = gql`
  fragment userFragment on UserType {
    id
    username
    firstName
    lastName
    clearers
    name
    metadata
    __typename
  }
`;
export const OrderFragmentFragmentDoc = gql`
  fragment orderFragment on OrderType {
    id
    limit
    stop
    volume
    displayVolume
    isDerived
    originUpstream {
      id
      __typename
    }
    product {
      id
      name
      code
      clearers
      ticker
      fatFingerThreshold
      __typename
      market {
        id
        name
        code
        timeZone
        timeClose
        timeOpen
        decimalPlaces
        displayColor
      }
    }
    createdFor {
      ...userFragment
      organizationName
    }
    createdBy {
      ...userFragment
    }
    updatedBy {
      ...userFragment
    }
    validFrom
    validUntil
    createdAt
    clearers
    originDownstreams {
      id
      __typename
    }
    orderType
    side
    status
    timeInForce
    productId
    ticker
    deskId
    publicUntil
    __typename
  }
  ${UserFragmentFragmentDoc}
`;
export const ClientOrderProductFragmentDoc = gql`
  fragment clientOrderProduct on ProductType {
    id
    code
    clearers
    settlementStart
    settlementEnd
    strip
    spread
    duration
    market {
      name
      id
      code
      decimalPlaces
      displayColor
    }
  }
`;
export const ClientOrderFragmentDoc = gql`
  fragment clientOrder on MarketDepthType {
    id
    limit
    displayVolume
    product {
      ...clientOrderProduct
    }
    validUntil
    createdAt
    clearers
    side
    status
    deskId
    myOrder
  }
  ${ClientOrderProductFragmentDoc}
`;
export const TradeHistoryFragmentDoc = gql`
  fragment tradeHistory on TradeHistoryType {
    id
    price
    clearers
    updatedAt
    offerBroker {
      id
      name
    }
    offerClient {
      id
      name
      organizationName
    }
    bidBroker {
      id
      name
    }
    bidClient {
      id
      name
      organizationName
    }
    product {
      code
      market {
        id
        decimalPlaces
        code
        displayColor
      }
    }
    side
    tradeId
    volume
    fulfilled
    cancelled
  }
`;
export const TradeTapeActivityFragmentDoc = gql`
  fragment tradeTapeActivity on ProductActivityType {
    product {
      id
      ticker
      code
      name
      market {
        id
        code
        decimalPlaces
        displayColor
      }
    }
    price
    volume
    typeId
    createdAt
  }
`;
export const ActivityFragmentFragmentDoc = gql`
  fragment activityFragment on ActivityStreamType {
    id
    user
    message
    isOwnStream
    ts
  }
`;
export const ShallowPgmFragmentDoc = gql`
  fragment shallowPGM on ProductGroupMemberType {
    id
    product {
      ticker
      name
      id
      market {
        id
        code
        displayColor
      }
    }
  }
`;
export const ProductGroupFragmentDoc = gql`
  fragment productGroup on ProductGroupType {
    id
    name
    productMembers {
      ...shallowPGM
    }
  }
  ${ShallowPgmFragmentDoc}
`;
export const IndexFragmentDoc = gql`
  fragment index on ProductGroupIndexType {
    curr
    market
    mtd
    ytd
    prev
  }
`;
export const ProductGroupMemberActivityFragmentDoc = gql`
  fragment productGroupMemberActivity on ProductGroupMemberActivityType {
    id
    typeId
    isDerived
    price
    volume
    createdAt
    product {
      id
      name
      code
      ticker
      market {
        id
        name
        code
        decimalPlaces
        displayColor
      }
    }
  }
`;
export const AbilityFragmentDoc = gql`
  fragment ability on AbilityType {
    actions
    subject
    conditions
    fields
    inverted
    reason
    __typename
  }
`;
export const ProductGroupMemberFragmentDoc = gql`
  fragment productGroupMember on ProductGroupMemberType {
    activitiesLast {
      ...productGroupMemberActivity
    }
    id
    updatedAt
    isIndex
    product {
      id
      code
      name
      ticker
      clearers
      fatFingerThreshold
      isTradable
      market {
        decimalPlaces
        id
        displayColor
        code
        timeZone
        timeClose
        timeOpen
        defaultVolume
        abilities {
          ...ability
        }
      }
    }
  }
  ${ProductGroupMemberActivityFragmentDoc}
  ${AbilityFragmentDoc}
`;
export const ProductActivityFragmentDoc = gql`
  fragment productActivity on ProductActivityType {
    id
    typeId
    isDerived
    price
    volume
    createdAt
    product {
      id
      name
      code
      ticker
      market {
        id
        name
        code
        decimalPlaces
        displayColor
      }
    }
  }
`;
export const AuthRefreshTokenDocument = gql`
  mutation AuthRefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`;
export type AuthRefreshTokenMutationFn = Apollo.MutationFunction<
  AuthRefreshTokenMutation,
  AuthRefreshTokenMutationVariables
>;

/**
 * __useAuthRefreshTokenMutation__
 *
 * To run a mutation, you first call `useAuthRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authRefreshTokenMutation, { data, loading, error }] = useAuthRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useAuthRefreshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthRefreshTokenMutation,
    AuthRefreshTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthRefreshTokenMutation,
    AuthRefreshTokenMutationVariables
  >(AuthRefreshTokenDocument, options);
}
export type AuthRefreshTokenMutationHookResult = ReturnType<
  typeof useAuthRefreshTokenMutation
>;
export type AuthRefreshTokenMutationResult =
  Apollo.MutationResult<AuthRefreshTokenMutation>;
export type AuthRefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  AuthRefreshTokenMutation,
  AuthRefreshTokenMutationVariables
>;
export const AuthTokenRefreshDocument = gql`
  mutation authTokenRefresh($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`;
export type AuthTokenRefreshMutationFn = Apollo.MutationFunction<
  AuthTokenRefreshMutation,
  AuthTokenRefreshMutationVariables
>;

/**
 * __useAuthTokenRefreshMutation__
 *
 * To run a mutation, you first call `useAuthTokenRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthTokenRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authTokenRefreshMutation, { data, loading, error }] = useAuthTokenRefreshMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useAuthTokenRefreshMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthTokenRefreshMutation,
    AuthTokenRefreshMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthTokenRefreshMutation,
    AuthTokenRefreshMutationVariables
  >(AuthTokenRefreshDocument, options);
}
export type AuthTokenRefreshMutationHookResult = ReturnType<
  typeof useAuthTokenRefreshMutation
>;
export type AuthTokenRefreshMutationResult =
  Apollo.MutationResult<AuthTokenRefreshMutation>;
export type AuthTokenRefreshMutationOptions = Apollo.BaseMutationOptions<
  AuthTokenRefreshMutation,
  AuthTokenRefreshMutationVariables
>;
export const UserPermissionsDocument = gql`
  query userPermissions {
    user {
      id
      abilities {
        ...ability
      }
    }
  }
  ${AbilityFragmentDoc}
`;

/**
 * __useUserPermissionsQuery__
 *
 * To run a query within a React component, call `useUserPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserPermissionsQuery,
    UserPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserPermissionsQuery, UserPermissionsQueryVariables>(
    UserPermissionsDocument,
    options
  );
}
export function useUserPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserPermissionsQuery,
    UserPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UserPermissionsQuery,
    UserPermissionsQueryVariables
  >(UserPermissionsDocument, options);
}
export type UserPermissionsQueryHookResult = ReturnType<
  typeof useUserPermissionsQuery
>;
export type UserPermissionsLazyQueryHookResult = ReturnType<
  typeof useUserPermissionsLazyQuery
>;
export type UserPermissionsQueryResult = Apollo.QueryResult<
  UserPermissionsQuery,
  UserPermissionsQueryVariables
>;
export const UserAvatarDocument = gql`
  query UserAvatar {
    user {
      name
      lastName
      firstName
    }
  }
`;

/**
 * __useUserAvatarQuery__
 *
 * To run a query within a React component, call `useUserAvatarQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAvatarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAvatarQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserAvatarQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserAvatarQuery,
    UserAvatarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserAvatarQuery, UserAvatarQueryVariables>(
    UserAvatarDocument,
    options
  );
}
export function useUserAvatarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserAvatarQuery,
    UserAvatarQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserAvatarQuery, UserAvatarQueryVariables>(
    UserAvatarDocument,
    options
  );
}
export type UserAvatarQueryHookResult = ReturnType<typeof useUserAvatarQuery>;
export type UserAvatarLazyQueryHookResult = ReturnType<
  typeof useUserAvatarLazyQuery
>;
export type UserAvatarQueryResult = Apollo.QueryResult<
  UserAvatarQuery,
  UserAvatarQueryVariables
>;
export const ContactGroupsDocument = gql`
  query contactGroups($deskId: ID!) {
    brokerDeskContactGroups(deskId: $deskId) {
      ...contactGroup
    }
  }
  ${ContactGroupFragmentDoc}
`;

/**
 * __useContactGroupsQuery__
 *
 * To run a query within a React component, call `useContactGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactGroupsQuery({
 *   variables: {
 *      deskId: // value for 'deskId'
 *   },
 * });
 */
export function useContactGroupsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ContactGroupsQuery,
    ContactGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ContactGroupsQuery, ContactGroupsQueryVariables>(
    ContactGroupsDocument,
    options
  );
}
export function useContactGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContactGroupsQuery,
    ContactGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ContactGroupsQuery, ContactGroupsQueryVariables>(
    ContactGroupsDocument,
    options
  );
}
export type ContactGroupsQueryHookResult = ReturnType<
  typeof useContactGroupsQuery
>;
export type ContactGroupsLazyQueryHookResult = ReturnType<
  typeof useContactGroupsLazyQuery
>;
export type ContactGroupsQueryResult = Apollo.QueryResult<
  ContactGroupsQuery,
  ContactGroupsQueryVariables
>;
export const BlastMessageDocument = gql`
  mutation blastMessage($body: String!, $contactGroups: [ID], $deskId: ID) {
    blast(body: $body, contactGroups: $contactGroups, deskId: $deskId) {
      contactGroups {
        id
      }
    }
  }
`;
export type BlastMessageMutationFn = Apollo.MutationFunction<
  BlastMessageMutation,
  BlastMessageMutationVariables
>;

/**
 * __useBlastMessageMutation__
 *
 * To run a mutation, you first call `useBlastMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlastMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blastMessageMutation, { data, loading, error }] = useBlastMessageMutation({
 *   variables: {
 *      body: // value for 'body'
 *      contactGroups: // value for 'contactGroups'
 *      deskId: // value for 'deskId'
 *   },
 * });
 */
export function useBlastMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BlastMessageMutation,
    BlastMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BlastMessageMutation,
    BlastMessageMutationVariables
  >(BlastMessageDocument, options);
}
export type BlastMessageMutationHookResult = ReturnType<
  typeof useBlastMessageMutation
>;
export type BlastMessageMutationResult =
  Apollo.MutationResult<BlastMessageMutation>;
export type BlastMessageMutationOptions = Apollo.BaseMutationOptions<
  BlastMessageMutation,
  BlastMessageMutationVariables
>;
export const PatchMyOrderPriceDocument = gql`
  mutation patchMyOrderPrice($orderId: ID!, $limit: String) {
    patchOrder(orderId: $orderId, limit: $limit) {
      orderIds
    }
  }
`;
export type PatchMyOrderPriceMutationFn = Apollo.MutationFunction<
  PatchMyOrderPriceMutation,
  PatchMyOrderPriceMutationVariables
>;

/**
 * __usePatchMyOrderPriceMutation__
 *
 * To run a mutation, you first call `usePatchMyOrderPriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchMyOrderPriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchMyOrderPriceMutation, { data, loading, error }] = usePatchMyOrderPriceMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePatchMyOrderPriceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PatchMyOrderPriceMutation,
    PatchMyOrderPriceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PatchMyOrderPriceMutation,
    PatchMyOrderPriceMutationVariables
  >(PatchMyOrderPriceDocument, options);
}
export type PatchMyOrderPriceMutationHookResult = ReturnType<
  typeof usePatchMyOrderPriceMutation
>;
export type PatchMyOrderPriceMutationResult =
  Apollo.MutationResult<PatchMyOrderPriceMutation>;
export type PatchMyOrderPriceMutationOptions = Apollo.BaseMutationOptions<
  PatchMyOrderPriceMutation,
  PatchMyOrderPriceMutationVariables
>;
export const PatchMyOrderVolumeDocument = gql`
  mutation patchMyOrderVolume(
    $orderId: ID!
    $volume: String
    $displayVolume: String
  ) {
    patchOrder(
      orderId: $orderId
      volume: $volume
      displayVolume: $displayVolume
    ) {
      orderIds
    }
  }
`;
export type PatchMyOrderVolumeMutationFn = Apollo.MutationFunction<
  PatchMyOrderVolumeMutation,
  PatchMyOrderVolumeMutationVariables
>;

/**
 * __usePatchMyOrderVolumeMutation__
 *
 * To run a mutation, you first call `usePatchMyOrderVolumeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchMyOrderVolumeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchMyOrderVolumeMutation, { data, loading, error }] = usePatchMyOrderVolumeMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      volume: // value for 'volume'
 *      displayVolume: // value for 'displayVolume'
 *   },
 * });
 */
export function usePatchMyOrderVolumeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PatchMyOrderVolumeMutation,
    PatchMyOrderVolumeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PatchMyOrderVolumeMutation,
    PatchMyOrderVolumeMutationVariables
  >(PatchMyOrderVolumeDocument, options);
}
export type PatchMyOrderVolumeMutationHookResult = ReturnType<
  typeof usePatchMyOrderVolumeMutation
>;
export type PatchMyOrderVolumeMutationResult =
  Apollo.MutationResult<PatchMyOrderVolumeMutation>;
export type PatchMyOrderVolumeMutationOptions = Apollo.BaseMutationOptions<
  PatchMyOrderVolumeMutation,
  PatchMyOrderVolumeMutationVariables
>;
export const GoogleAnalyticsUserDocument = gql`
  query GoogleAnalyticsUser {
    user {
      id
      __typename
    }
  }
`;

/**
 * __useGoogleAnalyticsUserQuery__
 *
 * To run a query within a React component, call `useGoogleAnalyticsUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleAnalyticsUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoogleAnalyticsUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGoogleAnalyticsUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GoogleAnalyticsUserQuery,
    GoogleAnalyticsUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GoogleAnalyticsUserQuery,
    GoogleAnalyticsUserQueryVariables
  >(GoogleAnalyticsUserDocument, options);
}
export function useGoogleAnalyticsUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GoogleAnalyticsUserQuery,
    GoogleAnalyticsUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GoogleAnalyticsUserQuery,
    GoogleAnalyticsUserQueryVariables
  >(GoogleAnalyticsUserDocument, options);
}
export type GoogleAnalyticsUserQueryHookResult = ReturnType<
  typeof useGoogleAnalyticsUserQuery
>;
export type GoogleAnalyticsUserLazyQueryHookResult = ReturnType<
  typeof useGoogleAnalyticsUserLazyQuery
>;
export type GoogleAnalyticsUserQueryResult = Apollo.QueryResult<
  GoogleAnalyticsUserQuery,
  GoogleAnalyticsUserQueryVariables
>;
export const MyOrdersDocument = gql`
  query myOrders {
    myOrders {
      ...orderFragment
    }
  }
  ${OrderFragmentFragmentDoc}
`;

/**
 * __useMyOrdersQuery__
 *
 * To run a query within a React component, call `useMyOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<MyOrdersQuery, MyOrdersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyOrdersQuery, MyOrdersQueryVariables>(
    MyOrdersDocument,
    options
  );
}
export function useMyOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyOrdersQuery,
    MyOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyOrdersQuery, MyOrdersQueryVariables>(
    MyOrdersDocument,
    options
  );
}
export type MyOrdersQueryHookResult = ReturnType<typeof useMyOrdersQuery>;
export type MyOrdersLazyQueryHookResult = ReturnType<
  typeof useMyOrdersLazyQuery
>;
export type MyOrdersQueryResult = Apollo.QueryResult<
  MyOrdersQuery,
  MyOrdersQueryVariables
>;
export const OrdersSubDocument = gql`
  subscription ordersSub($orderIds: [ID!], $productIds: [ID!]) {
    ordersSubscription(orderIds: $orderIds, productIds: $productIds) {
      orders {
        ...orderFragment
      }
    }
  }
  ${OrderFragmentFragmentDoc}
`;

/**
 * __useOrdersSubSubscription__
 *
 * To run a query within a React component, call `useOrdersSubSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrdersSubSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersSubSubscription({
 *   variables: {
 *      orderIds: // value for 'orderIds'
 *      productIds: // value for 'productIds'
 *   },
 * });
 */
export function useOrdersSubSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OrdersSubSubscription,
    OrdersSubSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OrdersSubSubscription,
    OrdersSubSubscriptionVariables
  >(OrdersSubDocument, options);
}
export type OrdersSubSubscriptionHookResult = ReturnType<
  typeof useOrdersSubSubscription
>;
export type OrdersSubSubscriptionResult =
  Apollo.SubscriptionResult<OrdersSubSubscription>;
export const PatchMyOrderClearersDocument = gql`
  mutation patchMyOrderClearers($orderId: ID!, $clearers: [ID!]) {
    patchOrder(orderId: $orderId, clearers: $clearers) {
      orderIds
    }
  }
`;
export type PatchMyOrderClearersMutationFn = Apollo.MutationFunction<
  PatchMyOrderClearersMutation,
  PatchMyOrderClearersMutationVariables
>;

/**
 * __usePatchMyOrderClearersMutation__
 *
 * To run a mutation, you first call `usePatchMyOrderClearersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchMyOrderClearersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchMyOrderClearersMutation, { data, loading, error }] = usePatchMyOrderClearersMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      clearers: // value for 'clearers'
 *   },
 * });
 */
export function usePatchMyOrderClearersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PatchMyOrderClearersMutation,
    PatchMyOrderClearersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PatchMyOrderClearersMutation,
    PatchMyOrderClearersMutationVariables
  >(PatchMyOrderClearersDocument, options);
}
export type PatchMyOrderClearersMutationHookResult = ReturnType<
  typeof usePatchMyOrderClearersMutation
>;
export type PatchMyOrderClearersMutationResult =
  Apollo.MutationResult<PatchMyOrderClearersMutation>;
export type PatchMyOrderClearersMutationOptions = Apollo.BaseMutationOptions<
  PatchMyOrderClearersMutation,
  PatchMyOrderClearersMutationVariables
>;
export const WithdrawMyOrderDocument = gql`
  mutation withdrawMyOrder($orderId: ID!) {
    withdrawOrder(orderId: $orderId) {
      orderIds
    }
  }
`;
export type WithdrawMyOrderMutationFn = Apollo.MutationFunction<
  WithdrawMyOrderMutation,
  WithdrawMyOrderMutationVariables
>;

/**
 * __useWithdrawMyOrderMutation__
 *
 * To run a mutation, you first call `useWithdrawMyOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawMyOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawMyOrderMutation, { data, loading, error }] = useWithdrawMyOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useWithdrawMyOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WithdrawMyOrderMutation,
    WithdrawMyOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    WithdrawMyOrderMutation,
    WithdrawMyOrderMutationVariables
  >(WithdrawMyOrderDocument, options);
}
export type WithdrawMyOrderMutationHookResult = ReturnType<
  typeof useWithdrawMyOrderMutation
>;
export type WithdrawMyOrderMutationResult =
  Apollo.MutationResult<WithdrawMyOrderMutation>;
export type WithdrawMyOrderMutationOptions = Apollo.BaseMutationOptions<
  WithdrawMyOrderMutation,
  WithdrawMyOrderMutationVariables
>;
export const ReinstateMyOrderDocument = gql`
  mutation reinstateMyOrder($orderId: ID!) {
    reinstateOrder(orderId: $orderId) {
      orderIds
    }
  }
`;
export type ReinstateMyOrderMutationFn = Apollo.MutationFunction<
  ReinstateMyOrderMutation,
  ReinstateMyOrderMutationVariables
>;

/**
 * __useReinstateMyOrderMutation__
 *
 * To run a mutation, you first call `useReinstateMyOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReinstateMyOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reinstateMyOrderMutation, { data, loading, error }] = useReinstateMyOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useReinstateMyOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReinstateMyOrderMutation,
    ReinstateMyOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReinstateMyOrderMutation,
    ReinstateMyOrderMutationVariables
  >(ReinstateMyOrderDocument, options);
}
export type ReinstateMyOrderMutationHookResult = ReturnType<
  typeof useReinstateMyOrderMutation
>;
export type ReinstateMyOrderMutationResult =
  Apollo.MutationResult<ReinstateMyOrderMutation>;
export type ReinstateMyOrderMutationOptions = Apollo.BaseMutationOptions<
  ReinstateMyOrderMutation,
  ReinstateMyOrderMutationVariables
>;
export const CancelMyOrderDocument = gql`
  mutation cancelMyOrder($orderId: ID!) {
    cancelOrder(orderId: $orderId) {
      orderIds
    }
  }
`;
export type CancelMyOrderMutationFn = Apollo.MutationFunction<
  CancelMyOrderMutation,
  CancelMyOrderMutationVariables
>;

/**
 * __useCancelMyOrderMutation__
 *
 * To run a mutation, you first call `useCancelMyOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelMyOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelMyOrderMutation, { data, loading, error }] = useCancelMyOrderMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useCancelMyOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelMyOrderMutation,
    CancelMyOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelMyOrderMutation,
    CancelMyOrderMutationVariables
  >(CancelMyOrderDocument, options);
}
export type CancelMyOrderMutationHookResult = ReturnType<
  typeof useCancelMyOrderMutation
>;
export type CancelMyOrderMutationResult =
  Apollo.MutationResult<CancelMyOrderMutation>;
export type CancelMyOrderMutationOptions = Apollo.BaseMutationOptions<
  CancelMyOrderMutation,
  CancelMyOrderMutationVariables
>;
export const PatchMyOrderTifDocument = gql`
  mutation patchMyOrderTif(
    $orderId: ID!
    $timeInForce: TimeInForceEnum
    $validUntil: DateTime
  ) {
    patchOrder(
      orderId: $orderId
      timeInForce: $timeInForce
      validUntil: $validUntil
    ) {
      orderIds
    }
  }
`;
export type PatchMyOrderTifMutationFn = Apollo.MutationFunction<
  PatchMyOrderTifMutation,
  PatchMyOrderTifMutationVariables
>;

/**
 * __usePatchMyOrderTifMutation__
 *
 * To run a mutation, you first call `usePatchMyOrderTifMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePatchMyOrderTifMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [patchMyOrderTifMutation, { data, loading, error }] = usePatchMyOrderTifMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      timeInForce: // value for 'timeInForce'
 *      validUntil: // value for 'validUntil'
 *   },
 * });
 */
export function usePatchMyOrderTifMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PatchMyOrderTifMutation,
    PatchMyOrderTifMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PatchMyOrderTifMutation,
    PatchMyOrderTifMutationVariables
  >(PatchMyOrderTifDocument, options);
}
export type PatchMyOrderTifMutationHookResult = ReturnType<
  typeof usePatchMyOrderTifMutation
>;
export type PatchMyOrderTifMutationResult =
  Apollo.MutationResult<PatchMyOrderTifMutation>;
export type PatchMyOrderTifMutationOptions = Apollo.BaseMutationOptions<
  PatchMyOrderTifMutation,
  PatchMyOrderTifMutationVariables
>;
export const OrderBookDocument = gql`
  query orderBook {
    marketDepth {
      ...clientOrder
    }
  }
  ${ClientOrderFragmentDoc}
`;

/**
 * __useOrderBookQuery__
 *
 * To run a query within a React component, call `useOrderBookQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderBookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBookQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrderBookQuery(
  baseOptions?: Apollo.QueryHookOptions<OrderBookQuery, OrderBookQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderBookQuery, OrderBookQueryVariables>(
    OrderBookDocument,
    options
  );
}
export function useOrderBookLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderBookQuery,
    OrderBookQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderBookQuery, OrderBookQueryVariables>(
    OrderBookDocument,
    options
  );
}
export type OrderBookQueryHookResult = ReturnType<typeof useOrderBookQuery>;
export type OrderBookLazyQueryHookResult = ReturnType<
  typeof useOrderBookLazyQuery
>;
export type OrderBookQueryResult = Apollo.QueryResult<
  OrderBookQuery,
  OrderBookQueryVariables
>;
export const OrderBookSubDocument = gql`
  subscription orderBookSub {
    marketDepthSubscription {
      orders {
        ...clientOrder
      }
    }
  }
  ${ClientOrderFragmentDoc}
`;

/**
 * __useOrderBookSubSubscription__
 *
 * To run a query within a React component, call `useOrderBookSubSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrderBookSubSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderBookSubSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOrderBookSubSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    OrderBookSubSubscription,
    OrderBookSubSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    OrderBookSubSubscription,
    OrderBookSubSubscriptionVariables
  >(OrderBookSubDocument, options);
}
export type OrderBookSubSubscriptionHookResult = ReturnType<
  typeof useOrderBookSubSubscription
>;
export type OrderBookSubSubscriptionResult =
  Apollo.SubscriptionResult<OrderBookSubSubscription>;
export const UserClearersDocument = gql`
  query userClearers {
    user {
      clearers
      id
      __typename
    }
  }
`;

/**
 * __useUserClearersQuery__
 *
 * To run a query within a React component, call `useUserClearersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserClearersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserClearersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserClearersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserClearersQuery,
    UserClearersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserClearersQuery, UserClearersQueryVariables>(
    UserClearersDocument,
    options
  );
}
export function useUserClearersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserClearersQuery,
    UserClearersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserClearersQuery, UserClearersQueryVariables>(
    UserClearersDocument,
    options
  );
}
export type UserClearersQueryHookResult = ReturnType<
  typeof useUserClearersQuery
>;
export type UserClearersLazyQueryHookResult = ReturnType<
  typeof useUserClearersLazyQuery
>;
export type UserClearersQueryResult = Apollo.QueryResult<
  UserClearersQuery,
  UserClearersQueryVariables
>;
export const CreateMyOrderDocument = gql`
  mutation createMyOrder(
    $side: SideEnum!
    $orderType: OrderTypeEnum!
    $timeInForce: TimeInForceEnum!
    $volume: String!
    $displayVolume: String
    $productId: ID!
    $createdForId: ID
    $limit: String
    $validUntil: DateTime
    $clearers: [ID!]!
    $deskId: ID!
    $cancelOnPartial: Boolean
  ) {
    createOrder(
      side: $side
      orderType: $orderType
      timeInForce: $timeInForce
      volume: $volume
      displayVolume: $displayVolume
      productId: $productId
      createdForId: $createdForId
      limit: $limit
      validUntil: $validUntil
      clearers: $clearers
      deskId: $deskId
      cancelOnPartial: $cancelOnPartial
    ) {
      orderIds
    }
  }
`;
export type CreateMyOrderMutationFn = Apollo.MutationFunction<
  CreateMyOrderMutation,
  CreateMyOrderMutationVariables
>;

/**
 * __useCreateMyOrderMutation__
 *
 * To run a mutation, you first call `useCreateMyOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyOrderMutation, { data, loading, error }] = useCreateMyOrderMutation({
 *   variables: {
 *      side: // value for 'side'
 *      orderType: // value for 'orderType'
 *      timeInForce: // value for 'timeInForce'
 *      volume: // value for 'volume'
 *      displayVolume: // value for 'displayVolume'
 *      productId: // value for 'productId'
 *      createdForId: // value for 'createdForId'
 *      limit: // value for 'limit'
 *      validUntil: // value for 'validUntil'
 *      clearers: // value for 'clearers'
 *      deskId: // value for 'deskId'
 *      cancelOnPartial: // value for 'cancelOnPartial'
 *   },
 * });
 */
export function useCreateMyOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMyOrderMutation,
    CreateMyOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateMyOrderMutation,
    CreateMyOrderMutationVariables
  >(CreateMyOrderDocument, options);
}
export type CreateMyOrderMutationHookResult = ReturnType<
  typeof useCreateMyOrderMutation
>;
export type CreateMyOrderMutationResult =
  Apollo.MutationResult<CreateMyOrderMutation>;
export type CreateMyOrderMutationOptions = Apollo.BaseMutationOptions<
  CreateMyOrderMutation,
  CreateMyOrderMutationVariables
>;
export const CreateTradeCandidatesForNewMyOrderDocument = gql`
  mutation createTradeCandidatesForNewMyOrder(
    $side: SideEnum!
    $orderType: OrderTypeEnum!
    $timeInForce: TimeInForceEnum!
    $volume: String!
    $productId: ID!
    $createdForId: ID
    $limit: String
    $restingOrders: [ID!]!
    $clearers: [ID!]!
    $deskId: ID!
  ) {
    createTradeCandidatesForNewOrder(
      side: $side
      orderType: $orderType
      timeInForce: $timeInForce
      volume: $volume
      productId: $productId
      createdForId: $createdForId
      limit: $limit
      restingOrders: $restingOrders
      clearers: $clearers
      deskId: $deskId
    ) {
      tradeCandidateIds {
        id
        upstreamId
      }
    }
  }
`;
export type CreateTradeCandidatesForNewMyOrderMutationFn =
  Apollo.MutationFunction<
    CreateTradeCandidatesForNewMyOrderMutation,
    CreateTradeCandidatesForNewMyOrderMutationVariables
  >;

/**
 * __useCreateTradeCandidatesForNewMyOrderMutation__
 *
 * To run a mutation, you first call `useCreateTradeCandidatesForNewMyOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTradeCandidatesForNewMyOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTradeCandidatesForNewMyOrderMutation, { data, loading, error }] = useCreateTradeCandidatesForNewMyOrderMutation({
 *   variables: {
 *      side: // value for 'side'
 *      orderType: // value for 'orderType'
 *      timeInForce: // value for 'timeInForce'
 *      volume: // value for 'volume'
 *      productId: // value for 'productId'
 *      createdForId: // value for 'createdForId'
 *      limit: // value for 'limit'
 *      restingOrders: // value for 'restingOrders'
 *      clearers: // value for 'clearers'
 *      deskId: // value for 'deskId'
 *   },
 * });
 */
export function useCreateTradeCandidatesForNewMyOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTradeCandidatesForNewMyOrderMutation,
    CreateTradeCandidatesForNewMyOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTradeCandidatesForNewMyOrderMutation,
    CreateTradeCandidatesForNewMyOrderMutationVariables
  >(CreateTradeCandidatesForNewMyOrderDocument, options);
}
export type CreateTradeCandidatesForNewMyOrderMutationHookResult = ReturnType<
  typeof useCreateTradeCandidatesForNewMyOrderMutation
>;
export type CreateTradeCandidatesForNewMyOrderMutationResult =
  Apollo.MutationResult<CreateTradeCandidatesForNewMyOrderMutation>;
export type CreateTradeCandidatesForNewMyOrderMutationOptions =
  Apollo.BaseMutationOptions<
    CreateTradeCandidatesForNewMyOrderMutation,
    CreateTradeCandidatesForNewMyOrderMutationVariables
  >;
export const ProductsViewProductGroupDocument = gql`
  query ProductsViewProductGroup($id: ID!) {
    productGroup(id: $id) {
      id
      name
      defaultColumns
      defaultTick
      desk {
        id
      }
      productMembers {
        ...productGroupMember
      }
    }
  }
  ${ProductGroupMemberFragmentDoc}
`;

/**
 * __useProductsViewProductGroupQuery__
 *
 * To run a query within a React component, call `useProductsViewProductGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsViewProductGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsViewProductGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductsViewProductGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProductsViewProductGroupQuery,
    ProductsViewProductGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProductsViewProductGroupQuery,
    ProductsViewProductGroupQueryVariables
  >(ProductsViewProductGroupDocument, options);
}
export function useProductsViewProductGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsViewProductGroupQuery,
    ProductsViewProductGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductsViewProductGroupQuery,
    ProductsViewProductGroupQueryVariables
  >(ProductsViewProductGroupDocument, options);
}
export type ProductsViewProductGroupQueryHookResult = ReturnType<
  typeof useProductsViewProductGroupQuery
>;
export type ProductsViewProductGroupLazyQueryHookResult = ReturnType<
  typeof useProductsViewProductGroupLazyQuery
>;
export type ProductsViewProductGroupQueryResult = Apollo.QueryResult<
  ProductsViewProductGroupQuery,
  ProductsViewProductGroupQueryVariables
>;
export const SystemStatusDocument = gql`
  query systemStatus {
    systemStatus @client
    systemMessage @client
  }
`;

/**
 * __useSystemStatusQuery__
 *
 * To run a query within a React component, call `useSystemStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSystemStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSystemStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useSystemStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SystemStatusQuery,
    SystemStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SystemStatusQuery, SystemStatusQueryVariables>(
    SystemStatusDocument,
    options
  );
}
export function useSystemStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SystemStatusQuery,
    SystemStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SystemStatusQuery, SystemStatusQueryVariables>(
    SystemStatusDocument,
    options
  );
}
export type SystemStatusQueryHookResult = ReturnType<
  typeof useSystemStatusQuery
>;
export type SystemStatusLazyQueryHookResult = ReturnType<
  typeof useSystemStatusLazyQuery
>;
export type SystemStatusQueryResult = Apollo.QueryResult<
  SystemStatusQuery,
  SystemStatusQueryVariables
>;
export const ThemeContainerUserDocument = gql`
  query ThemeContainerUser {
    user {
      id
      metadata
      __typename
    }
  }
`;

/**
 * __useThemeContainerUserQuery__
 *
 * To run a query within a React component, call `useThemeContainerUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemeContainerUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThemeContainerUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useThemeContainerUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ThemeContainerUserQuery,
    ThemeContainerUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ThemeContainerUserQuery,
    ThemeContainerUserQueryVariables
  >(ThemeContainerUserDocument, options);
}
export function useThemeContainerUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ThemeContainerUserQuery,
    ThemeContainerUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ThemeContainerUserQuery,
    ThemeContainerUserQueryVariables
  >(ThemeContainerUserDocument, options);
}
export type ThemeContainerUserQueryHookResult = ReturnType<
  typeof useThemeContainerUserQuery
>;
export type ThemeContainerUserLazyQueryHookResult = ReturnType<
  typeof useThemeContainerUserLazyQuery
>;
export type ThemeContainerUserQueryResult = Apollo.QueryResult<
  ThemeContainerUserQuery,
  ThemeContainerUserQueryVariables
>;
export const TradeHistoryDocument = gql`
  query tradeHistory($afterId: ID, $after: Int, $pageSize: Int) {
    tradeHistory(afterId: $afterId, after: $after, pageSize: $pageSize) {
      ...tradeHistory
    }
  }
  ${TradeHistoryFragmentDoc}
`;

/**
 * __useTradeHistoryQuery__
 *
 * To run a query within a React component, call `useTradeHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeHistoryQuery({
 *   variables: {
 *      afterId: // value for 'afterId'
 *      after: // value for 'after'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useTradeHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TradeHistoryQuery,
    TradeHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TradeHistoryQuery, TradeHistoryQueryVariables>(
    TradeHistoryDocument,
    options
  );
}
export function useTradeHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TradeHistoryQuery,
    TradeHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TradeHistoryQuery, TradeHistoryQueryVariables>(
    TradeHistoryDocument,
    options
  );
}
export type TradeHistoryQueryHookResult = ReturnType<
  typeof useTradeHistoryQuery
>;
export type TradeHistoryLazyQueryHookResult = ReturnType<
  typeof useTradeHistoryLazyQuery
>;
export type TradeHistoryQueryResult = Apollo.QueryResult<
  TradeHistoryQuery,
  TradeHistoryQueryVariables
>;
export const UserTradeHistorySubDocument = gql`
  subscription userTradeHistorySub {
    tradeHistorySubscription {
      trades {
        ...tradeHistory
      }
    }
  }
  ${TradeHistoryFragmentDoc}
`;

/**
 * __useUserTradeHistorySubSubscription__
 *
 * To run a query within a React component, call `useUserTradeHistorySubSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserTradeHistorySubSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserTradeHistorySubSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUserTradeHistorySubSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    UserTradeHistorySubSubscription,
    UserTradeHistorySubSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    UserTradeHistorySubSubscription,
    UserTradeHistorySubSubscriptionVariables
  >(UserTradeHistorySubDocument, options);
}
export type UserTradeHistorySubSubscriptionHookResult = ReturnType<
  typeof useUserTradeHistorySubSubscription
>;
export type UserTradeHistorySubSubscriptionResult =
  Apollo.SubscriptionResult<UserTradeHistorySubSubscription>;
export const TradeTapeActivitiesDocument = gql`
  query tradeTapeActivities($dashboardId: ID) {
    tradeTapeActivities(dashboardId: $dashboardId) {
      ...tradeTapeActivity
    }
  }
  ${TradeTapeActivityFragmentDoc}
`;

/**
 * __useTradeTapeActivitiesQuery__
 *
 * To run a query within a React component, call `useTradeTapeActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTradeTapeActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTradeTapeActivitiesQuery({
 *   variables: {
 *      dashboardId: // value for 'dashboardId'
 *   },
 * });
 */
export function useTradeTapeActivitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TradeTapeActivitiesQuery,
    TradeTapeActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TradeTapeActivitiesQuery,
    TradeTapeActivitiesQueryVariables
  >(TradeTapeActivitiesDocument, options);
}
export function useTradeTapeActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TradeTapeActivitiesQuery,
    TradeTapeActivitiesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TradeTapeActivitiesQuery,
    TradeTapeActivitiesQueryVariables
  >(TradeTapeActivitiesDocument, options);
}
export type TradeTapeActivitiesQueryHookResult = ReturnType<
  typeof useTradeTapeActivitiesQuery
>;
export type TradeTapeActivitiesLazyQueryHookResult = ReturnType<
  typeof useTradeTapeActivitiesLazyQuery
>;
export type TradeTapeActivitiesQueryResult = Apollo.QueryResult<
  TradeTapeActivitiesQuery,
  TradeTapeActivitiesQueryVariables
>;
export const UserInfoDocument = gql`
  query UserInfo {
    user {
      name
      firstName
      lastName
      email
      username
      organizationName
    }
  }
`;

/**
 * __useUserInfoQuery__
 *
 * To run a query within a React component, call `useUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<UserInfoQuery, UserInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserInfoQuery, UserInfoQueryVariables>(
    UserInfoDocument,
    options
  );
}
export function useUserInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserInfoQuery,
    UserInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserInfoQuery, UserInfoQueryVariables>(
    UserInfoDocument,
    options
  );
}
export type UserInfoQueryHookResult = ReturnType<typeof useUserInfoQuery>;
export type UserInfoLazyQueryHookResult = ReturnType<
  typeof useUserInfoLazyQuery
>;
export type UserInfoQueryResult = Apollo.QueryResult<
  UserInfoQuery,
  UserInfoQueryVariables
>;
export const MarketsPermissionsDocument = gql`
  query MarketsPermissions {
    desks {
      id
      abilities {
        ...ability
      }
      markets {
        id
        market {
          id
          abilities {
            ...ability
          }
        }
      }
    }
  }
  ${AbilityFragmentDoc}
`;

/**
 * __useMarketsPermissionsQuery__
 *
 * To run a query within a React component, call `useMarketsPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketsPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketsPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketsPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MarketsPermissionsQuery,
    MarketsPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MarketsPermissionsQuery,
    MarketsPermissionsQueryVariables
  >(MarketsPermissionsDocument, options);
}
export function useMarketsPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketsPermissionsQuery,
    MarketsPermissionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MarketsPermissionsQuery,
    MarketsPermissionsQueryVariables
  >(MarketsPermissionsDocument, options);
}
export type MarketsPermissionsQueryHookResult = ReturnType<
  typeof useMarketsPermissionsQuery
>;
export type MarketsPermissionsLazyQueryHookResult = ReturnType<
  typeof useMarketsPermissionsLazyQuery
>;
export type MarketsPermissionsQueryResult = Apollo.QueryResult<
  MarketsPermissionsQuery,
  MarketsPermissionsQueryVariables
>;
export const ActivityStreamDocument = gql`
  query activityStream($afterId: ID, $after: Int, $pageSize: Int) {
    activityStream(afterId: $afterId, after: $after, pageSize: $pageSize) {
      ...activityFragment
    }
  }
  ${ActivityFragmentFragmentDoc}
`;

/**
 * __useActivityStreamQuery__
 *
 * To run a query within a React component, call `useActivityStreamQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityStreamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityStreamQuery({
 *   variables: {
 *      afterId: // value for 'afterId'
 *      after: // value for 'after'
 *      pageSize: // value for 'pageSize'
 *   },
 * });
 */
export function useActivityStreamQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ActivityStreamQuery,
    ActivityStreamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActivityStreamQuery, ActivityStreamQueryVariables>(
    ActivityStreamDocument,
    options
  );
}
export function useActivityStreamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivityStreamQuery,
    ActivityStreamQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActivityStreamQuery, ActivityStreamQueryVariables>(
    ActivityStreamDocument,
    options
  );
}
export type ActivityStreamQueryHookResult = ReturnType<
  typeof useActivityStreamQuery
>;
export type ActivityStreamLazyQueryHookResult = ReturnType<
  typeof useActivityStreamLazyQuery
>;
export type ActivityStreamQueryResult = Apollo.QueryResult<
  ActivityStreamQuery,
  ActivityStreamQueryVariables
>;
export const WebsocketConnectionIdDocument = gql`
  query websocketConnectionId {
    connectionId @client
  }
`;

/**
 * __useWebsocketConnectionIdQuery__
 *
 * To run a query within a React component, call `useWebsocketConnectionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsocketConnectionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsocketConnectionIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useWebsocketConnectionIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WebsocketConnectionIdQuery,
    WebsocketConnectionIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WebsocketConnectionIdQuery,
    WebsocketConnectionIdQueryVariables
  >(WebsocketConnectionIdDocument, options);
}
export function useWebsocketConnectionIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WebsocketConnectionIdQuery,
    WebsocketConnectionIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WebsocketConnectionIdQuery,
    WebsocketConnectionIdQueryVariables
  >(WebsocketConnectionIdDocument, options);
}
export type WebsocketConnectionIdQueryHookResult = ReturnType<
  typeof useWebsocketConnectionIdQuery
>;
export type WebsocketConnectionIdLazyQueryHookResult = ReturnType<
  typeof useWebsocketConnectionIdLazyQuery
>;
export type WebsocketConnectionIdQueryResult = Apollo.QueryResult<
  WebsocketConnectionIdQuery,
  WebsocketConnectionIdQueryVariables
>;
export const HasConnectedDocument = gql`
  query hasConnected {
    hasConnected @client
  }
`;

/**
 * __useHasConnectedQuery__
 *
 * To run a query within a React component, call `useHasConnectedQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasConnectedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasConnectedQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasConnectedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HasConnectedQuery,
    HasConnectedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HasConnectedQuery, HasConnectedQueryVariables>(
    HasConnectedDocument,
    options
  );
}
export function useHasConnectedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HasConnectedQuery,
    HasConnectedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HasConnectedQuery, HasConnectedQueryVariables>(
    HasConnectedDocument,
    options
  );
}
export type HasConnectedQueryHookResult = ReturnType<
  typeof useHasConnectedQuery
>;
export type HasConnectedLazyQueryHookResult = ReturnType<
  typeof useHasConnectedLazyQuery
>;
export type HasConnectedQueryResult = Apollo.QueryResult<
  HasConnectedQuery,
  HasConnectedQueryVariables
>;
export const ConnectionStatusDocument = gql`
  query connectionStatus {
    connectionStatus @client
  }
`;

/**
 * __useConnectionStatusQuery__
 *
 * To run a query within a React component, call `useConnectionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useConnectionStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ConnectionStatusQuery,
    ConnectionStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ConnectionStatusQuery, ConnectionStatusQueryVariables>(
    ConnectionStatusDocument,
    options
  );
}
export function useConnectionStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ConnectionStatusQuery,
    ConnectionStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ConnectionStatusQuery,
    ConnectionStatusQueryVariables
  >(ConnectionStatusDocument, options);
}
export type ConnectionStatusQueryHookResult = ReturnType<
  typeof useConnectionStatusQuery
>;
export type ConnectionStatusLazyQueryHookResult = ReturnType<
  typeof useConnectionStatusLazyQuery
>;
export type ConnectionStatusQueryResult = Apollo.QueryResult<
  ConnectionStatusQuery,
  ConnectionStatusQueryVariables
>;
export const LoggedInDocument = gql`
  query loggedIn {
    loggedIn @client
  }
`;

/**
 * __useLoggedInQuery__
 *
 * To run a query within a React component, call `useLoggedInQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInQuery(
  baseOptions?: Apollo.QueryHookOptions<LoggedInQuery, LoggedInQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoggedInQuery, LoggedInQueryVariables>(
    LoggedInDocument,
    options
  );
}
export function useLoggedInLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoggedInQuery,
    LoggedInQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LoggedInQuery, LoggedInQueryVariables>(
    LoggedInDocument,
    options
  );
}
export type LoggedInQueryHookResult = ReturnType<typeof useLoggedInQuery>;
export type LoggedInLazyQueryHookResult = ReturnType<
  typeof useLoggedInLazyQuery
>;
export type LoggedInQueryResult = Apollo.QueryResult<
  LoggedInQuery,
  LoggedInQueryVariables
>;
export const PermissionsHashDocument = gql`
  query permissionsHash {
    permissionsHash @client
  }
`;

/**
 * __usePermissionsHashQuery__
 *
 * To run a query within a React component, call `usePermissionsHashQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsHashQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionsHashQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PermissionsHashQuery,
    PermissionsHashQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PermissionsHashQuery, PermissionsHashQueryVariables>(
    PermissionsHashDocument,
    options
  );
}
export function usePermissionsHashLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PermissionsHashQuery,
    PermissionsHashQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PermissionsHashQuery,
    PermissionsHashQueryVariables
  >(PermissionsHashDocument, options);
}
export type PermissionsHashQueryHookResult = ReturnType<
  typeof usePermissionsHashQuery
>;
export type PermissionsHashLazyQueryHookResult = ReturnType<
  typeof usePermissionsHashLazyQuery
>;
export type PermissionsHashQueryResult = Apollo.QueryResult<
  PermissionsHashQuery,
  PermissionsHashQueryVariables
>;
export const ReloadingCountDocument = gql`
  query reloadingCount {
    reloadingCount @client
  }
`;

/**
 * __useReloadingCountQuery__
 *
 * To run a query within a React component, call `useReloadingCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useReloadingCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReloadingCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useReloadingCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReloadingCountQuery,
    ReloadingCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReloadingCountQuery, ReloadingCountQueryVariables>(
    ReloadingCountDocument,
    options
  );
}
export function useReloadingCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReloadingCountQuery,
    ReloadingCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReloadingCountQuery, ReloadingCountQueryVariables>(
    ReloadingCountDocument,
    options
  );
}
export type ReloadingCountQueryHookResult = ReturnType<
  typeof useReloadingCountQuery
>;
export type ReloadingCountLazyQueryHookResult = ReturnType<
  typeof useReloadingCountLazyQuery
>;
export type ReloadingCountQueryResult = Apollo.QueryResult<
  ReloadingCountQuery,
  ReloadingCountQueryVariables
>;
export const BlastDeskDocument = gql`
  query blastDesk {
    brokerDesks {
      id
    }
  }
`;

/**
 * __useBlastDeskQuery__
 *
 * To run a query within a React component, call `useBlastDeskQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlastDeskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlastDeskQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlastDeskQuery(
  baseOptions?: Apollo.QueryHookOptions<BlastDeskQuery, BlastDeskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BlastDeskQuery, BlastDeskQueryVariables>(
    BlastDeskDocument,
    options
  );
}
export function useBlastDeskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BlastDeskQuery,
    BlastDeskQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BlastDeskQuery, BlastDeskQueryVariables>(
    BlastDeskDocument,
    options
  );
}
export type BlastDeskQueryHookResult = ReturnType<typeof useBlastDeskQuery>;
export type BlastDeskLazyQueryHookResult = ReturnType<
  typeof useBlastDeskLazyQuery
>;
export type BlastDeskQueryResult = Apollo.QueryResult<
  BlastDeskQuery,
  BlastDeskQueryVariables
>;
export const HomepageMarketsDocument = gql`
  query HomepageMarkets {
    brokerSite {
      id
      products {
        id
        market {
          decimalPlaces
        }
      }
      ticker {
        name
        products {
          id
          name
          price
          prevClose
        }
      }
    }
  }
`;

/**
 * __useHomepageMarketsQuery__
 *
 * To run a query within a React component, call `useHomepageMarketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomepageMarketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomepageMarketsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomepageMarketsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    HomepageMarketsQuery,
    HomepageMarketsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HomepageMarketsQuery, HomepageMarketsQueryVariables>(
    HomepageMarketsDocument,
    options
  );
}
export function useHomepageMarketsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    HomepageMarketsQuery,
    HomepageMarketsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    HomepageMarketsQuery,
    HomepageMarketsQueryVariables
  >(HomepageMarketsDocument, options);
}
export type HomepageMarketsQueryHookResult = ReturnType<
  typeof useHomepageMarketsQuery
>;
export type HomepageMarketsLazyQueryHookResult = ReturnType<
  typeof useHomepageMarketsLazyQuery
>;
export type HomepageMarketsQueryResult = Apollo.QueryResult<
  HomepageMarketsQuery,
  HomepageMarketsQueryVariables
>;
export const LoginDocument = gql`
  mutation login($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
      refreshToken
      user {
        id
        username
        firstName
        abilities {
          ...ability
        }
      }
    }
  }
  ${AbilityFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const RequestPasswordResetDocument = gql`
  mutation requestPasswordReset($origin: String!, $username: String!) {
    requestResetPassword(origin: $origin, username: $username) {
      ok
    }
  }
`;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      origin: // value for 'origin'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
  >(RequestPasswordResetDocument, options);
}
export type RequestPasswordResetMutationHookResult = ReturnType<
  typeof useRequestPasswordResetMutation
>;
export type RequestPasswordResetMutationResult =
  Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;
export const AuthStrategyDocument = gql`
  query authStrategy {
    brokerSite {
      ssoLink
    }
  }
`;

/**
 * __useAuthStrategyQuery__
 *
 * To run a query within a React component, call `useAuthStrategyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthStrategyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthStrategyQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthStrategyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AuthStrategyQuery,
    AuthStrategyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuthStrategyQuery, AuthStrategyQueryVariables>(
    AuthStrategyDocument,
    options
  );
}
export function useAuthStrategyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthStrategyQuery,
    AuthStrategyQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuthStrategyQuery, AuthStrategyQueryVariables>(
    AuthStrategyDocument,
    options
  );
}
export type AuthStrategyQueryHookResult = ReturnType<
  typeof useAuthStrategyQuery
>;
export type AuthStrategyLazyQueryHookResult = ReturnType<
  typeof useAuthStrategyLazyQuery
>;
export type AuthStrategyQueryResult = Apollo.QueryResult<
  AuthStrategyQuery,
  AuthStrategyQueryVariables
>;
export const SsoEnabledDocument = gql`
  query ssoEnabled($email: String!) {
    ssoEnabled(email: $email) {
      ssoEnabled
      ssoLink
    }
  }
`;

/**
 * __useSsoEnabledQuery__
 *
 * To run a query within a React component, call `useSsoEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useSsoEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSsoEnabledQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSsoEnabledQuery(
  baseOptions: Apollo.QueryHookOptions<
    SsoEnabledQuery,
    SsoEnabledQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SsoEnabledQuery, SsoEnabledQueryVariables>(
    SsoEnabledDocument,
    options
  );
}
export function useSsoEnabledLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SsoEnabledQuery,
    SsoEnabledQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SsoEnabledQuery, SsoEnabledQueryVariables>(
    SsoEnabledDocument,
    options
  );
}
export type SsoEnabledQueryHookResult = ReturnType<typeof useSsoEnabledQuery>;
export type SsoEnabledLazyQueryHookResult = ReturnType<
  typeof useSsoEnabledLazyQuery
>;
export type SsoEnabledQueryResult = Apollo.QueryResult<
  SsoEnabledQuery,
  SsoEnabledQueryVariables
>;
export const ProductsViewProductGroupsDocument = gql`
  query ProductsViewProductGroups {
    productGroups {
      ...productGroup
    }
  }
  ${ProductGroupFragmentDoc}
`;

/**
 * __useProductsViewProductGroupsQuery__
 *
 * To run a query within a React component, call `useProductsViewProductGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsViewProductGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsViewProductGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsViewProductGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProductsViewProductGroupsQuery,
    ProductsViewProductGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ProductsViewProductGroupsQuery,
    ProductsViewProductGroupsQueryVariables
  >(ProductsViewProductGroupsDocument, options);
}
export function useProductsViewProductGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductsViewProductGroupsQuery,
    ProductsViewProductGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ProductsViewProductGroupsQuery,
    ProductsViewProductGroupsQueryVariables
  >(ProductsViewProductGroupsDocument, options);
}
export type ProductsViewProductGroupsQueryHookResult = ReturnType<
  typeof useProductsViewProductGroupsQuery
>;
export type ProductsViewProductGroupsLazyQueryHookResult = ReturnType<
  typeof useProductsViewProductGroupsLazyQuery
>;
export type ProductsViewProductGroupsQueryResult = Apollo.QueryResult<
  ProductsViewProductGroupsQuery,
  ProductsViewProductGroupsQueryVariables
>;
export const ProductIndexesDocument = gql`
  query ProductIndexes {
    productGroups {
      id
      indices {
        ...index
      }
    }
  }
  ${IndexFragmentDoc}
`;

/**
 * __useProductIndexesQuery__
 *
 * To run a query within a React component, call `useProductIndexesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductIndexesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductIndexesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductIndexesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProductIndexesQuery,
    ProductIndexesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProductIndexesQuery, ProductIndexesQueryVariables>(
    ProductIndexesDocument,
    options
  );
}
export function useProductIndexesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProductIndexesQuery,
    ProductIndexesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProductIndexesQuery, ProductIndexesQueryVariables>(
    ProductIndexesDocument,
    options
  );
}
export type ProductIndexesQueryHookResult = ReturnType<
  typeof useProductIndexesQuery
>;
export type ProductIndexesLazyQueryHookResult = ReturnType<
  typeof useProductIndexesLazyQuery
>;
export type ProductIndexesQueryResult = Apollo.QueryResult<
  ProductIndexesQuery,
  ProductIndexesQueryVariables
>;
export const AllProductActivityDocument = gql`
  subscription AllProductActivity {
    productActivitySubscription {
      activities {
        ...productActivity
      }
      pgmActivities {
        ...productGroupMemberActivity
      }
    }
  }
  ${ProductActivityFragmentDoc}
  ${ProductGroupMemberActivityFragmentDoc}
`;

/**
 * __useAllProductActivitySubscription__
 *
 * To run a query within a React component, call `useAllProductActivitySubscription` and pass it any options that fit your needs.
 * When your component renders, `useAllProductActivitySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProductActivitySubscription({
 *   variables: {
 *   },
 * });
 */
export function useAllProductActivitySubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    AllProductActivitySubscription,
    AllProductActivitySubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    AllProductActivitySubscription,
    AllProductActivitySubscriptionVariables
  >(AllProductActivityDocument, options);
}
export type AllProductActivitySubscriptionHookResult = ReturnType<
  typeof useAllProductActivitySubscription
>;
export type AllProductActivitySubscriptionResult =
  Apollo.SubscriptionResult<AllProductActivitySubscription>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($token: String!, $password: String!) {
    submitResetPassword(token: $token, password: $password) {
      ok
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
