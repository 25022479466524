import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from '@material-ui/core';
import { useState } from 'react';
import PolicyButton from '../Policy/PolicyButton/PolicyButton';
import CookiePolicy from '../../themes/base/assets/docs/cookiepolicy.pdf';

const CookieStatus = () => {
  const [alertVisible, setVisible] = useState(
    localStorage.getItem('cookieConsent') !== 'true'
  );

  function dismiss() {
    setVisible(false);
    localStorage.setItem('cookieConsent', 'true');
  }

  const theme = useTheme();

  return (
    <Dialog open={alertVisible} disableBackdropClick={true}>
      <DialogTitle>Cookie Consent</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This site uses cookies. By continuing you agree to the use of these
          cookies as set out in our{' '}
          <PolicyButton
            title={'cookie policy'}
            url={CookiePolicy}
            style={{ fontSize: '1em', color: theme.palette.secondary.main }}
          />
          .
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => dismiss()}
          variant="contained"
          color="primary"
          id={'consent-to-cookies'}
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CookieStatus;
