import { PureAbility, RawRule } from '@casl/ability';
import { permissionsHashVar } from '../client/client';
import {
  isArray,
  isArrayOf,
  isBoolean,
  isNullish,
  isNullishArrayOf,
  isString,
} from '../util/TypeHelpers';
import { packRules } from '@casl/ability/extra';

// TODO get these included after update (on token) by default

// All users receive these rules regardless of authenticated state
export const defaultRules: RawRule<[string, string]>[] = [
  {
    action: 'read',
    subject: 'public',
  },
  {
    action: 'read',
    subject: 'login',
  },
];

// Users with an authenticated state receive this rule (required to fetch server stored rules)
export const authedDefaultRules: RawRule<[string, string]>[] = [
  {
    action: 'read',
    subject: 'userInfo',
  },
  {
    action: 'read',
    subject: 'private',
  },
];

// This is a helper to keep track of all rules injested from objects
// This is important because it enables the overwriting of a set of rules based on a typename id pair
type ObjectRulesInForce = { [__typename: string]: { [id: string]: RawRule[] } };
let objectRulesInForce: ObjectRulesInForce = {};

// This is a helper to keep track of all rules added by the client
// This is important because it enables us to track any authed rules alongside the default rules
let clientRulesInForce: RawRule[] = [...defaultRules];

// Master Ability Set
// Made up of default rules and any additional rules the user may have
// This is the single source of the truth for what a user can and cant do
const ability = new PureAbility(defaultRules, {
  detectSubjectType: subjectNameResolver,
  conditionsMatcher: (t: any) => (o) => o?.id && o.id === t.id,
});

// CASL Subject Name Function Override
// Accepts string - useful for checking non object based perms 'client', 'broker', 'public', 'private' etc
// Accepts object with a GQL typename (optional although we mandate typename fetch at client init)
// If no subject is determined return 'INVALID_SUBJECT'
export function subjectNameResolver(subject: string | { __typename?: string }) {
  if (typeof subject === 'string') {
    return subject;
  }
  return subject.__typename || 'INVALID_SUBJECT';
}

const mergeRulesToAbility = () => {
  const prevAbilityHash = hashCode(JSON.stringify(packRules(ability.rules)));
  ability.update([
    ...clientRulesInForce,
    ...flattenObjectRules(objectRulesInForce).map((r: any) => {
      return {
        ...r,
        action: isString(r.action)
          ? r.action
          : isString(r.actions)
          ? r.actions
          : isArrayOf(isString, r.actions) && r.actions.length
          ? r.actions[0]
          : '',
      };
    }),
  ]);
  const newAbilityHash = hashCode(JSON.stringify(packRules(ability.rules)));
  // Broadcast the change to Apollo if the abilities change

  if (prevAbilityHash !== newAbilityHash) {
    permissionsHashVar(newAbilityHash);
  }
};

export const hashCode = (string: string) => {
  var hash = 0;
  if (string.length === 0) {
    return hash;
  }
  for (var i = 0; i < string.length; i++) {
    var char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

// Call this on successful login to give the user permission to fetch all other perms
export const mergeAuthedRules = () => {
  clientRulesInForce = [...clientRulesInForce, ...authedDefaultRules];
  mergeRulesToAbility();
};

// Call this on logout to reset a users permissions back to the default
export const resetAbility = () => {
  clientRulesInForce = defaultRules;
  objectRulesInForce = {};
  mergeRulesToAbility();
};

// Called by Apollo Link to intercept all data objects and extract any valid rules it might have
// Objects may have other objects that are also with rules
// Recursively pulls out all of these from all levels
export const extractObjectRules = (incoming: unknown): void => {
  // Merge to object rule tracker
  mergeObjectRulesToInForce(objectRulesInForce, incoming);
  // Set rules on ability
  mergeRulesToAbility();
};

// Merges any valid rules found on the candidate recursively to any depth
// Candidate may have properties that are objects with rules or an array of objects that could have rules....etc
const mergeObjectRulesToInForce = (
  currentRulesInForce: ObjectRulesInForce,
  candidate: unknown
): void => {
  //
  if (isObject(candidate)) {
    if (isObjectWithPossibleRules(candidate)) {
      overwriteRules(currentRulesInForce, candidate);
    }
    Object.keys(candidate)
      .filter((key) => key !== 'abilities')
      .forEach((key) =>
        mergeObjectRulesToInForce(currentRulesInForce, candidate[key])
      );
  } else if (isArray<any>(candidate)) {
    (candidate as unknown[]).forEach((candidateItem) =>
      mergeObjectRulesToInForce(currentRulesInForce, candidateItem)
    );
  }
};

// Handles the overwriting of an object's rules based on its typename id key pair
export const overwriteRules = (
  currentRulesInForce: ObjectRulesInForce,
  candidate: ObjectWithPossibleRules
) => {
  currentRulesInForce[candidate.__typename] =
    currentRulesInForce[candidate.__typename] || {};
  currentRulesInForce[candidate.__typename][candidate.id] =
    candidate.abilities.filter(
      (possibleRule: unknown): possibleRule is RawRule => {
        const ruleOk = isRule(possibleRule);
        return ruleOk;
      }
    );
};

// Flattens Object Rules to a single array
export const flattenObjectRules = (rules: ObjectRulesInForce) => {
  return Object.keys(rules).reduce(
    (rulesAccum: RawRule[], typename: string) => {
      return rulesAccum.concat(
        Object.keys(rules[typename]).reduce(
          (typeRulesAccum: RawRule[], id: string) => {
            return typeRulesAccum.concat(rules[typename][id]);
          },
          []
        )
      );
    },
    []
  );
};

export default ability;

type ObjectWithPossibleRules = {
  __typename: string;
  id: string;
  abilities: unknown[];
};

// TypeHelpers

// Typeguard enforcing minimum reqs for object rule ingestion
function isObjectWithPossibleRules(o: any): o is ObjectWithPossibleRules {
  return isString(o.id) && isString(o.__typename) && isArray(o.abilities);
}

// Keep this local as its a bit of a hacky implementation!!
const isObject = function (o: any): o is { [key: string]: any } {
  return o === Object(o) && !Array.isArray(o) && typeof o !== 'function';
};

// Typeguard covering CASL RawRule type
function isRule(r: any): r is RawRule {
  return (
    (isString(r.subject) || isArrayOf(isString, r.subject)) &&
    (isString(r.actions) ||
      isArrayOf(isString, r.actions) ||
      isString(r.action)) &&
    isNullishArrayOf(isString, r.fields) &&
    isNullish(isObject, r.conditions) &&
    isNullish(isBoolean, r.inverted) &&
    isNullish(isString, r.reason)
  );
}
