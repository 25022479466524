import { FC } from 'react';
import { useLoggedInQuery } from '../../schema/types';
import MarketsFetcher from './MarketsFetcher';

const MarketsContainer: FC = ({ children }) => {
  const { data } = useLoggedInQuery();

  return !data?.loggedIn ? (
    <>{children}</>
  ) : (
    <MarketsFetcher>{children}</MarketsFetcher>
  );
};

export default MarketsContainer;
