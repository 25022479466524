import { FC } from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useTheme } from '@material-ui/core';
import { isFullScreen } from '../../util/isFullscreen';

const LoginButton: FC = () => {
  const theme = useTheme();
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <Button
        style={{
          font: 'inherit',
          height: '50px',
          background: theme.palette.inputBackground.main,
          color: theme.palette.text.primary,
          borderTop: theme.palette.inputBorder,
          padding: isFullScreen
            ? '15px 15px calc(max(env(safe-area-inset-bottom, 15px), 15px)) 15px'
            : '15px',
        }}
        component={Link}
        to={'/login'}
        variant="contained"
        fullWidth
      >
        Log In
      </Button>
    </div>
  );
};

export default LoginButton;
