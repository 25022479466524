import { setContext } from '@apollo/client/link/context';
import { getToken } from '../client';

export const authLink = setContext((request, { headers }) => {
  const token = getToken();
  if (request.operationName !== 'AuthRefreshToken' && token) {
    // don't send (expired) auth token during refresh operations
    // as backend may attempt to validate anyway and throw error
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  } else {
    return headers;
  }
});
