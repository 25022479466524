import { FC, useContext, createContext } from 'react';
import { SnackbarProvider, OptionsObject, useSnackbar } from 'notistack';
import { makeStyles, Theme } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '0px',
  },
  success: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1),
  },
  error: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.error.main}`,
    padding: theme.spacing(1),
  },
  icon: {
    padding: '0px',
    marginRight: '6px',
  },
}));

export enum NotificationType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export enum Snackbar {
  SUCCESS = 'success',
  ERROR = 'error',
}

interface NotificationSetting {
  snackbar: Snackbar;
}

type NotificationSettings = { [key in NotificationType]: NotificationSetting };

const defaultNotificationSettings: NotificationSettings = {
  [NotificationType.ERROR]: {
    snackbar: Snackbar.ERROR,
  },

  [NotificationType.SUCCESS]: {
    snackbar: Snackbar.SUCCESS,
  },
};

export const NotificationContainer: FC = ({ children }) => {
  const classes = useStyles();
  return (
    <SnackbarProvider
      iconVariant={{
        success: <CheckCircleIcon color="primary" className={classes.icon} />,
        error: <ErrorIcon color="error" className={classes.icon} />,
      }}
      classes={{
        root: classes.root,
        variantSuccess: classes.success,
        variantError: classes.error,
      }}
      dense
      maxSnack={1}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <NotificationProvider>{children}</NotificationProvider>
    </SnackbarProvider>
  );
};

interface INotificationContext {
  fireNotification: (
    messageType: NotificationType,
    message?: string,
    options?: OptionsObject
  ) => void;
}

export const NotificationContext = createContext<INotificationContext>({
  fireNotification: (
    messageType: NotificationType,
    message?: string,
    options?: OptionsObject
  ) => false,
});

export const NotificationProvider: FC = ({ children }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const fireNotification = (
    messageType: NotificationType,
    message?: string,
    options?: OptionsObject
  ) => {
    const notificationSettings = defaultNotificationSettings[messageType];
    return enqueueSnackbar(message || '', {
      ...options,
      variant: notificationSettings.snackbar,
      onClick: () => closeSnackbar(),
    });
  };

  const notificationContextProviderValues = {
    fireNotification,
  };

  return (
    <NotificationContext.Provider value={notificationContextProviderValues}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const { fireNotification } = useContext(NotificationContext);
  return {
    fireNotification,
    NotificationTypes: NotificationType,
  };
};
