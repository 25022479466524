import { ThemeOptions } from '@material-ui/core';
import logo from './assets/img/logo.svg';
import favicon from './assets/img/favicon.ico';
import appleIcon from './assets/img/icon-256x256.png';
import manifest from './manifest.webmanifest';

export const overrides: ThemeOptions = {
  palette: {
    type: 'dark',
    primary: {
      main: '#0154bd',
    },
  },
  meta: {
    propertyName: 'Holborn Trading',
    propertyOwner: 'Holborn Trading',
    propertyDesc: 'TBC',
    logo,
    favicon,
    manifest,
    appleIcon,
    policies: [],
    loginTerms: 'NULL',
  },
};

export default overrides;
