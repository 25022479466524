import { FC } from 'react';
import { useConnectionStatusQuery, useLoggedInQuery } from '../../schema/types';
import { useLocation } from 'react-router-dom';
import LoginButton from '../LoginButton/LoginButton';
import BottomNavBar from '../Navigation/BottomNavBar';
import AddAppPrompt from '../AddAppPrompt/AddAppPrompt';
import HomeButton from '../HomeButton/HomeButton';

const ViewContainer: FC = (props: any) => {
  const { data: loginStatus } = useLoggedInQuery();
  const loggedIn = loginStatus?.loggedIn;

  const { data: connectionStatus } = useConnectionStatusQuery();
  const isConnected =
    (connectionStatus?.connectionStatus || 0) < 2 ? false : true;

  const location = useLocation();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          flex: 1,
          overflow: 'hidden',
        }}
      >
        {props.children}
      </div>
      <AddAppPrompt />
      <div data-testid="bottom-container">
        {loggedIn ? (
          <BottomNavBar />
        ) : location.pathname !== '/login' && isConnected ? (
          <LoginButton />
        ) : (
          <HomeButton />
        )}
      </div>
    </div>
  );
};

export default ViewContainer;
