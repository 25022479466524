import { ThemeOptions } from '@material-ui/core';
import logo from './assets/img/logo.svg';
import favicon from './assets/img/favicon.ico';
import appleIcon from './assets/img/icon-192x192.png';
import manifest from './manifest.webmanifest';

const base: ThemeOptions = {
  typography: {
    fontFamily: ['Play', 'Roboto', 'sans-serif'].join(','),
  },
  palette: {
    type: 'dark',
    primary: {
      main: 'rgb(36,140,139)',
    },
    info: {
      main: 'rgba(255,255,255,0.4)',
    },
    price: {
      tickUp: '#4caf50', // Default Green
      tickDown: '#f44336', // Default Red
      indicative: '#FFFFFF',
      derived: '#888888',
      order: '#186260',
    },
    inputBackground: {
      main: 'rgba(255,255,255,0.1)',
    },
    inputBorder: 'solid 1px rgba(255,255,255,0.1)',
    background: {
      paper: 'rgba(25,25,25,1.0)',
      default: 'rgba(28,28,28,1.0)',
    },
  },
  overrides: {
    //@ts-ignore
    MuiTreeItem: {
      root: {
        '&:hover > .MuiTreeItem-content .MuiTreeItem-label': {
          backgroundColor: 'rgb(255,255,255,0.2)',
        },
        '&:hover > .MuiTreeItem-content .MuiTreeItem-icon': {
          backgroundColor: 'rgb(255,255,255,0.2)',
        },
        '&:focus > .MuiTreeItem-content .MuiTreeItem-icon': {
          backgroundColor: 'unset',
        },
        '&:focus > .MuiTreeItem-content .MuiTreeItem-label': {
          backgroundColor: 'unset',
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '40px',
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: '18px',
      },
    },
  },
  meta: {
    propertyName: 'Venetian Platform',
    propertyOwner: 'Zuma Labs',
    propertyDesc: 'Venetian is the bees knees, yo!',
    logo,
    favicon,
    appleIcon,
    manifest,
    homepageBackground:
      'radial-gradient(circle, rgba(70,70,70,1) 0%, rgba(25,25,25,1) 75%, rgba(25,25,25,1) 100%)',
    twitterId: 'zuma_labs',
    policies: [],
    loginTerms: 'NULL',
    contact: {
      support: { email: 'support@zumaltd.com' },
    },
  },
};

export default base;
