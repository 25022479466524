import {
  ApolloError,
  makeVar,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
} from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useWebsocketConnectionIdQuery } from '../../schema/types';

export const reloadingCountVar = makeVar(0);

const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

const refetchQuery = async <TData, TVariables>(
  wrappedQuery: QueryResult<TData, TVariables>,
  onRefetch: Function
) => {
  let errorMessage = '';
  const depths = [1, 2, 3, 4, 5, 6];
  for (let depth of depths) {
    try {
      await wait(2 ** depth * 100);
      const response = await wrappedQuery.refetch();
      return onRefetch(response);
    } catch (e: unknown) {
      errorMessage =
        e instanceof Error
          ? e.message
          : 'Something went wrong. Failed to refresh data on reconnect. Refresh page to reload';
    }
  }
  throw new Error(errorMessage);
};

export const useLiveQueryWrapper = <
  TData = any,
  TVariables = OperationVariables
>(
  queryHook: (
    o: QueryHookOptions<TData, TVariables>
  ) => QueryResult<TData, TVariables>,
  options: QueryHookOptions<TData, TVariables> = {},
  onRefetch = (_data: TData) => {}
) => {
  const connectionIdQuery = useWebsocketConnectionIdQuery();
  const connectionId = connectionIdQuery.data?.connectionId;
  const [isErrored, setIsErrored] = useState<ApolloError | undefined>();

  const isInitialMount = useRef(true);

  const wrappedQuery = queryHook({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    ...options,
  });
  // We pass in the connectionId as dependency to useEffect which does a
  // compare on each render to decide whether or not to invoke the refetch
  useEffect(() => {
    if (isInitialMount.current === true) {
      isInitialMount.current = false;
    } else {
      reloadingCountVar(reloadingCountVar() + 1);
      refetchQuery<TData, TVariables>(wrappedQuery, (data: TData) => {
        reloadingCountVar(reloadingCountVar() - 1);
        setIsErrored(undefined);
        onRefetch(data);
      }).catch((e: Error) => {
        setIsErrored(
          new ApolloError({
            errorMessage: `${e.message} Refresh page to try again`,
          })
        );
        reloadingCountVar(reloadingCountVar() - 1);
      });
    }
  }, [connectionId]);

  return {
    ...wrappedQuery,
    error: isErrored !== undefined ? isErrored : wrappedQuery.error,
  };
};

export default useLiveQueryWrapper;
