import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ThemeContainer from './components/ThemeContainer/ThemeContainer';
import Head from './Head';
import { ApolloProvider } from '@apollo/client';
import { getClient } from './client/client';
import * as serviceWorker from './serviceWorkerRegistration';

const render = (Component: () => JSX.Element) => {
  return ReactDOM.render(
    <React.StrictMode>
      <ApolloProvider client={getClient()}>
        <ThemeContainer>
          {/* HEAD */}
          <Head />
          {/* BODY */}
          <Component />
        </ThemeContainer>
      </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

render(App);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
const swConfig = {};
serviceWorker.register(swConfig);
