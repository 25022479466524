import { ThemeOptions } from '@material-ui/core';
import logo from './assets/img/logo.svg';
import favicon from './assets/img/favicon.ico';
import appleIcon from './assets/img/icon-192x192.png';
import manifest from './manifest.webmanifest';

export const overrides: ThemeOptions = {
  meta: {
    propertyName: 'Venetian Platform',
    propertyOwner: 'Zuma Labs',
    propertyDesc: 'Venetian is the bees knees, yo!',
    logo,
    favicon,
    manifest,
    appleIcon,
    googAnal: 'UA-206620876-1', // SIMON'S TESTING ACC TRACKING ID
    twitterId: 'zuma_labs',
    policies: [
      {
        title: 'Zuma Test Policy',
        source: 'https://www.braemarscreen.com/static/terms/TOS.pdf',
      },
      {
        title: 'Zuma Test Policy',
        source: 'https://www.braemarscreen.com/static/terms/TOqqS.pdf',
      },
      {
        title: 'Zuma Test Policy',
        source: 'https://www.braemarscreen.com/static/terms/TOqqS.pdf',
      },
    ],
    loginTerms: 'NULL',
  },
};

export default overrides;
