import {
  ApolloCache,
  ApolloClient,
  from,
  gql,
  InMemoryCache,
  makeVar,
  NormalizedCacheObject,
} from '@apollo/client';
import { abilityLink } from './links/ability';
import { errorLink } from './links/error';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { transportLink } from './links/transport';
import { authLink } from './links/authLink';
import PossibleTypes from './possibleTypes.json';
import { arrayMergeIncoming } from '../cache/util';
import { ActivityStreamType, TradeHistoryType } from '../schema/types';
import { reloadingCountVar } from '../hooks/useLiveQueryWrapper/useLiveQueryWrapper';

export const getToken = () => localStorage.getItem('token');
export const getRefreshToken = () => localStorage.getItem('refreshToken');

export const getClient = () => {
  return client ? client : initClient();
};

export const getCache = (): ApolloCache<NormalizedCacheObject> => {
  return client ? client.cache : initClient().cache;
};

const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    hasConnected: Boolean!
    connectionId: Int!
    permissionsHash: Int!
    systemStatus: String!
    systemMessage: String!
  }
`;

export enum SystemStatusType {
  Operational = 'operational',
  Maintenance = 'maintenance',
  Degraded = 'degraded',
}

export const loggedInVar = makeVar<boolean>(false);
export const hasConnectedVar = makeVar<boolean>(false);
export const connectionStatusVar = makeVar<number>(0);
export const connectionIdVar = makeVar<number>(0);
export const permissionsHashVar = makeVar<any>(0);
export const tokenVar = makeVar<string | null>(getToken());
export const refreshTokenVar = makeVar<string | null>(getRefreshToken());
export const systemStatusVar = makeVar<SystemStatusType>(
  SystemStatusType.Operational
);
export const systemMessageVar = makeVar<string>('');

const initClient = () => {
  const client = new ApolloClient<NormalizedCacheObject>({
    cache: initCache(),
    uri: '',
    link: from([
      abilityLink,
      errorLink,
      authLink,
      new ApolloLinkTimeout(10000),
      transportLink,
    ]),
    typeDefs,
  });

  client.onResetStore(async () => {
    hasConnectedVar(true);
    loggedInVar(false);
    connectionStatusVar(connectionStatusVar());
    connectionIdVar(connectionIdVar());
    permissionsHashVar(permissionsHashVar());
    systemStatusVar(systemStatusVar());
    systemMessageVar(systemMessageVar());
    reloadingCountVar(0);
  });

  return client;
};

const initCache = (): InMemoryCache => {
  const cache = new InMemoryCache({
    possibleTypes: PossibleTypes,
    typePolicies: {
      // UserType: {
      //   merge(existing: UserType, incoming: Partial<UserType>) {
      //     return mergeDeep(existing, incoming);
      //   }
      // },
      Query: {
        fields: {
          tradeHistory: {
            keyArgs: false,
            merge(
              existing: TradeHistoryType[] = [],
              incoming: TradeHistoryType[]
            ) {
              return arrayMergeIncoming(existing, incoming);
            },
          },
          activityStream: {
            keyArgs: [],
            merge(
              existing: ActivityStreamType[] = [],
              incoming: ActivityStreamType[]
            ) {
              return arrayMergeIncoming(existing, incoming);
            },
          },
          hasConnected: {
            read() {
              return hasConnectedVar();
            },
          },
          loggedIn: {
            read() {
              return loggedInVar();
            },
          },
          connectionId: {
            read() {
              return connectionIdVar();
            },
          },
          connectionStatus: {
            read() {
              return connectionStatusVar();
            },
          },
          permissionsHash: {
            read() {
              return permissionsHashVar();
            },
          },
          token: {
            read() {
              return tokenVar();
            },
          },
          refreshToken: {
            read() {
              return refreshTokenVar();
            },
          },
          systemStatus: {
            read() {
              return systemStatusVar();
            },
          },
          systemMessage: {
            read() {
              return systemMessageVar();
            },
          },
          reloadingCount: {
            read() {
              return reloadingCountVar();
            },
          },
        },
      },
    },
  });
  hasConnectedVar(false);
  loggedInVar(false);
  connectionStatusVar(0);
  connectionIdVar(0);
  permissionsHashVar(0);
  tokenVar(getToken());
  refreshTokenVar(getRefreshToken());
  systemStatusVar(systemStatusVar());
  systemMessageVar(systemMessageVar());
  reloadingCountVar(reloadingCountVar());
  return cache;
};

const client: ApolloClient<NormalizedCacheObject> | undefined = initClient();
