import { FC, memo, PropsWithChildren } from 'react';
import InfoMessage from '../../components/InfoMessage';
import Loading from '../../components/Loading';
import { useMarketsPermissionsQuery } from '../../schema/types';

const MarketsFetcher: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { data, loading } = useMarketsPermissionsQuery({ pollInterval: 60000 });

  if (loading || !data?.desks) {
    <InfoMessage
      icon={<Loading width={36} height={36} />}
      title={'Loading Market Data'}
      description={''}
    />;
  }

  return <>{children}</>;
};

export default memo(MarketsFetcher);
