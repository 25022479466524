import ReactGA from 'react-ga';

const Event = (category: string, action: string, label: string) => {
  // check if ReactGA has been initalised before firing event
  if (ReactGA.ga()) {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  }
};

export default Event;
