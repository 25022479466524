import { hot } from 'react-hot-loader/root';
import './App.css';
import SystemStatus from './components/SystemStatus/SystemStatus';
import CookieStatus from './components/CookieStatus/CookieStatus';
import AppContainer from './components/AppContainer/AppContainer';
import GoogleAnalytics from './components/GoogleAnalytics/GoogleAnalytics';
import { getHistory } from './history';
import PermissionsContainer from './components/PermissionsContainer/PermissionsContainer';
import ViewProvider from './components/ViewProvider/ViewProvider';
import ViewContainer from './components/ViewContainer/ViewContainer';
import { NotificationContainer } from './components/NotificationContainer/NotificationContainer';
import HistoryRouter from './components/Router/HistoryRouter';
import ConnectionContainer from './components/ConnectionStatus/ConnectionStatus';
import MarketsContainer from './containers/Markets/MarketsContainer';

const App = () => {
  return (
    <>
      <ConnectionContainer>
        <SystemStatus />
        <CookieStatus />
        {/* MAIN APP CONTENT */}
        <AppContainer>
          <GoogleAnalytics />
          <HistoryRouter history={getHistory()}>
            <NotificationContainer>
              <PermissionsContainer>
                <MarketsContainer>
                  <ViewContainer>
                    <ViewProvider />
                  </ViewContainer>
                </MarketsContainer>
              </PermissionsContainer>
            </NotificationContainer>
          </HistoryRouter>
        </AppContainer>
      </ConnectionContainer>
    </>
  );
};

export default process.env.NODE_ENV !== 'production' ? hot(App) : App;
