import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import {
  useSystemStatusQuery,
  SystemStatus as SystemStatusEnum,
} from '../../schema/types';

export const STATUS_MESSAGES: { [id: string]: string } = {
  [SystemStatusEnum.Maintainence]:
    "We're curently performing some maintenance but we'll be back soon! 🚀",
  [SystemStatusEnum.Degraded]:
    "We're currently experiencing some issues. Our engineers are investigating. All pricing and trading will be halted until the system is operational.",
};

const SystemStatus = () => {
  const { data } = useSystemStatusQuery();
  const systemStatus = data?.systemStatus || SystemStatusEnum.Operational;
  const systemMessage = data?.systemMessage || '';
  return (
    <Dialog
      open={systemStatus !== SystemStatusEnum.Operational}
      disableBackdropClick={true}
    >
      <DialogTitle>System Status</DialogTitle>
      <DialogContent>
        <DialogContentText>{STATUS_MESSAGES[systemStatus]}</DialogContentText>
        {systemMessage !== '' && (
          <DialogContentText>{systemMessage}</DialogContentText>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SystemStatus;
