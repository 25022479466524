interface IWithId {
  id?: string;
  __ref?: string;
}

function defaultGetKey<T extends IWithId>(o: T) {
  return `${o.id || o.__ref}`;
}

type TFilter<T> = (obj: T) => boolean;

export interface IMergeIncomingOpts<T> {
  preventOverwriteExisting?: boolean;
  incomingFilter?: TFilter<T>;
  resultFilter?: TFilter<T>;
  sorter?: (a: T, b: T) => -1 | 0 | 1;
  getKey?: (o: T) => string;
}

export function arrayMergeIncoming<T>(
  existing: T[],
  incoming: T[],
  {
    preventOverwriteExisting = false,
    incomingFilter = () => true,
    resultFilter = () => true,
    sorter = () => 0,
    getKey = defaultGetKey,
  }: IMergeIncomingOpts<T> = {}
) {
  let itemMap: { [key: string]: T } = existing.reduce(
    (acc, v) => ({ ...acc, [getKey(v)]: v }),
    {}
  );
  let newItems = incoming.filter(incomingFilter);
  if (preventOverwriteExisting) {
    const prevKeys = Object.keys(itemMap);
    newItems = newItems.filter((i) => !prevKeys.includes(getKey(i)));
  }
  itemMap = newItems.reduce((acc, v) => ({ ...acc, [getKey(v)]: v }), itemMap);
  return Object.values(itemMap).filter(resultFilter).sort(sorter);
}
