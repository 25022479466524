export type FunctionYielding<T> = (...args: any) => T;

export type TypeGuard<T> = (x: any) => x is T;

export type Nullable<T> = T | null;

export function isArrayOf<T>(
  typeguard: TypeGuard<T>,
  value: any
): value is T[] {
  return value instanceof Array && value.every((item) => typeguard(item));
}

export function isOptional<T>(
  typeguard: TypeGuard<T>,
  value: any
): value is T | undefined {
  return value === undefined || typeguard(value);
}

export function isNullable<T>(
  typeguard: TypeGuard<T>,
  value: any
): value is T | null {
  return value === null || typeguard(value);
}

export function isNullish<T>(
  typeguard: TypeGuard<T>,
  value: any
): value is T | null | undefined {
  return value === null || value === undefined || typeguard(value);
}

export function isOptionalArrayOf<T>(
  typeguard: TypeGuard<T>,
  value: any
): value is T[] | undefined {
  return value === undefined || isArrayOf(typeguard, value);
}

export function isNullableArrayOf<T>(
  typeguard: TypeGuard<T>,
  value: any
): value is T[] | null {
  return value === null || isArrayOf(typeguard, value);
}

export function isNullishArrayOf<T>(
  typeguard: TypeGuard<T>,
  value: any
): value is T[] | null | undefined {
  return value === null || value === undefined || isArrayOf(typeguard, value);
}

export function nullAsUndefined<T>(value?: T | null): T | undefined {
  if (value === null) {
    return undefined;
  }
  return value;
}

export function nullishToString<T>(value?: T | null): string | '' {
  if (value === null || value === undefined) {
    return '';
  }
  return `${value}`;
}

export function isValidNumberString(value: any): boolean {
  if (!value) {
    return false;
  }
  if (typeof value !== 'string') {
    return false;
  }
  if (Number.isNaN(parseFloat(value))) {
    return false;
  }
  if (!/^(-|\+)?(\d+|)\.?\d+/.test(value)) {
    return false;
  }
  return true;
}

export function isValidNumber(value: any): value is number {
  if (typeof value === 'number') {
    return !Number.isNaN(value);
  }
  return false;
}

export function enumKeys<O extends object, K extends keyof O = keyof O>(
  obj: O
): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

export function isArray<T = any>(a: any): a is T[] {
  return Array.isArray(a);
}

export function isString(s: any): s is string {
  return typeof s === 'string';
}

export function isBoolean(b: any): b is boolean {
  return typeof b === 'boolean';
}
