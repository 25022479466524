import { FC, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme, createStyles } from '@material-ui/core';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import WidgetsIcon from '@material-ui/icons/Widgets';
import ViewListIcon from '@material-ui/icons/ViewList';
import HomeIcon from '@material-ui/icons/Home';
import GavelIcon from '@material-ui/icons/Gavel';
import { Link, useLocation } from 'react-router-dom';
import { isFullScreen } from '../../util/isFullscreen';
import Event from '../GoogleAnalytics/Event';
import { Message, MoreHoriz } from '@material-ui/icons';
import ability from '../../auth/util';
import { usePermissionsHashQuery } from '../../schema/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingBottom: isFullScreen
        ? 'calc(env(safe-area-inset-bottom, 0px) - 5px)'
        : '0px',
      background: theme.palette.background.paper,
    },
    root: {
      width: '100%',
      minWidth: '300px',
      background: theme.palette.background.paper,
      overflowX: 'hidden',
      font: 'inherit !important',
      fontFamily: 'Play, sans-serif !important',
      borderTop: `solid 1px ${theme.palette.inputBackground.main}`,
    },
    navItem: {
      font: 'inherit !important',
      fontFamily: 'Play, sans-serif !important',
      color: theme.palette.info.light,
      '&$selected': {
        color: theme.palette.text.primary,
      },
    },
    selected: {
      fontSize: '1.1rem !important',
    },
    label: {
      font: 'inherit',
      color: theme.palette.info.light,
      '&$selected': {
        color: theme.palette.text.primary,
      },
    },
  })
);

const BottomNavBar: FC = () => {
  const classes = useStyles();
  const location = useLocation();

  usePermissionsHashQuery();

  const formattedPath = location.pathname.includes('/orders')
    ? '/orders'
    : location.pathname.includes('/trades')
    ? '/trades'
    : location.pathname.includes('/user') ||
      location.pathname.includes('/preferences')
    ? '/preferences'
    : location.pathname;

  const [activeTab, setActiveTab] = useState(formattedPath);

  useEffect(() => {
    setActiveTab(formattedPath);
  }, [formattedPath]);

  return (
    <div className={classes.container}>
      <BottomNavigation value={activeTab} showLabels className={classes.root}>
        {ability.can('read', 'broker') ? (
          <BottomNavigationAction
            onClick={() => Event('NAVIGATION', 'Blast tab pressed', 'BLAST')}
            data-testid="blast-tab"
            value="/blast"
            label="Blast"
            to="/blast"
            component={Link}
            icon={<Message />}
            classes={{
              root: classes.navItem,
              selected: classes.selected,
              label: classes.label,
            }}
          />
        ) : (
          <BottomNavigationAction
            onClick={() =>
              Event('NAVIGATION', 'Home tab pressed', 'HOME_SCREEN')
            }
            data-testid="home-tab"
            value="/"
            label="Home"
            to="/"
            component={Link}
            icon={<HomeIcon />}
            classes={{
              root: classes.navItem,
              selected: classes.selected,
              label: classes.label,
            }}
          />
        )}
        <BottomNavigationAction
          onClick={() =>
            Event('NAVIGATION', 'Markets tab pressed', 'MARKETS_SCREEN')
          }
          data-testid="markets-tab"
          value="/markets"
          label="Markets"
          to="/markets"
          component={Link}
          icon={<WidgetsIcon />}
          classes={{
            root: classes.navItem,
            selected: classes.selected,
            label: classes.label,
          }}
        />
        <BottomNavigationAction
          onClick={() =>
            Event('NAVIGATION', 'Trades tab pressed', 'TRADES_SCREEN')
          }
          data-testid="trades-tab"
          value="/trades"
          label="Trades"
          to={location.pathname.includes('trades') ? '#' : '/trades'}
          component={Link}
          icon={<GavelIcon />}
          classes={{
            root: classes.navItem,
            selected: classes.selected,
            label: classes.label,
          }}
        />
        <BottomNavigationAction
          onClick={() =>
            Event('NAVIGATION', 'Orders tab pressed', 'ORDERS_SCREEN')
          }
          data-testid="orders-tab"
          value="/orders"
          label="Orders"
          to={location.pathname.includes('orders') ? '#' : '/orders'}
          component={Link}
          icon={<ViewListIcon />}
          classes={{
            root: classes.navItem,
            selected: classes.selected,
            label: classes.label,
          }}
        />
        <BottomNavigationAction
          onClick={() =>
            Event(
              'NAVIGATION',
              'preferences tab pressed',
              'PREFERENECES_SCREEN'
            )
          }
          data-testid="preferences-tab"
          value="/preferences"
          label="More"
          to="/preferences"
          component={Link}
          icon={<MoreHoriz />}
          classes={{
            root: classes.navItem,
            selected: classes.selected,
            label: classes.label,
          }}
        />
      </BottomNavigation>
    </div>
  );
};

export default BottomNavBar;
