import { ApolloLink } from '@apollo/client';
import { extractObjectRules } from '../../auth/util';

const abilityBlacklist = ['ProductActivitySubscription'];

export const abilityLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((result) => {
    const { data } = result;
    if (data && !abilityBlacklist.includes(operation.operationName)) {
      extractObjectRules(data);
    }
    return result;
  });
});
