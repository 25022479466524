import { split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { httpLink } from './http';
import { webSocketLink } from './webSocket';

export const wwWithout = (wwWhatever: string) =>
  wwWhatever.replace(/^www\./i, '');

export const GRAPHQL_HOST =
  process.env.REACT_APP_API_BASE ||
  `${window.location.protocol}//api.${wwWithout(window.location.host)}`;
const wsProtocol = GRAPHQL_HOST.startsWith('https') ? 'wss:' : 'ws:';
const wsHost = `${wsProtocol}${GRAPHQL_HOST.replace('https:', '').replace(
  'http:',
  ''
)}`;
export const GRAPHQL_URI = `${GRAPHQL_HOST}/api/graphql`;
export const GRAPHQL_WS_URI = `${wsHost}/graphql`;

export const transportLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    const isSubscription =
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription';
    const isTokenMutation =
      definition.name && definition.name.value === 'RefreshTokenMutation';
    return isSubscription || isTokenMutation || false;
  },
  webSocketLink,
  httpLink
);
