import { BrowserHistory, createBrowserHistory } from 'history';
import { Maybe } from './schema/types';

let historySingleton: Maybe<BrowserHistory> = null;

export const getHistory = (): BrowserHistory => {
  if (historySingleton) {
    return historySingleton;
  }
  historySingleton = createBrowserHistory();
  return historySingleton;
};
