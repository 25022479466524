import { FC, useEffect } from 'react';
import { logout } from '../../auth/auth';
import { getRefreshToken, getToken } from '../../client/client';
import {
  useHasConnectedQuery,
  useLoggedInQuery,
  usePermissionsHashQuery,
} from '../../schema/types';
import PermissionsFetcher from '../Auth/PermissionsFetcher/PermissionsFetcher';

const PermissionsContainer: FC = ({ children }) => {
  useEffect(() => {
    // Check for broken local storage on load
    if (!!getToken() !== !!getRefreshToken()) logout();
  }, []);

  const { data } = useHasConnectedQuery();
  const hasConnected = data?.hasConnected || false;
  const { data: loginStatus } = useLoggedInQuery();
  const loggedIn = loginStatus?.loggedIn;

  // Reload if perms change
  usePermissionsHashQuery();
  return hasConnected && loggedIn ? (
    <PermissionsFetcher>{children}</PermissionsFetcher>
  ) : (
    <>{children}</>
  );
};

export default PermissionsContainer;
