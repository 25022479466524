import { useEffect } from 'react';
import { logout, persistTokens } from '../../../auth/auth';
import { getClient, getRefreshToken } from '../../../client/client';
import useInterval from '../../../hooks/useInterval';
import { AuthRefreshTokenDocument } from '../../../schema/types';

const AuthTokenRefresher = () => {
  const refreshInterval = 60 * 60 * 1000;

  const handleTokenRefresh = () => {
    if (getRefreshToken() === null) return;
    getClient()
      .mutate({
        mutation: AuthRefreshTokenDocument,
        variables: { refreshToken: getRefreshToken() },
      })
      .then(({ data }) => {
        persistTokens(data.refreshToken);
      })
      .catch((e) => {
        logout();
      });
  };

  useInterval(() => {
    handleTokenRefresh();
  }, refreshInterval);

  useEffect(() => {
    handleTokenRefresh();
  }, []);

  return <div id={'perms'} />;
};

export default AuthTokenRefresher;
