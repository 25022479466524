import {
  Button,
  Checkbox,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { Share } from '@material-ui/icons';
import { FC } from 'react';
import { useState } from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';
import useTimeout from '../../hooks/useTimeout';
import { isFullScreen } from '../../util/isFullscreen';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.background.paper,
    border: theme.palette.inputBorder,
    paddingBottom: '8px',
  },
  arrow: {
    '&:before': {
      border: theme.palette.inputBorder,
    },
    color: theme.palette.background.paper,
    fontSize: '40px',
  },
  button: {
    border: theme.palette.inputBorder,
    background: theme.palette.inputBackground.main,
  },
}));

const AddAppPrompt: FC<{ popupDelay?: number }> = ({ popupDelay = 300000 }) => {
  const classes = useStyles();
  const [promptOpen, setPromptOpen] = useState<boolean>(false);
  const [dontShow, setDontShow] = useLocalStorage<boolean>(
    'dontShowAppPrompt',
    isFullScreen
  );

  useTimeout(() => setPromptOpen(true), dontShow ? null : popupDelay);

  return (
    <Tooltip
      open={promptOpen}
      interactive
      arrow
      classes={{ tooltip: classes.container, arrow: classes.arrow }}
      title={
        <div>
          <h1>Add this page as an app</h1>
          <p>
            Did you know you can add this site as an app and access it directly
            from your home screen?
          </p>
          <p>Click on the {<Share />} button below then 'Add to Home Screen'</p>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              Don't show me this again{' '}
              {
                <Checkbox
                  checked={dontShow}
                  onChange={(_e, c) => setDontShow(c)}
                />
              }
            </div>
            <Button
              size={'small'}
              className={classes.button}
              onClick={() => setPromptOpen(false)}
            >
              close
            </Button>
          </div>
        </div>
      }
    >
      <div
        style={{
          display: 'fixed',
          bottom: 0,
          width: '100%',
          height: 0,
        }}
      ></div>
    </Tooltip>
  );
};

export default AddAppPrompt;
